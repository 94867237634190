import axios from 'axios'
import config from 'config'
axios.defaults.baseURL = config.API_URL
class Service {
  route: string

  constructor(route: string) {
    this.route = route
  }

  get = async (url: string) => {
    try {
      const res = await axios.get(this._buildUrl(url))
      return res.data
    } catch (e: any) {
      this.onError(e)
      throw e.response ? e.response : e
    }
  }

  post = async (entity: any, url?: string) => {
    try {
      const res = await axios.post(this._buildUrl(url), entity)
      return res.data
    } catch (e: any) {
      this.onError(e)
      throw e.response ? e.response : e
    }
  }

  put = async (entity: any, url?: string) => {
    try {
      const res = await axios.put(this._buildUrl(url), entity)
      return res.data
    } catch (e: any) {
      this.onError(e)
      throw e.response ? e.response : e
    }
  }

  delete1 = async (url?: string) => {
    try {
      return await axios.delete(this._buildUrl(url))
    } catch (e: any) {
      this.onError(e)
      throw e.response ? e.response : e
    }
  }

  onError = (e: any) => {
    if (e.response && e.response.status === 404) {
      return
    }
  }

  _buildUrl = (url?: string) => {
    if (url) {
      return `${this.route}${url}`
    }
    return this.route
  }
}

export default Service
