import {FC} from 'react'
import {useFormikContext, Field} from 'formik'
import clsx from 'clsx'

type Props = {
  id: string
  name: string
  className?: string
  handleChange?: any
  disabled?: boolean
}

const Checkbox: FC<Props> = ({className, name, handleChange, disabled}) => {
  const {getFieldMeta, getFieldProps} = useFormikContext()
  const {onChange} = getFieldProps(name)

  const {touched, error} = getFieldMeta(name)

  const handleValueChange = (e: any) => {
    onChange(e)
    if (handleChange) {
      handleChange(e)
    }
  }

  return (
    <>
      <Field
        disabled={disabled}
        type='checkbox'
        name={name}
        onChange={handleValueChange}
        className={clsx('form-check-input', className)}
      />

      {touched && error && (
        <div className='fv-plugins-message-container invalid-feedback'>
          <i className='bi bi-exclamation-circle-fill text-danger me-2'></i>
          <span role='alert'>{error}</span>
        </div>
      )}
    </>
  )
}

export default Checkbox
