import {FC, forwardRef, useRef, useState} from 'react'
import {FormikProvider, useFormikContext} from 'formik'
import {Button, ErrorMessage} from '_core'
import {useFormik} from 'formik'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import find from 'lodash/find'
import PaymentForm from 'app/components/PaymentForm'
import {showMessage} from 'app/redux/messageSlice'
import Address from './AddressForm'
import {
  ElavonPaymentMethodModel,
  ElavonPaymentMethodSchema,
  PaymentMethodModel,
  PaymentMethodSchema,
  elavonPaymentMethodInitialState,
  paymentMethodInitialState,
} from 'app/features/plans/models/PaymentModel'
import customerService from 'app/services/CustomerService/CustomerService'
import {hideLoader} from 'app/redux/loaderSlice'
import {RootState} from 'setup'
import {UserModel} from 'app/features/auth/models/UserModel'
import {paymentProviderTypes} from 'app/constant'
import toLowerCase from 'lodash/toLower'
import {Loader} from '_core/components/Loader'
import {getCustomerAddresses} from 'app/features/customers/redux/customerSlice'

type Props = {
  onClose: (id?: string) => any
  customerId: any
  addressList: Array<any>
  isAddressFetch?: boolean
}

const PaymentMethodForm: FC<Props> = (props) => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  if (toLowerCase(user.store?.paymentProviderType) === paymentProviderTypes.elavon) {
    return <ElavonForm {...props} />
  }
  return <StripForm {...props} />
}

export default PaymentMethodForm

const ElavonForm: FC<Props> = (props) => {
  const formik = useFormik<ElavonPaymentMethodModel>({
    initialValues: elavonPaymentMethodInitialState,
    validationSchema: ElavonPaymentMethodSchema,
    onSubmit: handleSubmit,
  })

  async function handleSubmit() {}

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <PaymentMethodFormControl {...props} isSubmitting={false} paymentError='' />
      </form>
    </FormikProvider>
  )
}

const StripForm: FC<Props> = (props) => {
  const ref = useRef<any>(null)
  const [paymentError, setPaymentError] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const {onClose, customerId, addressList} = props
  const dispatch = useDispatch()
  const formik = useFormik<PaymentMethodModel>({
    initialValues: paymentMethodInitialState,
    validationSchema: PaymentMethodSchema,
    onSubmit: handleSubmit,
  })

  async function handleSubmit({address, addressId, isPrimary}: any) {
    try {
      setIsSubmitting(true)
      let addressModel = {...address}
      if (addressId) {
        addressModel = find(addressList, {id: addressId})
      } else {
        const out = await customerService.createAddress({...address, customerId})
        addressModel.id = out.id
      }

      if (ref && ref.current) {
        const {error, id: paymentMethodId} = await ref.current.createPaymentMethod(
          addressModel,
          customerId,
          isPrimary
        )

        if (paymentMethodId) {
          handleClose(paymentMethodId)
        } else {
          setPaymentError(error)
        }
      }
    } catch (error) {
      console.log(error)
      dispatch(
        showMessage({
          text: 'An error occurred while create Payment Card ',
          variant: 'error',
        })
      )
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleClose = (id?: string) => {
    onClose(id)
    dispatch(hideLoader())
  }

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <PaymentMethodFormControl
          {...props}
          isSubmitting={isSubmitting}
          paymentError={paymentError}
          ref={ref}
        />
      </form>
    </FormikProvider>
  )
}

type FormControlProps = {
  onClose: any
  customerId: any
  addressList: Array<any>
  isAddressFetch?: boolean
  isSubmitting: boolean
  paymentError: string
}

const PaymentMethodFormControl = forwardRef((props: FormControlProps, ref) => {
  const dispatch = useDispatch()
  const {isValid, values} = useFormikContext()
  const {onClose, customerId, addressList, isAddressFetch, isSubmitting, paymentError} = props
  const {addressId, isPrimary}: any = values

  const handleClose = () => {
    onClose()
    dispatch(hideLoader())
  }

  return (
    <>
      <div className='row gy-5 gx-xl-8'>
        <div className='col-xl-12 mt-10'>
          <AddressFormWrapper customerId={customerId}>
            <Address
              addressList={addressList}
              isAddressFetch={isAddressFetch}
              addressId={addressId}
            />
          </AddressFormWrapper>

          <PaymentForm
            customerId={customerId}
            addressId={addressId}
            onClose={handleClose}
            isPrimary={isPrimary}
            ref={ref}
          />
        </div>
      </div>
      <ErrorMessage error={paymentError} />
      <Footer isSubmitting={isSubmitting} isValid={isValid} onClose={handleClose} />
    </>
  )
})

type FooterProps = {
  isSubmitting: boolean
  isValid: boolean
  onClose: () => void
}
const Footer: FC<FooterProps> = ({isSubmitting, isValid, onClose}) => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  if (toLowerCase(user.store?.paymentProviderType) === paymentProviderTypes.stripe) {
    return (
      <div className='modal-footer'>
        <div className='d-flex justify-content-end'>
          <div className='me-5'>
            <button
              id='btnAddressCancel'
              type='button'
              onClick={onClose}
              className='btn explore-btn-outline '
            >
              Cancel
            </button>
          </div>
          <div>
            <Button
              id='btnPaymentSave'
              type='submit'
              title='Save'
              isSubmitting={isSubmitting}
              isValid={isValid}
              className='btn-primary'
            />
          </div>
        </div>
      </div>
    )
  }

  return null
}

type AddressProps = {
  children: any
  customerId: string
}

const AddressFormWrapper: FC<AddressProps> = ({children, customerId}) => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const {values, isValid, setFieldValue, setFieldTouched} = useFormikContext<PaymentMethodModel>()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const dispatch = useDispatch()
  const handleSave = async () => {
    handleTouched(true, true)
    if (isValid) {
      try {
        setIsSubmitting(true)
        const {address} = values
        if (!address.id) {
          const out = await customerService.createAddress({...address, customerId})
          setFieldValue('address', elavonPaymentMethodInitialState.address)
          setFieldValue('addressId', out.id)
          setFieldValue('hasAddress', true)
          handleTouched(false, false)
          dispatch(getCustomerAddresses(customerId))
        }
      } catch (error) {
        console.log(error)
      } finally {
        setIsSubmitting(false)
      }
    }
  }

  const handleTouched = (isTouched: boolean, shouldValidate: boolean) => {
    setFieldTouched('address.firstName', isTouched, shouldValidate)
    setFieldTouched('address.lastName', isTouched, shouldValidate)
    setFieldTouched('address.email', isTouched, shouldValidate)
    setFieldTouched('address.phone', isTouched, shouldValidate)
    setFieldTouched('address.addressLine1', isTouched, shouldValidate)
    setFieldTouched('address.city', isTouched, shouldValidate)
    setFieldTouched('address.stateId', isTouched, shouldValidate)
    setFieldTouched('address.countryId', isTouched, shouldValidate)
    setFieldTouched('address.dateOfBirth', isTouched, shouldValidate)
    setFieldTouched('address.zipCode', isTouched, shouldValidate)
  }

  if (toLowerCase(user.store?.paymentProviderType) === paymentProviderTypes.elavon) {
    return (
      <div>
        <Loader show={isSubmitting} />
        {children}
        {!values.hasAddress && (
          <div className='modal-footer'>
            <div className='d-flex justify-content-end'>
              <div className='me-5'></div>
              <div>
                <Button
                  id='btnPaymentSave'
                  type='button'
                  title='Save'
                  isSubmitting={isSubmitting}
                  isValid={isValid}
                  className='btn-primary'
                  onClick={handleSave}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }

  return (
    <div>
      <Loader show={isSubmitting} />
      {children}
    </div>
  )
}
