import {FC} from 'react'

type Props = {
  error: any
  className?: string
}

const ErrorMessage: FC<Props> = ({error, className}) => {
  if (!error) {
    return null
  }
  if (error.message) {
    return (
      <div className='fv-plugins-message-container invalid-feedback pt-1'>
        <i className='bi bi-exclamation-circle-fill text-danger me-2'></i>
        <span role='alert' className='pt-5'>
          {error.message}
        </span>
      </div>
    )
  }

  // if (error.length) {
  //   return (
  //     <ul className='fv-plugins-message-container invalid-feedback pt-1 m-0 p-0'>
  //       {error.map((err: any, index: number) => (
  //         <div className='mb-2'>
  //           <KTSVG path='/media/icons/corksy/alert.svg' className='svg-icon svg-icon-danger me-2' />
  //           <span role='alert' className={className || ''}>
  //             {err}
  //           </span>
  //           <br />
  //         </div>
  //       ))}
  //     </ul>
  //   )
  // }
  return (
    <div className='fv-plugins-message-container invalid-feedback pt-1'>
      <i className='bi bi-exclamation-circle-fill text-danger me-2'></i>
      <span role='alert' className={className || 'pt-5'}>
        {error}
      </span>
    </div>
  )
}
export default ErrorMessage
