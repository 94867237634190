//@ts-nocheck
import {addPaymentStatus} from 'app/constant'
import paymentService from 'app/services/PaymentService'

export interface ElavonPayment {
  firstName: string
  lastName: string
  addressLine1: string
  zipCode: string
  amount: number
}

export interface PaymentResult {
  status: 'ERROR' | 'SUCCEEDED' | 'DECLINED' | 'CANCELLED'
  transactionId?: string
  cardLast4?: string
  brand?: string
  createCard: boolean
  expDate?: string
  externalPaymentMethodId?: string
  data?: any
  error?: any
}

interface Customer {
  id: string
  addressId: string
}

const getOptions = (iframeId) => {
  if (!iframeId) {
    iframeId = 'id_payment;'
  }
  return {
    frame: document.getElementById(iframeId),
    cssOverride: `
      html,
      body {
        background-color: transparent;
      }
     
      .hpm-content {
        min-width: 98%;
        max-width: 100%;
      }
      .hpm-content {
        box-shadow: unset;
        margin-top: unset;
      }
      .hpm-input-short {
        margin-bottom: 0px;
      }
      md-input-container .md-input {
        box-shadow: none !important;
        font-size: 14px;
        font-weight: 400;
        color: #445275 !important;
        padding: 0.5rem 0.75rem;
        border-radius: 0.475rem;
        display: block;
        width: 100%;
        line-height: 1.5;
        background-color: #ffffff;
        background-clip: padding-box;
        border: 1px solid #b6c2e2;
        appearance: none;
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      }
      md-card {
        box-shadow: unset;
        background-color: transparent;
      }
      .md-button {
        background-color: #000000;
        color: #fff !important;
        border: 1px solid;
        font-size: 18px;
        min-height: 50px;
        font-weight: 400;
        line-height: 1.5;
      }
      .md-button.md-raised:disabled {
        background-color: #1480db !important;        
      }
      .md-button.md-primary.md-raised {
        background-color: #1480db;
        border-radius: 4px !important;
        font-weight: 500 !important;
        line-height: 1.5 !important;
        font-size: 1.1rem !important;
        outline: none !important;
        transition: all 0.2s ease-in !important;
        cursor: pointer;
        padding: calc(0.75rem + 1px) calc(1.5rem + 1px);
        box-shadow: none !important;
      }
      .md-button.md-primary.md-raised:hover {
        transition: all 0.2s ease-in !important;
        color: #ffffff !important;
        background: linear-gradient(269.64deg, #9e2ebb 0%, #4063d5 100%);
      }
      `,
  }
}

class ElavonLightboxManager {
  constructor() {
    this.openLightbox = this.openLightbox.bind(this)
  }

  async addCard(model: any, onCompleted: (pm: any) => void, iframeId: string) {
    try {
      const payment = {
        ...model,
        transactionType: 'ccverify',
      }
      const {sessionToken} = await paymentService.getTransactionToken(payment)
      const customer = {
        addressId: model.addressId,
        id: model.customerId,
      }
      const options = getOptions(iframeId)
      this.openLightbox(sessionToken, customer, onCompleted, options)
    } catch (error) {
      console.log(error)
    }
  }

  openLightbox(
    sessionToken: string,
    customer: Customer,
    onCompleted: (pm: any) => void,
    options: any
  ) {
    const paymentFields = {
      ssl_txn_auth_token: sessionToken,
      ssl_get_token: 'Y',
      ssl_add_token: 'Y',
    }
    const pm = {}
    const callback = {
      onError: (error) => {
        pm.status = addPaymentStatus.error
        pm.data = {
          errorMessage: error,
        }
        onCompleted(pm)
      },
      onCancelled: () => {
        pm.status = addPaymentStatus.cancelled
        pm.data = {
          errorMessage: '',
        }
        onCompleted(pm)
      },
      onDeclined: (response) => {
        pm.status = addPaymentStatus.declined
        pm.data = response
        onCompleted(pm)
      },
      onApproval: async (response) => {
        console.log('elavon response', response)
        pm.maskedNumber = response.ssl_card_number
        pm.brand = response.ssl_card_short_description
        pm.externalId = response.ssl_token
        pm.expDate = response.ssl_exp_date
        pm.customerId = customer.id
        pm.addressId = customer.addressId
        try {
          onCompleted({card: pm, status: addPaymentStatus.succeeded})
        } catch (error) {
          onCompleted({errorMessage: error, status: addPaymentStatus.error})
        }
      },
    }

    window.PayWithConverge.open(paymentFields, callback, options)

    return false
  }

  showResult(status: string, msg: string) {}
}

const elavonLightboxManager = new ElavonLightboxManager()
export default elavonLightboxManager
