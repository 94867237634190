import {FC} from 'react'
import {gql, useQuery} from '@apollo/client'
import {Select, ZipCodeField} from '_core/forms'
import {SimplePage} from '_core'
import {useFormikContext} from 'formik'

const GET_COUNTRIES = gql`
  query GetCountries {
    countries {
      nodes {
        id
        name
      }
    }
  }
`

type Props = {
  name: string
  onChange?: any
  className?: string
  disabled?: boolean
  resetKeys?: string[]
}

const Country: FC<Props> = ({className, name, disabled, resetKeys}) => {
  const {data, loading, error} = useQuery(GET_COUNTRIES)
  const {setFieldValue} = useFormikContext()

  const handleCountryChange = () => {
    if (resetKeys && resetKeys.length) {
      resetKeys.forEach((key) => {
        setFieldValue(`${key}`, '')
      })
    }
  }
  return (
    <SimplePage loading={loading} error={error}>
      <div className={className}>
        <label className='form-label fs-6 fw-bold text-dark required'>Country/Region</label>
        <Select id='ddCountry' name={name} disabled={disabled} onChange={handleCountryChange}>
          <option value=''>Select a country</option>
          {data &&
            data.countries.nodes.map((country: any) => (
              <option value={country.id} key={country.id}>
                {country.name}
              </option>
            ))}
        </Select>
      </div>
    </SimplePage>
  )
}

export default Country
