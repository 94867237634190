import {FC} from 'react'
import {Link, useLocation} from 'react-router-dom'
import {PageLink} from '../../../core/PageData'
import {KTSVG} from '_metronic/helpers'

const CustomTitle: FC<PageLink> = ({children, path, title}) => {
  const {state} = useLocation<any>()
  return (
    <div className='d-flex justify-content-between align-items-center w-100'>
      <h1 className='d-flex align-items-center text-dark fw-bolder my-1 fs-1 mb-5'>
        <Link to={{pathname: path, state}} className='cursor-pointer'>
          <KTSVG
            path='/media/icons/corksy/arrow-left.svg'
            className='svg-icon-2hx svg-icon-dark me-3'
          />
        </Link>
        {title}
      </h1>
      <div>{children}</div>
    </div>
  )
}

export {CustomTitle}
