import {FC, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from 'setup'
import map from 'lodash/map'
import AddressListItem from './AddressListItem'
import {getStoreAddresses} from 'app/redux/StorePlanSlice'
import Pagination from 'app/components/pagination'

type Props = {formik: any; storeAddressList?: any}
const pageSize = 5
const AddressList: FC<Props> = ({formik}) => {
  const [addressId, setAddressId] = useState<any>()
  const [currentPage, setCurrentPage] = useState(1)

  const paging = useSelector<RootState>(({misc}) => misc.paging) as any
  const handleChange = (addressId: any) => {
    setAddressId(addressId)
    formik.setFieldValue('addressId', addressId)
  }
  const dispatch = useDispatch()
  const storeAddressList = useSelector<RootState>(
    ({misc}) => misc.storePlan.storeAddressList
  ) as any

  const {errors, touched} = formik

  const handleAddAddress = () => {
    formik.setFieldValue('hasAddress', false)
    formik.setFieldValue('addressId', '')
    setAddressId('')
  }

  const handlePageChange = async (currentPage: any) => {
    setCurrentPage(currentPage)
    const newFilter = {
      offset: (currentPage - 1) * pageSize,
      pageSize,
    }
    dispatch(getStoreAddresses(newFilter))
  }

  return (
    <div className='py-4'>
      <div className='fs-4 fw-bolder text-dark mb-3 pb-4'>Select Address</div>
      <div className='row'>
        {map(storeAddressList, (address: any) => (
          <AddressListItem
            key={address.Id}
            address={address}
            onHandleChange={handleChange}
            accountId={addressId}
          />
        ))}
        <div className='col-xxl-6'>
          <div className='card notice d-flex bg-light rounded border-secondary border border-dashed '>
            <div className='d-flex justify-content-center m-8 py-3'>
              <button
                id='btnAddPayment'
                type='button'
                onClick={handleAddAddress}
                className='btn explore-btn-outline '
              >
                Add Address
              </button>
            </div>
          </div>
        </div>
        {errors.addressId && touched.hasAddress && (
          <div className='px-5 text-danger pt-5'>
            <i className='bi bi-exclamation-circle-fill text-danger me-2'></i>
            {errors.addressId}
          </div>
        )}
      </div>
      <Pagination
        totalCount={paging.totalCount}
        onPageChange={handlePageChange}
        currentPage={currentPage}
        pageSize={pageSize}
      />
    </div>
  )
}
export default AddressList
