import {FC} from 'react'
import {useFormikContext} from 'formik'
import clsx from 'clsx'
import NumberFormat from 'react-number-format'

type Props = {
  id?: string
  name: string
  placeholder?: string
  className?: string
  min?: number
  max?: number
  prefix?: string
  suffix?: string
  disabled?: boolean
  allowNegative?: boolean
  validate?: (e: any) => boolean
  validate2?: (e: any) => boolean
  handleChange?: (e: any) => void
  decimalSeparator?: any
  isAllowed?: any
  decimalScale?: number | undefined
  fixedDecimalScale?: any
}

const NumberField: FC<Props> = ({
  className,
  name,
  prefix,
  validate,
  validate2,
  handleChange,
  suffix,
  ...props
}) => {
  const {getFieldMeta, getFieldProps} = useFormikContext()
  const {onChange, ...rest} = getFieldProps(name)
  const {touched, error} = getFieldMeta(name)
  const id = props.id || `txt${name}`

  const handleValueChange = (e: any) => {
    if (validate2) {
      if (validate2(e)) {
        onChange(e)
        if (handleChange) {
          handleChange(e)
        }
      }
    } else {
      onChange(e)
      if (handleChange) {
        handleChange(e)
      }
    }
  }

  return (
    <div>
      <div className='d-flex align-items-center'>
        <NumberFormat
          {...rest}
          isAllowed={validate}
          className={clsx(
            'form-control',
            className,
            {'is-invalid': touched && error},
            {
              'is-valid': touched && !error,
            }
          )}
          name={name}
          {...props}
          id={id}
          prefix={prefix}
          onChange={handleValueChange}
        />
        {suffix && (
          <div className='ms-3'>
            <span>{suffix}</span>
          </div>
        )}
      </div>
      {touched && error && (
        <div className='fv-plugins-message-container invalid-feedback'>
          <i className='bi bi-exclamation-circle-fill text-danger me-2'></i>
          <span role='alert'>{error}</span>
        </div>
      )}
    </div>
  )
}

export default NumberField
