import {gql} from '@apollo/client'

export const GET_STORE_PLAN = gql`
  query GetSubscriptionPlan {
    shop {
      name
      storePlan {
        id
        trialPeriodId
        trialPeriodLeft
        trialExpired
        trialPeriod {
          id
          startDate
          endDate
        }
        subscriptionPlan {
          id
          name
          isTrail
        }
      }
      storePaymentMethods {
        totalCount
      }
    }
  }
`
