import Service from '../Service'

class FeatureFlagService extends Service {
  constructor() {
    super('/features')
  }
  async evaluation(flagKey: any) {
    return this.post({flagKey}, '/flags/evaluation')
  }
}

const featureFlagService = new FeatureFlagService()
export default featureFlagService
