import {Context} from '@opentelemetry/api'
import {ReadableSpan, Span, SpanProcessor} from '@opentelemetry/sdk-trace-web'
import {getSessionUser} from 'app/features/auth/redux/AuthCRUD'

export class SessionIdProcessor implements SpanProcessor {
  forceFlush(): Promise<void> {
    return Promise.resolve()
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onStart(span: Span, parentContext: Context): void {
    const user = getSessionUser()
    if (user) {
      span.setAttribute('app.session.storeName', user.store.name)
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  onEnd(span: ReadableSpan): void {}

  shutdown(): Promise<void> {
    return Promise.resolve()
  }
}
