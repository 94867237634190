import {FC, useEffect, useState} from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import {useDispatch} from 'react-redux'
import {useFormikContext} from 'formik'
import {useQuery} from '@apollo/client'
import {toggleLoader} from 'app/redux/loaderSlice'
import {GET_CUSTOMER_CLASS} from '../../graphql/customer'
import find from 'lodash/find'

const animatedComponents = makeAnimated()

type Props = {}

const CustomerClass: FC<Props> = () => {
  const {data, loading} = useQuery(GET_CUSTOMER_CLASS)
  const [customerType, setCustomerType] = useState<any[]>([])
  const [selected, setSelected] = useState<any>([])
  const dispatch = useDispatch()

  const {
    values: {classIds},
    setFieldValue,
    getFieldMeta,
    setFieldTouched,
  } = useFormikContext<any>()

  useEffect(() => {
    dispatch(toggleLoader(loading))
  }, [dispatch, loading])

  useEffect(() => {
    if (data && classIds && classIds.length > 0) {
      const selectCustomerClass: any[] = []

      classIds.forEach((id: any) => {
        const _customerClass = find(data.customerClasses.nodes, {id})
        if (_customerClass) {
          selectCustomerClass.push({
            value: _customerClass.id,
            label: _customerClass.name,
          })
        }
      })
      setSelected(selectCustomerClass)
    }
  }, [data, setFieldValue, classIds])

  const filed = getFieldMeta('classIds')

  useEffect(() => {
    if (data) {
      let _customer = data.customerClasses.nodes.map((customer: any) => ({
        value: customer.id,
        label: customer.name,
      }))
      setCustomerType(_customer)
    }
  }, [data])

  const handleChange = (selectedOption: any) => {
    setSelected(selectedOption)
    setFieldValue(
      'classIds',
      selectedOption.map((option: any) => option.value),
      true
    )
    setFieldTouched('classIds', true, true)
  }

  return (
    <div className='mb-10'>
      <Select
        // menuPortalTarget={document.body}
        id='sltProductCollectionsChange'
        className='basic-multi-select'
        classNamePrefix='select'
        value={selected}
        components={animatedComponents}
        isMulti
        options={customerType}
        onChange={handleChange}
      />
      {filed.touched && filed.error && (
        <div className='fv-plugins-message-container invalid-feedback d-block'>
          <i className='bi bi-exclamation-circle-fill text-danger me-2'></i>
          <span role='alert'>{filed.error}</span>
        </div>
      )}
    </div>
  )
}

export default CustomerClass
