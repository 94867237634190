import {FC} from 'react'
import {useFormikContext} from 'formik'
import clsx from 'clsx'

type Props = {
  id: string
  name: string
  placeholder?: string
  className?: string
  rows?: number
  maxLength?: any
}

const MultilineTextFiled: FC<Props> = ({id, placeholder, className, name, rows, ...props}) => {
  const {getFieldProps, getFieldMeta} = useFormikContext()

  const {touched, error} = getFieldMeta(name)

  return (
    <>
      <textarea
        {...getFieldProps(name)}
        rows={rows}
        className={clsx(
          'form-control',
          className,
          {'is-invalid': touched && error},
          {
            'is-valid': touched && !error,
          }
        )}
        placeholder={placeholder}
        name={name}
        spellCheck
        autoComplete='off'
        id={id}
        {...props}
      ></textarea>
      {touched && error && (
        <div className='fv-plugins-message-container invalid-feedback'>
          <i className='bi bi-exclamation-circle-fill text-danger me-2'></i>
          <span role='alert'>{error}</span>
        </div>
      )}
    </>
  )
}

export default MultilineTextFiled
