import {forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState} from 'react'
import PaymentDetails from 'app/features/plans/components/payment/PaymentDetails'
import paymentService from 'app/services/PaymentService'
import {CardNumberElement, Elements, useElements, useStripe} from '@stripe/react-stripe-js'
import {setupIntentIf3DRequired} from './ThreeDAuth'
import {loadStripe} from '@stripe/stripe-js'
import {Loader} from '_core/components/Loader'
import {getPaymentMethods} from 'app/features/customers/redux/paymentMethodSlice'
import {useDispatch} from 'react-redux'
import {showMessage} from 'app/redux/messageSlice'

type StripeProps = {
  children: React.ReactNode
  config: any
  showCard?: boolean
}
const Stripe = forwardRef((prop: StripeProps, ref) => {
  const stripeRef: any = useRef<any>()
  const [isLoading, setIsLoading] = useState(true)

  const {config, ...props} = prop

  useEffect(() => {
    const load = async (config: any) => {
      stripeRef.current = await loadStripe(config.publishableKey, {
        stripeAccount: config.connectedAccountId,
      })
      setIsLoading(false)
    }
    if (config) {
      load(config)
    }
  }, [config])

  if (isLoading) {
    return <Loader show />
  }

  return (
    <Elements stripe={stripeRef.current}>
      <StripeFrom {...props} ref={ref} />
    </Elements>
  )
})

export default Stripe

type Props = {
  children: React.ReactNode
  showCard?: boolean
}

const StripeFrom = forwardRef((props: Props, ref) => {
  const stripe = useStripe()
  const elements = useElements()
  const dispatch = useDispatch()

  useImperativeHandle(ref, () => ({
    createPaymentMethod: handleCreatePaymentModel,
  }))

  const handleCreatePaymentModel = useCallback(
    async (address: any, customerId: string, isPrimary: boolean) => {
      if (elements && stripe) {
        const card = elements.getElement(CardNumberElement)
        const param: any = mapToStripePaymentMethodModel(address, card)
        const {error: stripeError, paymentMethod} = await stripe.createPaymentMethod(param)
        if (stripeError) {
          return {error: stripeError.message}
        } else {
          const out = await setupIntentIf3DRequired(paymentMethod, customerId, stripe)
          if (out && out.error) {
            return {error: out.error}
          } else {
            const model = mapToPaymentMethodModel(address.id, customerId, paymentMethod, isPrimary)
            const out = await paymentService.createCardPaymentMethod(model)
            dispatch(getPaymentMethods(customerId))
            dispatch(
              showMessage({
                text: 'Payment card has been created successfully',
                variant: 'success',
              })
            )
            return out
          }
        }
      }
      return {
        error: 'Error occurred while creating payment method',
      }
    },
    [elements, stripe, dispatch]
  )

  return <PaymentDetails {...props} />
})

const mapToStripePaymentMethodModel = (address: any, card: any) => {
  return {
    type: 'card',
    card,
    billing_details: {
      name: `${address.firstName} ${address.lastName}`,
      address: {
        city: address.city,
        country: address.country?.code,
        state: address.state?.code,
        line1: address.addressLine1,
        line2: address.addressLine2,
        postal_code: address.zipCode,
      },
    },
  }
}

const mapToPaymentMethodModel = (
  addressId: string,
  customerId: string,
  paymentMethod: any,
  isPrimary: boolean
) => {
  const {id, card, setupIntentId} = paymentMethod
  const pm: any = {
    externalId: id,
    maskedNumber: card.last4,
    brand: card.brand,
    expMonth: card.exp_month.toString(),
    expYear: card.exp_year.toString(),
    setupIntentId,
    addressId,
    customerId,
    isPrimary,
  }

  return pm
}
