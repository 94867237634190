import {FC} from 'react'
import {useFormikContext} from 'formik'
import clsx from 'clsx'
import NumberFormat from 'react-number-format'

type Props = {
  name: string
  placeholder?: string
  className?: string
  maxLength?: number
  minLength?: number
  allowNegative?: boolean
  decimalScale?: number
  format?: string
  mask?: string
  disabled?: boolean
}

const MaskedNumberField: FC<Props> = ({placeholder, className, name, disabled, ...props}) => {
  const {getFieldMeta, getFieldProps} = useFormikContext()
  const {touched, error} = getFieldMeta(name)

  return (
    <>
      <NumberFormat
        {...getFieldProps(name)}
        className={clsx(
          'form-control',
          className,
          {'is-invalid': touched && error},
          {
            'is-valid': touched && !error,
          }
        )}
        placeholder={placeholder}
        name={name}
        disabled={disabled}
        {...props}
      />
      {touched && error && (
        <div className='fv-plugins-message-container invalid-feedback'>
          <i className='bi bi-exclamation-circle-fill text-danger me-2'></i>
          <span role='alert'>{error}</span>
        </div>
      )}
    </>
  )
}

export default MaskedNumberField
