import React, {useEffect} from 'react'
import CustomerStripe from './CustomerStripe'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {UserModel} from 'app/features/auth/models/UserModel'
import {RootState} from 'setup'
import {paymentProviderTypes} from 'app/constant'
import CustomerElavon from './CustomerElavon'
import {useHistory, useParams} from 'react-router-dom'
import toLowerCase from 'lodash/toLower'
import {resetAddressListState} from '../redux/customerSlice'
import {mapToCreateCustomerModelV2} from './helper'
import customerService from 'app/services/CustomerService/CustomerService'
import {showMessage} from 'app/redux/messageSlice'

type Props = {
  searchText?: string
  onClose?: (value: any) => any
  closeBtnChildren?: any
  isCreate?: boolean
}

const Customer: React.FC<Props> = (props) => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const {id}: any = useParams()
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    return () => {
      dispatch(resetAddressListState())
    }
  }, [dispatch])

  const handleCreateCustomer = async (params: any) => {
    const model = mapToCreateCustomerModelV2(params)
    const customer = await customerService.createCustomer(model)
    dispatch(
      showMessage({
        text: 'Customer created successfully',
        variant: 'success',
      })
    )
    if (props.searchText && props.onClose) {
      props.onClose(customer.id)
    } else {
      history.goBack()
    }
  }

  if (toLowerCase(user.store?.paymentProviderType) === paymentProviderTypes.elavon && !id) {
    return <CustomerElavon {...props} onCustomerCreate={handleCreateCustomer} />
  }
  return <CustomerStripe {...props} onCustomerCreate={handleCreateCustomer} />
}

export default Customer
