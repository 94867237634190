import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import storePlanService from 'app/services/StorePlanService'
import {RootState} from 'setup'
import {toggleLoader} from './loaderSlice'
import {setTotalCount} from './pagingSlice'
import invoiceService from 'app/services/InvoiceService/InvoiceService'
import orderBy from 'lodash/orderBy'

export const getStorePlan = createAsyncThunk('store/plan', async (_, {dispatch}) => {
  try {
    dispatch(setPlanFetching(true))
    const storePlan = await storePlanService.getStorePlan()
    return storePlan
  } catch (err) {
    console.error(err)
  } finally {
    dispatch(setPlanFetching(false))
  }
})

export const getStorePayments = createAsyncThunk(
  'store/plan/payment',
  async (filter: any, {dispatch}) => {
    dispatch(toggleLoader(true))
    const {data, totalCount} = await storePlanService.getStorePayments(filter)
    dispatch(setTotalCount(totalCount))
    dispatch(toggleLoader(false))
    return orderBy(data, 'isDefault', 'desc')
  }
)

export const getStoreSubscriptionInvoices = createAsyncThunk(
  'store/plan/invoices',
  async (_, {dispatch}) => {
    dispatch(setIsFetchInvoice(true))
    const {data} = await invoiceService.invoiceList()
    dispatch(setIsFetchInvoice(false))
    return data
  }
)

export const getStoreAddresses = createAsyncThunk(
  'store/plan/address',
  async (filter: any, {dispatch}) => {
    if (!filter.offset) {
      filter.offset = 0
    }
    dispatch(setIsFetching(true))
    const {data, totalCount} = await storePlanService.getStoreAddress(filter)
    dispatch(setTotalCount(totalCount))
    dispatch(setIsFetching(false))
    return data
  }
)

const storePlanSlice = createSlice({
  name: 'storePlan',
  initialState: {
    subscriptionPlan: {},
    newPlan: null,
    trialPeriod: {},
    account: {
      planId: '',
      addressId: '',
      billingId: '',
    },
    billingCycle: null,
    storePaymentsList: [],
    storeAddressList: [],
    isFetching: false,
    planIsFetching: false,
    subscriptionInvoices: [],
    isFetchInvoice: false,
  },
  reducers: {
    setAccount: (state, action) => {
      state.account = {...state.account, ...action.payload}
    },
    setNewPlan: (state, action) => {
      state.newPlan = action.payload
    },
    setBillingCycle: (state, action) => {
      state.billingCycle = action.payload
    },
    setIsFetching: (state, action) => {
      state.isFetching = action.payload
    },
    setPlanFetching: (state, action) => {
      state.planIsFetching = action.payload
    },
    setSubscriptionInvoices: (state, action) => {
      state.subscriptionInvoices = action.payload
    },
    setIsFetchInvoice: (state, action) => {
      state.isFetchInvoice = action.payload
    },
  },
  extraReducers: {
    [getStorePlan.fulfilled.toString()]: (state, action) => {
      return action.payload
    },
    [getStorePayments.fulfilled.toString()]: (state, action) => {
      state.storePaymentsList = action.payload
    },
    [getStoreAddresses.fulfilled.toString()]: (state, action) => {
      state.storeAddressList = action.payload
    },
    [getStoreSubscriptionInvoices.fulfilled.toString()]: (state, action) => {
      state.subscriptionInvoices = action.payload
      state.isFetchInvoice = false
    },
  },
})
export const {
  setAccount,
  setNewPlan,
  setBillingCycle,
  setIsFetching,
  setPlanFetching,
  setSubscriptionInvoices,
  setIsFetchInvoice,
} = storePlanSlice.actions

export default storePlanSlice.reducer

export const selectPlanFetching = ({misc}: RootState): boolean => misc.storePlan.planIsFetching

export const selectSubscriptionInvoices = ({misc}: RootState): Array<any> =>
  misc.storePlan.subscriptionInvoices

export const selectInvoiceFetching = ({misc}: RootState): boolean => misc.storePlan.isFetchInvoice

export const selectStorePaymentsList = ({misc}: RootState): Array<any> =>
  misc.storePlan.storePaymentsList

export const selectStoreAddressList = ({misc}: RootState): Array<any> =>
  misc.storePlan.storeAddressList

export const selectIsFetching = ({misc}: RootState): boolean => misc.storePlan.isFetching
