import apolloClient from 'apollo-client'
import {GET_CUSTOMER_ORDERS} from '../graphql/order'
import {GET_CUSTOMER_SUBSCRIPTION} from '../graphql/subscription'
import {GET_CUSTOMER_WINE_CLUB} from '../graphql/wineClub'
import {GET_WINETASTING_BOOKINGS} from '../graphql/wineTasting'

class CustomerOrdersService {
  async getCustomerOrders(params: any) {
    const {data} = await apolloClient.query({
      query: GET_CUSTOMER_ORDERS,
      variables: params,
    })
    return {data: data.orders.nodes, totalCount: data.orders.totalCount}
  }

  async getCustomerWineTasting(params: any) {
    const {data} = await apolloClient.query({
      query: GET_WINETASTING_BOOKINGS,
      variables: params,
    })
    return {data: data.customerBookings.nodes, totalCount: data.customerBookings.totalCount}
  }

  async getCustomerWineClub(params: any) {
    const {data} = await apolloClient.query({
      query: GET_CUSTOMER_WINE_CLUB,
      variables: params,
    })
    return {data: data.searchSubscriptions.nodes, totalCount: data.searchSubscriptions.totalCount}
  }

  async getCustomerSubscriptions(params: any) {
    const {data} = await apolloClient.query({
      query: GET_CUSTOMER_SUBSCRIPTION,
      variables: params,
    })
    return {data: data.searchSubscriptions.nodes, totalCount: data.searchSubscriptions.totalCount}
  }
}

const customerOrdersService = new CustomerOrdersService()
export default customerOrdersService
