import apolloClient from 'apollo-client'
import {
  GET_PAYMENTS,
  CREATE_PAYMENT,
  CREATE_CARD_PAYMENT_METHOD,
  CHECK_PAYMENT_METHOD,
  DELETE_CUSTOMER_PAYMENT_METHOD,
} from './graphql'
import {ElavonPayment} from 'app/Payment/elavon/Elavon'
import Service from '../Service'
import axios from 'axios'

class PaymentService extends Service {
  constructor() {
    super('/payments/v1/elavon')
  }

  async create(model: any) {
    const {data, errors} = await apolloClient.mutate({
      mutation: CREATE_PAYMENT,
      variables: {
        model,
      },
    })

    if (errors) {
      throw errors
    }
    return data.createPaymentMethod2
  }

  async getPaymentMethods(customerId: any) {
    const {data} = await apolloClient.query({
      query: GET_PAYMENTS,
      variables: {
        customerId,
      },
    })
    return data.paymentMethods.nodes
  }

  async getTransactionToken(payment: ElavonPayment) {
    return this.post(payment, '/ap-transact/token')
  }

  async createCardPaymentMethod(payment: any) {
    const {data, errors} = await apolloClient.mutate({
      mutation: CREATE_CARD_PAYMENT_METHOD,
      variables: {
        payment,
      },
    })

    if (errors) {
      throw errors
    }
    return data.cardPaymentMethodCreate
  }

  async validatePaymentMethod(customerId: string, paymentMethodId: string) {
    const {data} = await apolloClient.query({
      query: CHECK_PAYMENT_METHOD,
      variables: {
        customerId,
        paymentMethodId,
      },
    })
    return data.checkPaymentMethod
  }

  async setupIntentByCustomer(customerId: string) {
    const {data} = await axios.post('/store/admin/v1/payment/intent/setup/customer', {customerId})
    return data
  }

  async deletePaymentMethod(id: any) {
    return await apolloClient.mutate({
      mutation: DELETE_CUSTOMER_PAYMENT_METHOD,
      variables: {id},
    })
  }
}

const paymentService = new PaymentService()
export default paymentService
