import {FC, useState, useEffect} from 'react'
import {ErrorMessage} from '_core'
import AddressDetails from 'app/features/plans/components/payment/AddressDetails'
import map from 'lodash/map'
import AddressListItem from 'app/features/settings/billing/addresslist/AddressListItem'
import {PhoneField, TextField} from '_core/forms'
import {useFormikContext} from 'formik'
import PaginationList from 'app/components/pagination/PaginationList'

const pageSize = 4
type Props = {
  addressList: Array<any>
  isAddressFetch?: boolean
  addressId: string
}

const AddressForm: FC<Props> = ({addressList, isAddressFetch, addressId}) => {
  const [switchAddress, setSwitchAddress] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)

  const {setFieldValue, touched, errors} = useFormikContext<any>()

  useEffect(() => {
    if (addressList) {
      setFieldValue('hasAddress', addressList.length > 0)
      setSwitchAddress(addressList.length > 0)
    }
  }, [addressList, setFieldValue])

  const handleChange = (addressId: any) => {
    setFieldValue('addressId', addressId)
  }

  const handleSwitch = () => {
    setSwitchAddress(!switchAddress)
    if (switchAddress) {
      setFieldValue('addressId', '')
    }
    setFieldValue('hasAddress', !switchAddress)
  }

  const handlePageChange = async (currentPage: any) => {
    setCurrentPage(currentPage)
  }

  const lastIndex = pageSize * currentPage
  const firstIndex = lastIndex - pageSize

  return (
    <>
      <div className='d-flex justify-content-between'>
        <div className='fs-4 fw-bolder text-dark mb-3 pb-4'>Billing Address</div>
        <div className='text-primary cursor-pointer' onClick={handleSwitch}>
          {switchAddress ? 'Add Address' : 'Address List'}
        </div>
      </div>
      {isAddressFetch ? (
        <div className='modal-body align-items-center pt-5'>
          <div className='d-flex justify-content-center '>
            <label>
              <span className='indicator-progress d-block'>
                <span className='spinner-border ms-5 align-middle text-info'></span>
              </span>
            </label>
          </div>
        </div>
      ) : (
        <>
          {!switchAddress ? (
            <AddressDetails isHideTitle isEnableDateOfBirth>
              <div className='row mb-10'>
                <div className='col-xl-6'>
                  <label className='form-label fs-6 fw-bold text-dark required'>Email</label>
                  <TextField id='txtCustomerOverviewEmail' name='address.email' maxLength={200} />
                </div>
                <div className='col-xl-6'>
                  <label className='form-label fs-6 fw-bold text-dark required'>Phone</label>
                  <PhoneField id='phnCustomerPhone' name='address.phone' />
                </div>
              </div>
            </AddressDetails>
          ) : (
            <div className='py-4'>
              <div className='row mb-5'>
                {map(addressList.slice(firstIndex, lastIndex), (address: any) => (
                  <AddressListItem
                    key={address.Id}
                    address={address}
                    onHandleChange={handleChange}
                    accountId={addressId}
                  />
                ))}
                {touched.hasAddress && <ErrorMessage error={errors.addressId} />}
              </div>
              <PaginationList
                totalCount={addressList.length}
                onPageChange={handlePageChange}
                currentPage={currentPage}
                pageSize={pageSize}
              />
            </div>
          )}
        </>
      )}
    </>
  )
}
export default AddressForm
