import {createSlice} from '@reduxjs/toolkit'
import {RootState} from 'setup'

export type PagingState = {
  totalCount: number
  currentPage: number
  totalPages: number
  pageSize: number
}

const initialState: PagingState = {
  totalCount: 0,
  currentPage: 1,
  totalPages: 1,
  pageSize: 10,
}

const pagingSlice = createSlice({
  name: 'paging',
  initialState,
  reducers: {
    setTotalCount: (state, action) => {
      state.totalCount = action.payload
      state.totalPages = Math.ceil(action.payload / state.pageSize)
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload
    },
    resetPaging: () => {
      return initialState
    },
  },
})

export const {setTotalCount, setCurrentPage, resetPaging, setPageSize} = pagingSlice.actions

export default pagingSlice.reducer

export const selectPaging = ({misc}: RootState): PagingState => misc.paging

export const selectPagingCurrentPage = ({misc}: RootState): number => misc.paging.currentPage
