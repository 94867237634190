import {FC} from 'react'
import {Modal} from 'react-bootstrap-v5'
import {useDispatch, useSelector} from 'react-redux'
import {KTSVG} from '_metronic/helpers'
import {RootState} from 'setup'
import {DialogState, closeDialog} from 'app/redux/dialogSlice'

type Props = {}

const Dialog: FC<Props> = () => {
  const dispatch = useDispatch()
  const dialog = useSelector<RootState>(({misc}) => misc.dialog) as DialogState
  const {show, children, title} = dialog

  const handleClose = () => {
    dispatch(closeDialog())
  }

  return (
    <Modal
      size='lg'
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='h-auto'
      show={show}
      onHide={handleClose}
    >
      <div className='mt-5'>
        <div className='modal-header d-flex'>
          <h5 className='modal-title'>{title}</h5>
          <div className='btn btn-icon btn-sm' onClick={handleClose}>
            <KTSVG
              className='svg-icon-1 rounded-1 svg-icon border border-primary'
              path='/media/icons/duotune/arrows/arr061.svg'
            />
          </div>
        </div>

        {children}
      </div>
    </Modal>
  )
}

export default Dialog
