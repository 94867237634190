import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {setTotalCount} from 'app/redux/pagingSlice'
import customerTagService from 'app/services/CustomerService/CustomerTagService'
import customerService from 'app/services/CustomerService'
import {showMessage} from 'app/redux/messageSlice'
import {RootState} from 'setup'
import {customerTypes} from '../list/components/helper'
import {setCurrentPageOne} from 'app/components/pagination/PagingWithFilterAction'

export const getCustomerList = createAsyncThunk(
  'customers/list',
  async (model: any, {dispatch, getState}) => {
    try {
      dispatch(setFetching(true))
      const newFilter = {
        ...model.filter,
      }
      if (newFilter.customerType === customerTypes.wineClubMember) {
        newFilter.customerType = null
        newFilter.hasWineClub = true
      }
      if (newFilter.name === '') {
        newFilter.name = null
      }
      const data = await customerService.getCustomers(newFilter, model.signal)
      const totalCount = await getCustomersTotalCount(newFilter, dispatch, getState)
      return {data, totalCount}
    } finally {
      if (!model.signal?.aborted) {
        dispatch(setFetching(false))
      }
    }
  }
)

export const getCustomersTotalCount = async (filter: any, dispatch: any, getState: any) => {
  const state: any = getState()
  const {customerList, isFilterChanged}: any = state.customers.customer
  const {data, totalCount} = customerList
  if ((data && data.length === 0) || isFilterChanged) {
    const totalCount = await customerService.getCustomersTotalCount(filter)
    dispatch(setTotalCount(totalCount))
    dispatch(setIsFilterChanged(false))
    return totalCount
  }
  return totalCount
}

export const getCustomerTags = createAsyncThunk('customers/tags', async (_, {dispatch}) => {
  try {
    dispatch(setTagIsFetching(true))
    const {data, totalCount} = await customerTagService.getCustomerTags()
    dispatch(setTotalCount(totalCount))
    return data
  } finally {
    dispatch(setTagIsFetching(false))
  }
})

export const getCustomerAddresses = createAsyncThunk(
  'customer/address',
  async (customerId: any, {dispatch}) => {
    try {
      dispatch(setFetching(true))
      const data = await customerService.getCustomerAddresses(customerId)
      return data
    } catch (error) {
      dispatch(showMessage({text: 'An error occurred while getting address', variant: 'error'}))
    } finally {
      dispatch(setFetching(false))
    }
  }
)

export const getCustomerDetails = createAsyncThunk(
  'customer/details/fetch',
  async (id: any, {dispatch, getState}) => {
    const {
      customers: {
        customer: {customerDetails},
      },
    }: any = getState()
    try {
      dispatch(setCustomerFetching(true))
      let orderBy = customerDetails.orderBy
      if (!orderBy) {
        orderBy = initialState.customerDetails.orderBy
      }

      const data = await customerService.getCustomerDetails({
        id,
        orderBy,
      })
      return data
    } catch (error) {
      dispatch(showMessage({text: 'An error occurred while getting details', variant: 'error'}))
    } finally {
      dispatch(setCustomerFetching(false))
    }
  }
)

export const fetchCustomerAddress = createAsyncThunk(
  'customer/address/fetch',
  async (id: any, {dispatch}) => {
    try {
      dispatch(setFetching(true))
      const data = await customerService.fetchCustomerAddress(id)
      dispatch(setFetching(false))
      return data
    } catch (error) {
      dispatch(showMessage({text: 'An error occurred while getting address', variant: 'error'}))
    }
  }
)

const initialState = {
  customerList: {
    data: [],
    totalCount: 0,
  },
  filter: {
    offset: 0,
    name: null,
    orderBy: null,
    customerType: null,
    payment: null,
    password: null,
    account: null,
    tagIds: null,
    hasWineClub: null,
    pageSize: 10,
    currentPage: 1,
    canLoad: false,
  },
  customerTags: [],
  isFetching: true,
  customerId: null,
  error: null,
  customerAddress: [],
  address: {},
  showDialog: {
    customerDialog: false,
    CustomerTagDialog: false,
  },
  customerDetails: {
    note: '',
    isFetching: false,
    orders: [],
    wineClubSubscriptions: [],
    customerTags: [],
    orderBy: 'CREATED_AT_DESC',
  },
  isTagFetching: false,
  isFilterChanged: false,
}
const getCustomerSlice = createSlice({
  name: 'customer/list',
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.isFilterChanged = true
      state.filter = {...state.filter, ...action.payload}
      setCurrentPageOne(state)
    },
    setPagingSizeChange: (state, action) => {
      state.filter = {...state.filter, ...action.payload}
    },
    setFetching: (state, action) => {
      state.isFetching = action.payload
    },
    setIsFilterChanged: (state, action) => {
      state.isFilterChanged = action.payload
    },
    setCustomerFetching: (state, action) => {
      state.customerDetails.isFetching = action.payload
    },
    setTagIsFetching: (state, action) => {
      state.isTagFetching = action.payload
    },
    setOrderBy: (state, action) => {
      state.filter.currentPage = 1
      state.filter.orderBy = action.payload
      state.filter.offset = 0
    },
    resetAddressState: (state) => {
      state.address = {}
    },
    resetAddressListState: (state) => {
      state.customerAddress = []
    },
    setCustomerId: (state, action) => {
      const {
        data: {
          createCustomer: {customer},
        },
      } = action.payload
      state.customerId = customer.id
    },
    setErrors: (state, action) => {
      state.error = action.payload
    },
    setNote: (state, action) => {
      state.customerDetails.note = action.payload
    },
    setCustomerDialog: (state, action) => {
      state.showDialog = action.payload
    },
    setOrderOrderBy: (state, action) => {
      state.customerDetails.orderBy = action.payload
    },
    resetCustomerList: (state) => {
      state.customerList = initialState.customerList
      state.filter = initialState.filter
    },
  },
  extraReducers: {
    [getCustomerList.fulfilled.toString()]: (state, action) => {
      state.customerList = action.payload
      state.isFetching = false
    },
    [getCustomerTags.fulfilled.toString()]: (state, action) => {
      state.customerTags = action.payload
    },
    [getCustomerAddresses.fulfilled.toString()]: (state, action) => {
      state.customerAddress = action.payload
    },
    [fetchCustomerAddress.fulfilled.toString()]: (state, action) => {
      state.address = action.payload
    },
    [getCustomerDetails.fulfilled.toString()]: (state, action) => {
      state.customerDetails = action.payload
    },
  },
})
export const {
  setFilter,
  setFetching,
  setOrderBy,
  setCustomerId,
  setErrors,
  resetAddressState,
  setCustomerDialog,
  setNote,
  setCustomerFetching,
  setOrderOrderBy,
  resetAddressListState,
  setTagIsFetching,
  resetCustomerList,
  setIsFilterChanged,
  setPagingSizeChange,
} = getCustomerSlice.actions

export default getCustomerSlice.reducer

export const selectCustomerShowDialog = ({customers}: RootState): any =>
  customers.customer.showDialog.customerDialog
export const selectCustomerTagShowDialog = ({customers}: RootState): any =>
  customers.customer.showDialog.CustomerTagDialog
export const selectCustomerValue = ({customers}: RootState): any =>
  customers.customer.customerValues
export const selectCustomerFilter = ({customers}: RootState): any => customers.customer.filter
export const selectCustomersIsFetching = ({customers}: RootState): boolean =>
  customers.customer.isFetching
export const selectCustomerAddressList = ({customers}: RootState): any[] =>
  customers.customer.customerAddress
export const selectCustomersList = ({customers}: RootState): Array<any> =>
  customers.customer.customerList.data

export const selectCustomersTotalCount = ({customers}: RootState): number =>
  customers.customer.customerList.totalCount
