import _ from 'lodash'
export const getMediaType = (fileName: any) => {
  let format = ''
  if (fileName) {
    format = fileName.split('.').pop()
  }
  const imageType = ['png', 'jpeg', 'jpg', 'gif']
  const videoType = ['mp4']

  if (imageType.indexOf(format) >= 0) {
    return 'image'
  }
  if (videoType.indexOf(format) >= 0) {
    return 'video'
  }
  // return 'image'
}

export const checkSpecialCharacter = (e: any) => {
  const {value} = e.target
  const _val = value.replace(/\s/g, '')
  const format = /[^a-zA-Z0-9',\-àç__èéù]/g
  if (format.test(_val)) {
    return false
  } else {
    return true
  }
}

export const checkSpecialCharacterAndSpace = (e: any) => {
  const {value} = e.target
  const _val = value.replace(/\s/g, '')
  const format = /[^a-zA-Z0-9\-àç__èéù]/g
  var isSpace = /\s/
  if (format.test(_val)) {
    return false
  } else if (isSpace.test(value)) {
    return false
  } else {
    return true
  }
}
export const removeSpecialChar = (src: any) => {
  const fileName = _.replace(src, /[^a-zA-Z0-9-. ]/gi, '').replace(/\s/g, '')
  return fileName
}
export const imageSize = (e: any) => {
  return new Promise((resolve, reject) => {
    const file = e.target.files[0]

    if (!file) {
      return
    }

    const reader = new FileReader()
    reader.onload = () => {
      const img = new Image()
      img.onload = () => {
        resolve({
          height: img.height,
          width: img.width,
        })
      }
      img.onerror = reject
      img.src = reader.result as string
    }

    reader.onerror = reject
    reader.readAsDataURL(file)
  })
}
