import React, {useState} from 'react'
import AddressForm from './AddressForm'
import AddressListItem from './AddressListItem'
import orderBy from 'lodash/orderBy'
import find from 'lodash/find'
import Indicator from '_core/components/Indicator/Indicator'
import {ErrorMessage} from '_core'

type Props = {
  addressList: any[]
  isFetching: boolean
  onClose: (id?: string) => void
  customerId: string
  onShowAddress: (show: boolean, id: string) => any
  onChangeAddress: (value: any) => any
  checkedAddress?: any
  address: {
    id: string
    dialog: boolean
  }
  children?: React.ReactNode
  isSubmitting?: boolean
  error?: any
  onError?: () => void
  btnTitle?: string
  onAddressSubmit?: (value: any) => any
}
const AddressList: React.FC<Props> = ({
  addressList,
  isFetching,
  onClose,
  onChangeAddress,
  customerId,
  address,
  checkedAddress,
  onShowAddress,
  children,
  isSubmitting,
  error,
  onError,
  btnTitle,
  onAddressSubmit,
}) => {
  const [selectedAddress, setSelectedAddress] = useState<any>({})

  const handleSelectAddress = (address: any) => {
    setSelectedAddress(address)
    if (onError) {
      onError()
    }
  }

  const handleChangeAddress = () => {
    const address = find(addressList, {id: selectedAddress.id})
    onChangeAddress(address)
  }

  const handleCloseAddress = (id?: string) => {
    if (id) {
      setSelectedAddress({id})
    }
    onShowAddress(false, '')
  }

  const handleEditAddress = (id: string) => {
    onShowAddress(true, id)
  }

  const handleSelectWithClose = (id?: string) => {
    if (id) {
      setSelectedAddress({id})
    }
    onClose(id || '')
  }

  if (isFetching) {
    return Loader()
  }

  if (address.dialog) {
    return (
      <AddressForm
        onClose={handleCloseAddress}
        customerId={customerId}
        addressId={address.id}
        isAdd
        onSubmit={onAddressSubmit}
      />
    )
  }

  if (addressList.length > 0) {
    return (
      <>
        <div className='row m-7'>
          {orderBy(addressList, ['isPrimary', 'dateOfBirth'], 'desc').map((address) => {
            return (
              <AddressListItem
                address={address}
                key={address.id}
                addressId={selectedAddress.id}
                onSelectAddress={handleSelectAddress}
                showAddress={checkedAddress}
                onUpdate={handleEditAddress}
              />
            )
          })}
          <ErrorMessage error={error} />
        </div>
        <div className='modal-footer'>
          <button
            id='btnAddressClose'
            className='btn explore-btn-outline me-5'
            onClick={() => onClose()}
          >
            Cancel
          </button>
          {children && children}
          <button
            id='btnSave'
            type='button'
            title='Change'
            disabled={!selectedAddress.id}
            className='btn-primary btn'
            onClick={handleChangeAddress}
          >
            {isSubmitting ? (
              <Indicator text=' Please wait...' />
            ) : btnTitle ? (
              btnTitle
            ) : addressList.length === 1 ? (
              'Save'
            ) : (
              'Change'
            )}
          </button>
        </div>
      </>
    )
  }
  return (
    <AddressForm
      onClose={handleSelectWithClose}
      customerId={customerId}
      onSubmit={onAddressSubmit}
    />
  )
}

export default AddressList

function Loader() {
  return (
    <div className='modal-body align-items-center pt-5'>
      <div className='d-flex justify-content-center '>
        <label>
          <span className='indicator-progress d-block'>
            <span className='spinner-border ms-5 align-middle text-info'></span>
          </span>
        </label>
      </div>
    </div>
  )
}
