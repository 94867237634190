import {FC} from 'react'
import clsx from 'clsx'
import {KTSVG} from '_metronic/helpers'
import Indicator from './Indicator'

type Props = {
  id: string
  title: string | any
  isSubmitting?: boolean
  isValid?: boolean
  className?: string
  onClick?: any
  type?: 'submit' | 'reset' | 'button'
  startIcon?: any
  endIcon?: any
  disabled?: boolean
  loaderText?: string
}

const Button: FC<Props> = ({
  id,
  title,
  isSubmitting,
  className,
  type,
  onClick,
  startIcon,
  disabled,
  endIcon,
  loaderText,
}) => {
  return (
    <button
      id={id}
      type={type}
      className={clsx('btn', className)}
      disabled={isSubmitting || disabled}
      onClick={onClick}
    >
      {!isSubmitting && (
        <span className='indicator-label'>
          {startIcon && <KTSVG path={startIcon} className='svg-icon svg-icon-2 me-3' />}
          {title}
          {endIcon && <KTSVG path={endIcon} className='svg-icon svg-icon-2 ms-3' />}
        </span>
      )}
      {isSubmitting && <Indicator text={loaderText ? loaderText : ' Please wait...'} />}
    </button>
  )
}

export default Button
