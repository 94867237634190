import {gql} from '@apollo/client'

export const GET_STORE_SETUP = gql`
  query GetStoreSetup {
    shop {
      storeSetups {
        nodes {
          id
          storeDetails
          shippingCompliance
          shippingDelivery
          emailCampaigner
          customizeTheme
          payment
          products
          domain
          orderLocation
        }
      }
    }
  }
`

export const GET_STORE_TOTAL_SALES_ORDERS_AND_CUSTOMERS = gql`
  query GetTotalSalesAndOrders(
    $fromDate: Date!
    $toDate: Date!
    $prevFromDate: Date!
    $prevToDate: Date!
  ) {
    totalOrders(fromDate: $fromDate, toDate: $toDate)
    totalSales(fromDate: $fromDate, toDate: $toDate)
    totalCustomers(fromDate: $fromDate, toDate: $toDate)
    prevMonthOrders: totalOrders(fromDate: $prevFromDate, toDate: $prevToDate)
    prevMonthSales: totalSales(fromDate: $prevFromDate, toDate: $prevToDate)
    prevMonthCustomers: totalCustomers(fromDate: $prevFromDate, toDate: $prevToDate)
  }
`
export const UPDATE_STORE_SETUP = gql`
  mutation UpdateStoreSetup($setupId: UUID!, $storeSetup: StoreSetupPatch!) {
    updateStoreSetup(input: {id: $setupId, patch: $storeSetup}) {
      clientMutationId
    }
  }
`
