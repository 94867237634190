import {FC} from 'react'
import {useFormikContext} from 'formik'

type Props = {
  name: string
  children: any
  className?: string
}

const RadioButtonGroup: FC<Props> = ({children, name, className}) => {
  const {getFieldMeta} = useFormikContext()
  const {touched, error} = getFieldMeta(name)

  return (
    <div className={className}>
      {children}

      {touched && error && (
        <div className='fv-plugins-message-container invalid-feedback'>
          <i className='bi bi-exclamation-circle-fill text-danger me-2'></i>
          <span role='alert'>{error}</span>
        </div>
      )}
    </div>
  )
}

export default RadioButtonGroup
