import {find, capitalize} from 'lodash'

export const getSelectedStatus = (status: any, statusTypes: any) => {
  const data = find(statusTypes, {value: status})
  return data ? capitalize(data.name) : ''
}

export const paymentStatusTypes = [
  {
    id: 'ACTIVE',
    name: 'Active',
    value: true,
  },
  {
    id: 'IN_ACTIVE',
    name: 'In active',
    value: false,
  },
]

export const accountStatusTypes = [
  {
    id: 'LOGIN',
    name: 'Account',
    value: true,
  },
  {
    id: 'GUEST',
    name: 'Guest',
    value: false,
  },
]

export const customerTypes = {
  wineClubMember: 'wine_club_member',
}

export const wineClubStatusTypes = [
  {
    id: 'wine_club_member',
    name: 'Wine Club Member',
  },
  {
    id: 'first_time',
    name: 'First Time',
  },
  {
    id: 'returning',
    name: 'Returning',
  },
  {
    id: 'inactive',
    name: 'Inactive',
  },
  {
    id: 'prospect',
    name: 'Prospect',
  },
]

export const passwordStatusTypes = [
  {
    id: 'COMPLETED',
    name: 'Completed',
    value: true,
  },
  {
    id: 'IN_COMPLETED',
    name: 'Incomplete',
    value: false,
  },
]
