import moment from 'moment-timezone'

export const formatToMDY = (date: any) => {
  if (!date) return ''
  return formatDate(date, 'MM-DD-YYYY')
}
export const formatToUTCMDY = (date: any) => {
  if (!date) {
    return ''
  } else {
    const formattedDate = moment.tz(date, 'UTC')

    return formattedDate
  }
}
export const formatToShortDateString = (date: string) => {
  return formatDate(date, 'MMM DD [at] h:mm a')
}

export const formatToShortDateString3 = (date: string) => {
  return formatDate(date, 'Do MMMM YYYY h:mm:ss a')
}

export const formatToDateString2 = (date: string) => {
  if (date) {
    return formatDate(date, 'MMM DD, YYYY')
  }
  return '-'
}

export const formatToDateString3 = (date: string) => {
  if (date) {
    return formatDate(date, 'MMM DD')
  }
  return '-'
}

export const formatToDateString4 = (date: string) => {
  if (date) {
    return formatDate(date, 'MMM DD, YYYY [at] h:mm a')
  }
  return '-'
}

export const formatToMMDDYYYY = (date: string) => {
  if (date) {
    return formatDate(date, 'MMM DD/YYYY h:mm')
  }
  return '-'
}

export const formatToMMDDYYYYv1 = (date: string) => {
  if (date) {
    return formatDate(date, 'MMM DD/YYYY')
  }
  return '-'
}

export const formatToDateString = (date: string) => {
  if (date) {
    return formatDate(date, 'MMM Do YYYY')
  }
  return '-'
}

export const formatDate = (date: any, format: string) => {
  if (!date) return '-'
  return moment(date).format(format)
}

export const formatTime = (date: any) => {
  if (!date) return '-'
  return moment(date, 'hh:mm A').format('hh:mm A')
}

export const formatLongTime = (date: any) => {
  if (!date) return '-'
  return moment(date, 'hh:mm A').format('hh:mm A')
}

export const toMMDD = (date: any) => {
  if (!date) return '-'
  return moment(date).format('MMM DD')
}

export const toYMD = (date?: any) => {
  if (!date) {
    return moment().format('YYYY-MM-DD')
  }
  return moment(date).format('YYYY-MM-DD')
}

export const toDate = (time: any) => {
  const date = moment(time)
  if (time && !date.isValid()) {
    return moment(time, 'hh:mm A')
  }
  return moment(time)
}

export const toDay = () => {
  return moment()
}

export const nextDay = () => {
  return moment().add(1, 'days')
}

export const addDays = (date: any, day: number) => {
  return moment(date).add(day, 'days').format('MM/DD/YYYY')
}

export const addDays1 = (date: any, day: number) => {
  return moment(date).add(day, 'days').format('YYYY-MM-DD')
}

export const addYears = (date: any, years: number) => {
  return moment(date).add(years, 'years').format('MM/DD/YYYY')
}

export const toYYYYMMDD = (date: any) => {
  return moment(date).format('YYYY-MM-DD')
}

export const subDays = (date: any, day: number) => {
  return moment(date).subtract(day, 'days').format('MM/DD/YYYY')
}

export const formatToMDDY = (date: string) => {
  if (!date) return ''
  return formatDate(date, 'MM/DD/YYYY')
}

export const lastNDays = (date: any, day: number) => {
  return moment(date).subtract(day, 'days')
}

export const lastNMonth = (date: any, month: number) => {
  return moment(date).subtract(month, 'month')
}

export const lastNYear = (date: any, year: number) => {
  return moment(date).subtract(year, 'year')
}

export const firstNYear = (date: any, year: number) => {
  return moment(date).add(year, 'year')
}

export const _toYMD = (date?: any) => {
  if (date) {
    const dt = moment(date)
    return dt
  }
}

export const toLocal = (date?: any) => {
  if (date) {
    const dt = moment.utc(date).local()
    return dt
  }
}

export const last30Days = () => moment().subtract(29, 'days').format('YYYY-MM-DD')

export const formatYYYYMMDDLastNDays = (days: number) => {
  return moment().subtract(days, 'days').format('YYYY-MM-DD')
}

export const toYYYYMMDDUTC = (date: any) => {
  return toYYYYMMDD(moment.utc(date))
}

export const toUTC = (date: any) => {
  return moment(date).utc()
}

export const UnixtoDate = (date: any) => {
  const dateString = moment.unix(date).format('YYYY-MM-DD')
  return dateString
}

export const UnixtoDateMDY = (date: any) => {
  const dateString = moment.unix(date).format('MM-DD-YYYY')
  return dateString
}

export const getMonth = (date: any, month: number) => {
  return moment(date).add(month, 'months').format('MMMM')
}

export const addMonth = (date: any, month: number) => {
  const nextDate = moment(date).add(month, 'months')
  return {
    month: nextDate.format('MMMM'),
    year: nextDate.year(),
  }
}

export const DatetoUnix = (date: any) => {
  const dateString = moment(date).unix()
  return dateString
}

export const toLocalUTCMMMDD = (date?: any) => {
  if (!date) return ''
  if (date) {
    const dt = moment.utc(date).local().format('MMM DD')
    return dt
  }
}

export const toLocalUTC = (date?: any) => {
  if (!date) return ''
  if (date) {
    const dt = moment.utc(date).local().format('MM-DD-YYYY')
    return dt
  }
}

export const trimTime = (time: any) => {
  time = time.replace(/^0+/, '')
  return time
}
export const toDateStartOfUTC = (date: any) => {
  return moment(date).startOf('day').toISOString()
}

export const toDateEndOfUTC = (date: any) => {
  return moment(date).endOf('day').toISOString()
}
