import {gql} from '@apollo/client'

export const GET_VARIANTS = gql`
  query GetVariants {
    getSubscriptionProducts {
      nodes {
        id
        name
        productName
      }
    }
  }
`
