import {FC} from 'react'
import {Modal} from 'react-bootstrap-v5'
import {ModalBody, ModalHeader} from '_core/components/Modal'

type Props = {
  title: string
  onClose: () => void
  children: React.ReactNode
  size?: string | any
}

const DialogModal: FC<Props> = ({title, onClose, children, size = 'lg'}) => {
  return (
    <Modal
      size={size}
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='h-auto'
      show
      onHide={onClose}
    >
      <ModalHeader title={title} toolbar={{onClick: onClose}} />
      <ModalBody>{children}</ModalBody>
    </Modal>
  )
}

export default DialogModal
