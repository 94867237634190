import {FC, useEffect, useState} from 'react'
import find from 'lodash/find'
import AsyncSelect from 'react-select/async'
import {useDispatch, useSelector} from 'react-redux'
import {useFormikContext} from 'formik'
import {InputFormat, searchTags} from './helper'
import {getCustomerTags} from 'app/features/customers/redux/customerSlice'
import {RootState} from 'setup'

type Props = {
  name: any
  tagIds: any
}

const components = {
  DropdownIndicator: null,
  IndicatorSeparator: null,
}

function getCustomerTagId(ids: any) {
  if (ids.tagId || ids.value) {
    return ids.tagId || ids.value
  } else {
    return ids
  }
}

const CustomerTags: FC<Props> = ({name, tagIds}) => {
  const [customerTags, setCustomerTags] = useState<any[]>([])
  const [selected, setSelected] = useState<any>([])
  const dispatch = useDispatch()

  const tags = useSelector<RootState>(({customers}) => customers.customer.customerTags) as any

  const {setFieldValue} = useFormikContext<any>()

  useEffect(() => {
    dispatch(getCustomerTags())
  }, [dispatch])

  useEffect(() => {
    if (tags && tagIds && tagIds.length >= 0) {
      setSelected(
        tagIds.map((ids: any) => {
          const id = getCustomerTagId(ids)
          const tag = find(tags, {id})
          return {
            value: id,
            label: `${tag?.name}`,
            id: ids.id,
          }
        })
      )
      setFieldValue(name, tagIds)
    }
  }, [name, tags, setFieldValue, tagIds])

  useEffect(() => {
    if (tags) {
      let _customerTags = tags.map((tag: any) => ({
        value: tag.id,
        label: `${tag.name}`,
        isLocal: true,
      }))
      setCustomerTags(_customerTags)
    }
  }, [tags])

  const handleChange = (selectedOption: any) => {
    setSelected(selectedOption)
    setFieldValue(
      name,
      selectedOption.map((option: any) => option),
      true
    )
  }

  const options = (inputValue: string) =>
    new Promise<InputFormat[]>((resolve) => {
      setTimeout(() => {
        resolve(searchTags(inputValue, customerTags))
      }, 1000)
    })

  return (
    <div className='fv-row mb-10'>
      <AsyncSelect
        isMulti
        value={selected}
        defaultOptions={customerTags}
        noOptionsMessage={() => 'No more tags found.'}
        placeholder='Type something and select'
        loadOptions={options}
        onChange={handleChange}
        components={components}
      />
    </div>
  )
}

export default CustomerTags
