import omit from 'lodash/omit'
import {filter} from 'lodash'
import {formatToMDY} from 'app/format/date'
import CustomerModel from 'app/features/customers/models/Customer'

export const mapToCustomerModel = (customer: any) => {
  const {customerTags, ...rest} = customer
  const customerModel = omit(rest, ['__typename'])
  const tagIds = customerTags.nodes.map((id: any) => id)
  return {
    ...customerModel,
    tagIds,
    isEdit: true,
  }
}

export const mapToCreateCustomerTag = (tags: any) => {
  return tags.map((tag: any) => {
    return {
      tagId: tag.value,
    }
  })
}

export const mapToUpdateCustomerTag = (tags: any) => {
  return filter(tags, ({id}) => !id).map((tag: any) => {
    return {
      tagId: tag.value,
    }
  })
}

export const mapToCreatePaymentMethod = ({customerId, response, customer, addressId}: any) => {
  const {payment} = customer

  let _id
  let _addressId

  if (customerId) {
    _id = customerId
    _addressId = addressId
  } else {
    const {
      data: {
        createCustomer: {
          customer: {
            id,
            defaultAddress: {id: addressId},
          },
        },
      },
    } = response
    _id = id
    _addressId = addressId
  }
  const model = {
    customerId: _id,
    addressId: _addressId,
    card: {
      cvv: payment.cvv,
      expMonth: payment.expMonth,
      expYear: payment.expYear,
      number: payment.number,
    },
    isPrimary: payment.isPrimary,
  }
  return model
}

export const mapToCreateCustomerModel = (address: any, customer: any, tags: any) => {
  const cust = {
    customerAddresses: {
      create: {
        ...address,
        dateOfBirth: formatToMDY(address.dateOfBirth),
        email: customer.email,
        // isDefault: true,
      },
    },
    customerTags: {create: mapToCreateCustomerTag(tags)},
    ...omit(customer, 'payment', 'isEdit', '__typename', 'isEnabledCustomerMethods'),
  }
  return cust
}

export const mapToCreateCustomerModelV2 = ({tagIds, address, ...customer}: CustomerModel) => {
  return {
    ...omit(customer, 'payment', 'isEdit', '__typename', 'isEnabledCustomerMethods'),
    customerTags: {create: mapToCreateCustomerTag(tagIds)},
  }
}

export const mapToUpdateCustomerModel = ({customerSpouses, ...customer}: any, tags: any) => {
  let cust = {}
  const tagIds = tags.map((tag: any) => tag.value)
  cust = {
    ...omit(customer, 'payment', 'isEdit', 'address', '__typename', 'isEnabledCustomerMethods'),
    customerTags: {
      create: mapToUpdateCustomerTag(tags),
    },
  }
  return {cust, tagIds}
}

export const getCustomerId = (response: any) => {
  const {
    data: {
      createCustomer: {
        customer: {
          id,
          defaultAddress: {id: addressId},
        },
      },
    },
  } = response

  return {id, addressId}
}
