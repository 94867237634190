import apolloClient from 'apollo-client'
import {GET_MESSAGES, GET_SHIPPING_COMPLIANCE_MESSAGES} from '../query/message'

class MessageService {
  async getMessages(params: any) {
    const {data} = await apolloClient.query({
      query: GET_MESSAGES,
      variables: {
        includes: params.name,
        first: params.pageSize,
        offset: params.offset,
      },
    })
    return {data: data.searchMessages.nodes, totalCount: data.searchMessages.totalCount}
  }
  async getShippingComplianceMessage(filter: any) {
    const {data} = await apolloClient.query({
      query: GET_SHIPPING_COMPLIANCE_MESSAGES,
      variables: {
        pageSize: filter.pageSize,
        offset: filter.offset,
      },
    })
    return {data: data.shippingCompliances.nodes, totalCount: data.shippingCompliances.totalCount}
  }
}

const messageService = new MessageService()
export default messageService
