import {FC} from 'react'
import moment from 'moment'
import {toMMDD} from 'app/format/date'
import {simpleCurrency} from 'app/format/currency'

type Props = {
  invoice: any
}

const InvoiceListItem: FC<Props> = ({invoice}) => {
  const getUTCDate = (date: any) => {
    return moment(date).format('YYYY-DD-MM')
  }
  return (
    <tr key={invoice.id}>
      <td> {invoice.invoiceNumber ? invoice.invoiceNumber : '-'}</td>
      <td> {toMMDD(invoice.invoiceDate)}</td>
      <td>
        {invoice.periodStart && <> {`${getUTCDate(invoice.periodStart)}`}</>}
        {invoice.periodEnd && <> to {`${getUTCDate(invoice.periodEnd)}`}</>}
      </td>
      <td>
        <span className='badge badge-light-success'>{invoice.status}</span>
      </td>
      <td>{simpleCurrency(invoice.subtotal)}</td>
      <td>{simpleCurrency(invoice.fee)}</td>
      <td>{simpleCurrency(invoice.tax)}</td>
      <td>{simpleCurrency(invoice.totalApplicationFee)}</td>
      <td className='text-end'>{simpleCurrency(invoice.total)}</td>
    </tr>
  )
}

export default InvoiceListItem
