import {FC, lazy, useEffect} from 'react'
import {KTSVG} from '_metronic/helpers'
import {Redirect, Route, Switch} from 'react-router-dom'
import SubscriptionInvoiceList from './Invoice/InvoiceList'
import {
  getStoreSubscriptionInvoices,
  selectInvoiceFetching,
  selectSubscriptionInvoices,
} from 'app/redux/StorePlanSlice'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from 'setup'

type Props = {}
const PaymentMethodAlert: FC<Props> = () => {
  const invoices = useSelector<RootState, Array<any>>(selectSubscriptionInvoices)
  const dispatch = useDispatch()
  const isFetching = useSelector<RootState, boolean>(selectInvoiceFetching)
  const SettingsPage = lazy(() => import('app/features/settings/SettingsPage'))

  useEffect(() => {
    dispatch(getStoreSubscriptionInvoices())
  }, [dispatch])

  if (isFetching) {
    return (
      <div className='w-100 vh-75 d-flex justify-content-center align-items-center'>
        <label>
          <span className='indicator-progress d-block'>
            <span className='spinner-border align-middle ms-5 text-info'></span>
          </span>
          Loading...
        </label>
      </div>
    )
  }

  return (
    <Switch>
      <Route path='/payment/pending'>
        <div className='bg-light-danger rounded-3 mb-2'>
          <div className='pt-5'>
            <KTSVG
              className='svg-icon-5x svg-icon-danger rounded-1 d-flex flex-center'
              path='/media/icons/duotune/general/gen034.svg'
            />
            <p className='text-center pt-4 h2'>Your Corksy account has been locked.</p>
            <div className='separator separator-dashed border-danger opacity-50 mb-5 container my-7 w-90' />
          </div>
          {invoices && invoices.length === 0 ? (
            <p className='text-center h5 pt-4'>Please Contact your administrator</p>
          ) : (
            <SubscriptionInvoiceList />
          )}
        </div>
      </Route>
      {invoices && invoices.length > 0 && (
        <Route path='/settings/billing' component={SettingsPage} />
      )}
      <Redirect to='/payment/pending' />
    </Switch>
  )
}
export default PaymentMethodAlert
