import React, {FC} from 'react'
import {KTSVG} from '_metronic/helpers'

type ToolbarProps = {
  path?: any
  className?: any
  onClick?: any
  children?: any
}

type Props = {
  title: any
  toolbar?: ToolbarProps
  children?: React.ReactNode
  className?: any
}

const ModalHeader: FC<Props> = ({title, toolbar, children, className}) => {
  return (
    <div className={className ? className : 'modal-header d-flex'}>
      <h5 className='modal-title'>{title}</h5>
      {toolbar && (
        <>
          {!toolbar.children && (
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={toolbar.onClick}>
              <KTSVG
                className={
                  toolbar.className
                    ? toolbar.className
                    : 'svg-icon-2 border rounded-1 border-primary svg-icon-primary'
                }
                path={toolbar.path ? toolbar.path : '/media/icons/duotune/arrows/arr061.svg'}
              />
            </div>
          )}
          {toolbar.children}
        </>
      )}
      {children}
    </div>
  )
}

export default ModalHeader
