import {Action} from '@reduxjs/toolkit'
import toUpper from 'lodash/toUpper'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage/session'
import {put, takeLatest} from 'redux-saga/effects'
import {UserModel} from '../models/UserModel'

import {StoreModelParam} from '../models/StoreModel'
import storeService from 'app/services/StoreService'
import {getUserByToken, setAccessToken, updateStoreName} from './AuthCRUD'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  Register: '[Register] Action',
  UserRequested: '[Request User] Action',
  UserLoaded: '[Load User] Auth API',
  SetUser: '[Set User] Action',
  SignUp: '[SignUp] Action',
}

const initialAuthState: IAuthState = {
  user: undefined,
  accessToken: undefined,
}

export interface IAuthState {
  user?: UserModel
  accessToken?: string
  isLoggedOut?: boolean
}

export const reducer = persistReducer(
  {storage, key: 'vino', whitelist: ['user', 'accessToken']},
  (state: IAuthState = initialAuthState, action: ActionWithPayload<IAuthState>) => {
    switch (action.type) {
      case actionTypes.Login: {
        const accessToken = action.payload?.accessToken
        return {accessToken, user: undefined, isLoggedOut: false}
      }
      case actionTypes.SignUp: {
        const accessToken = action.payload?.accessToken
        return {accessToken, user: undefined, isLoggedOut: false}
      }
      case actionTypes.Register: {
        const accessToken = action.payload?.accessToken
        return {accessToken, user: undefined, isLoggedOut: false}
      }

      case actionTypes.Logout: {
        return {...initialAuthState, isLoggedOut: !!state.accessToken}
      }

      case actionTypes.UserRequested: {
        return {...state, user: undefined}
      }

      case actionTypes.UserLoaded: {
        const user: any = action.payload?.user
        if (user) {
          user.avatarText = toUpper(`${user.firstName[0]}${user.lastName ? user.lastName[0] : ''}`)
        }
        return {...state, user}
      }

      case actionTypes.SetUser: {
        const user: any = action.payload?.user
        if (user) {
          user.avatarText = toUpper(`${user.firstName[0]} ${user.lastName ? user.lastName[0] : ''}`)
        }
        return {...state, user}
      }

      default:
        return state
    }
  }
)

export const actions = {
  login: (accessToken: string, rememberMe: boolean) => {
    setAccessToken(accessToken, rememberMe)
    return {type: actionTypes.Login, payload: {accessToken}}
  },
  signUp: (accessToken: string) => {
    setAccessToken(accessToken, false)
    return {type: actionTypes.SignUp, payload: {accessToken}}
  },
  register: (accessToken: string) => ({
    type: actionTypes.Register,
    payload: {accessToken},
  }),
  logout: () => {
    sessionStorage.clear()
    localStorage.clear()
    return {type: actionTypes.Logout}
  },

  requestUser: () => ({
    type: actionTypes.UserRequested,
  }),
  fulfillUser: (user: UserModel) => ({type: actionTypes.UserLoaded, payload: {user}}),
  setUser: (user: UserModel) => ({type: actionTypes.SetUser, payload: {user}}),
}

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser())
  })

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser())
  })

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const user: UserModel = yield getUserByToken()
    yield put(actions.fulfillUser(user))
  })

  yield takeLatest(actionTypes.SignUp, function* userRequested() {
    const store: StoreModelParam = yield storeService.fetch()
    yield updateStoreName(store)
    yield put(actions.requestUser())
  })
}
