import get from 'lodash/get'
import set from 'lodash/set'

export const parseFloatList = (list: any[], path: string) => {
  return list.map((item) => {
    const val = parseFloat(get(item, path))
    set(item, path, val)
    return item
  })
}
