import apolloClient from 'apollo-client'
import {GET_SHIPPING_CARRIERS, GET_PRODUCT_TYPES, GET_PRODUCT_TYPE, GET_COUNTRY_NAME, GET_COUNTRIES} from './graphql'

class LookupsService {
  async getShippingCarriers() {
    const {data} = await apolloClient.query({
      query: GET_SHIPPING_CARRIERS,
    })
    return data.carrierServiceProviders.nodes
  }

  async getProductTypes() {
    const {data} = await apolloClient.query({
      query: GET_PRODUCT_TYPES,
    })
    return data.productTypes.nodes
  }

  async getProductType(id: any) {
    const {data} = await apolloClient.query({
      query: GET_PRODUCT_TYPE,
      variables: {
        id: id,
      },
    })
    return data.productType
  }

  
    async fetchCountryList() {
    const { data } = await apolloClient.query({
      query: GET_COUNTRIES,
    });
    const { nodes } = data.countries;
    return nodes.map(({ states, ...country }:any) => {
      return {
        ...country,
        states: states.nodes,
      };
    });
  }

    async getCountryName(id:any) {
    const { data } = await apolloClient.query({
      query: GET_COUNTRY_NAME,
      variables: { id },
    });
    return data.country.name;
  }
}

const lookupsService = new LookupsService()
export default lookupsService
