import {FC} from 'react'
import {capitalize} from 'lodash'
import clsx from 'clsx'
import {KTSVG} from '_metronic/helpers'
import omit from 'lodash/omit'
import MessagePopover from '_core/components/MessagePopover'
import {paymentLinkedMessage} from 'app/constant/address'

type Props = {
  address: any
  onSelectAddress: any
  addressId: any
  showAddress: any
  onUpdate: (value: any) => any
}
const AddressListItem: FC<Props> = ({
  address,
  onSelectAddress,
  addressId,
  showAddress,
  onUpdate,
}) => {
  const handleChange = async (e: any) => {
    onSelectAddress(omit(address, 'isLinkedWithPayment'))
  }

  const handleUpdateAddress = () => {
    onUpdate(address.id)
  }

  const disabled = () => {
    if (address && address.dateOfBirth === '') {
      return true
    }
    if (address && address.dateOfBirth === null) {
      return true
    }
    return false
  }

  const editBtn = (
    <button
      type='button'
      className='btn btn-icon btn-sm btn-outline btn-outline-secondary btn-active-text-white me-2 px-2 p-0'
      onClick={handleUpdateAddress}
      disabled={address.isLinkedWithPayment}
    >
      <KTSVG path='/media/icons/corksy/edit.svg' className='svg-icon-2 pb-1' />
    </button>
  )

  return (
    <div className='col-xxl-6 pb-6 col-lg-6 col-md-6'>
      <label
        className={clsx(
          'btn btn-outline btn-tab btn-outline-dashed d-flex flex-stack text-start mb-2',
          (disabled() || showAddress === address.id) && 'cursor-default'
        )}
      >
        <div className='d-flex align-items-center'>
          {showAddress !== address.id ? (
            <div className='form-check form-check-custom form-check-solid form-check-primary me-6'>
              <input
                className='form-check-input'
                type='radio'
                checked={addressId === address?.id}
                value={address.id}
                onChange={handleChange}
                disabled={disabled()}
              />
            </div>
          ) : (
            <div className='me-3'>
              <KTSVG
                path='/media/icons/duotune/arrows/arr016.svg'
                className='svg-icon-muted svg-icon-1'
              />
            </div>
          )}
          <div className='d-flex py-2'>
            <span className='fw-bold text-start row'>
              <span className='text-dark fw-bolder fs-6 d-flex justify-content-between'>
                <span className={clsx('col-8 text-wrap w-100', disabled() && 'required')}>
                  {`${capitalize(address.firstName)}
                  ${capitalize(address.lastName)}`}
                  {address.isPrimary && (
                    <span className={`badge badge badge-light-success ms-2`}>Primary</span>
                  )}
                </span>
              </span>
              <span className='text-muted fw-bold fs-7'>{address.addressLine1}</span>
              <span className='text-muted fw-bold fs-7'>
                {address.addressLine2
                  ? `${address.addressLine2},${address.city}, `
                  : `${address.city}, `}
                {address.zipCode}
              </span>
              <span className='text-muted fw-bold fs-7'>{address.state.name}</span>
            </span>
          </div>
          {address.isLinkedWithPayment ? (
            <MessagePopover message={paymentLinkedMessage} status={editBtn} />
          ) : (
            <>{editBtn}</>
          )}
        </div>
      </label>
    </div>
  )
}
export default AddressListItem
