import apolloClient from 'apollo-client'
import {
  CREATE_CUSTOMER_TAG,
  DELETE_CUSTOMER_TAG,
  GET_CUSTOMER_TAGS,
  GET_CUSTOMER_TAGS_BY_NAME,
} from './graphql'

class CustomerTagService {
  async getCustomerTags() {
    const {data} = await apolloClient.query({
      query: GET_CUSTOMER_TAGS,
    })
    return {
      data: data.customerTagDefintions.nodes,
      totalCount: data.customerTagDefintions.totalCount,
    }
  }

  async checkCustomerTagAvailable(name: any) {
    const {data} = await apolloClient.query({
      query: GET_CUSTOMER_TAGS_BY_NAME,
      variables: {
        name,
      },
    })
    return data.customerTagDefintions.totalCount
  }

  async createCustomerTag(tag: any) {
    return await apolloClient.mutate({
      mutation: CREATE_CUSTOMER_TAG,
      variables: {customerTagDefintion: tag},
    })
  }
  async delete(id: any) {
    return await apolloClient.mutate({
      mutation: DELETE_CUSTOMER_TAG,
      variables: {id},
    })
  }
}

const customerTagService = new CustomerTagService()
export default customerTagService
