import clsx from 'clsx'
import {useFormikContext} from 'formik'
import {FC} from 'react'

type Props = {
  name: string
  required?: boolean
  type?: string
  className?: string
  [key: string]: any
  maxLength?: number
}

const InputMask: FC<Props> = ({
  name,
  required = false,
  type = 'text',
  className,
  maxLength,
  ...props
}) => {
  const {getFieldMeta, getFieldProps, setFieldValue} = useFormikContext()
  const {touched, error} = getFieldMeta(name)
  const {onChange, value, ...rest} = getFieldProps(name)
  const id = props.id || `txt${name}`

  const handleChange = (e: any) => {
    const rawValue = e.target.value.replace(/\W/g, '')
    const formattedValue = rawValue.replace(/(\w{0,3})(\w{0,3})/, '$1 $2').trim()
    setFieldValue(name, rawValue, true)
    e.target.value = formattedValue
  }

  return (
    <div>
      <input
        onChange={handleChange}
        value={value?.replace(/(\w{0,3})(\w{0,3})/, '$1 $2').trim() || ''}
        maxLength={7}
        className={clsx(
          'form-control',
          className,
          {'is-invalid': touched && error},
          {'is-valid': touched && !error}
        )}
        autoComplete='off'
        {...props}
        {...rest}
        id={id}
      />
      {touched && error && (
        <div className='fv-plugins-message-container invalid-feedback'>
          <i className='bi bi-exclamation-circle-fill text-danger me-2'></i>
          <span role='alert'>{error}</span>
        </div>
      )}
    </div>
  )
}

export default InputMask
