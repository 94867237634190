import apolloClient from 'apollo-client'
import {
  ORDER_CANCEL,
  GET_ORDERS,
  GET_UNFULFILLED_ORDERS,
  FIND_ORDER,
  GET_ORDER_STATES,
  GET_ORDER_ZIP_CODES,
  GET_ORDER_BOTTLES,
  GET_ORDER_WEIGHTS,
  UPDATE_ORDER,
  GET_ORDER,
  UPDATE_FULFILLMENT_LOCATION,
  GET_FULFILLMENT_CURRENT_LOCATION,
  CREATE_ORDER_NOTES,
  GET_ORDERS_TOTAL_COUNT,
} from 'app/features/orders/graphql'
import GraphqlService from '../GraphqlService'

class OrdersService extends GraphqlService {
  async fetchOrder(id: string) {
    const {data, errors} = await apolloClient.query({
      query: GET_ORDER,
      variables: {id},
    })
    if (errors) {
      throw errors
    }
    return data.order
  }
  async getOrders(params: any, signal: any) {
    const {data, errors} = await this._signalQuery(GET_ORDERS, signal, params)
    if (errors) {
      throw errors
    }
    const {nodes} = data.searchOrders
    return {data: nodes, totalCount: nodes.length ? nodes[0].totalCount : 0}
  }

  async getOrdersTotalCount(params: any) {
    const {data, errors} = await this._query(GET_ORDERS_TOTAL_COUNT, params)
    if (errors) {
      throw errors
    }
    return data.searchOrdersTotalCount
  }

  async getUnfulfilledOrders(params: any) {
    const {data} = await apolloClient.query({
      query: GET_UNFULFILLED_ORDERS,
      variables: params,
    })

    const orders = data.searchOrdersToFulfill.nodes
    return {data: orders, totalCount: data.searchOrdersToFulfill.totalCount}
  }

  async getUnfulfilledOrdersId(params: any) {
    const {data} = await apolloClient.query({
      query: GET_UNFULFILLED_ORDERS,
      variables: params,
    })

    const orders = data.searchOrdersToFulfill.nodes.map(({id}: any) => id)
    return orders
  }

  async updateOrder(id: string, patch: any) {
    return await apolloClient.mutate({
      mutation: UPDATE_ORDER,
      variables: {id, patch},
    })
  }

  async findOrder(id: string) {
    const {data} = await apolloClient.query({
      query: FIND_ORDER,
      variables: {
        id,
      },
    })
    const {order} = data
    return {
      ...order,
      orderItems: order.orderItems.nodes,
    }
  }

  async cancelOrder(id: any, note?: string) {
    const {data} = await apolloClient.mutate({
      mutation: ORDER_CANCEL,
      variables: {
        id,
        reason: note ?? 'Canceled from admin portal',
      },
    })
    return data.orderCancel
  }

  async getOrderStates(filters: any) {
    const {data} = await apolloClient.query({
      query: GET_ORDER_STATES,
      variables: mapFilter(filters),
    })
    return data.orderStates.nodes
  }
  async getOrderZipCodes(filters: any) {
    const {data} = await apolloClient.query({
      query: GET_ORDER_ZIP_CODES,
      variables: mapFilter(filters),
    })

    const orderZipCodes = data.orderZipCodes.nodes.map((code: any) => {
      return {id: code}
    })
    return orderZipCodes
  }

  async getOrderBottles(filters: any) {
    const {data} = await apolloClient.query({
      query: GET_ORDER_BOTTLES,
      variables: mapFilter(filters),
    })

    const orderBottles = data.orderBottles.nodes.map((bottle: any) => {
      return {id: bottle}
    })
    return orderBottles
  }

  async getOrderWeights(filters: any) {
    const {data} = await apolloClient.query({
      query: GET_ORDER_WEIGHTS,
      variables: mapFilter(filters),
    })

    const orderWeights = data.orderWeights.nodes.map((weight: any) => {
      return {id: weight}
    })
    return orderWeights
  }

  async updateFulfillmentLocation(params: any) {
    const {data} = await apolloClient.mutate({
      mutation: UPDATE_FULFILLMENT_LOCATION,
      variables: {id: params.id, locationId: params.locationId},
    })
    return data.updateFulfillment.fulfillment
  }

  async getFulfillmentCurrentLocation(id: any) {
    const {data} = await apolloClient.mutate({
      mutation: GET_FULFILLMENT_CURRENT_LOCATION,
      variables: id,
    })
    return data.fulfillment
  }

  createOrderNotes = async (orderId: string, notes: Record<string, any>) => {
    const {data, errors} = await apolloClient.mutate({
      mutation: CREATE_ORDER_NOTES,
      variables: {orderId, notes},
    })
    if (errors) {
      throw errors
    }
    return data.updateOrderNotesById.json
  }
}

const ordersService = new OrdersService()
export default ordersService

const mapFilter = (filters: any) => {
  if (filters.wineClubPackageId === 'new') {
    filters.wineClubPackageId = null
  }
  return filters
}
