import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik, FormikProvider} from 'formik'
import {useHistory, useLocation} from 'react-router-dom'
import {TextField} from '_core/forms'
import {Button} from '_core'
import {kebabCase} from 'lodash'
import storeService from 'app/services/StoreService'
import mapKeys from 'lodash/mapKeys'

const StoreDetailsSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, 'First Name must be at least 3 characters')
    .max(50, 'Maximum 50 characters ')
    .required('Please enter a First Name'),
  lastName: Yup.string()
    .min(3, 'Last Name must be at least 3 characters')
    .required('Please enter a Last Name'),
  password: Yup.string()
    .min(5, 'Password must be at least 5 characters')
    .max(50, 'Maximum 50 symbols')
    .required('Please enter a Password '),
  storeName: Yup.string()
    .min(4, 'Store Name must be at least 4 characters')
    .max(50, 'Maximum 50 characters ')
    .required('Please enter a Store Name'),
  domain: Yup.string()
    .min(4, 'Store URL must be at least 4 characters')
    .required('Please enter a Store URL'),
})

const initialValues = {
  firstName: '',
  lastName: '',
  password: '',
  storeName: '',
  domain: '',
}

export const charactersAllow = (value: any) => {
  return value
    .replace(/ /g, '-')
    .replace(/[^a-zA-Z0-9\-àç_èéù]/g, '')
    .trim()
    .replace(/[-\s]+/g, '-')
}

function StoreDetails() {
  const [storeUrl, setStoreUrl] = useState<any>()
  const [error, setError] = useState<any>('')
  const location: any = useLocation()
  const history = useHistory()

  useEffect(() => {
    if (!location.state?.email) {
      history.push('/store/create')
    }
  }, [location, history])

  const formik = useFormik({
    initialValues,
    validationSchema: StoreDetailsSchema,
    onSubmit: handleSave,
  })

  const {
    values: {domain},
  } = formik

  async function handleSave(model: any, {setSubmitting}: any) {
    try {
      setSubmitting(true)
      const {state: store}: any = location
      const domain = kebabCase(model.domain)
      const _model = {...model, ...store, domain}
      const out = await storeService.checkStoreNameAndDomainExists(model.storeName, domain)
      if (out.status === 200) {
        formik.resetForm()
        history.push('/store/details/payment', _model)
      } else {
        mapKeys(out.errors, (value, key) => {
          formik.setFieldError(key, value)
        })
      }
    } catch (error) {
      console.error(error)
    } finally {
      setSubmitting(false)
    }
  }

  const handleStoreNameChange = (e: any) => {
    const {value} = e.target
    const field = formik.getFieldHelpers('domain')
    field.setTouched(true, true)
    if (value) {
      const _val = kebabCase(value)
      formik.setFieldValue('storeName', value)
      if (storeUrl === undefined) {
        formik.setFieldValue('domain', _val)
      }
    } else {
      if (storeUrl === undefined) {
        formik.setFieldValue('domain', '')
      }
      formik.setFieldValue('storeName', '')
    }
  }

  const handleStoreURLChange = (e: any) => {
    const {value} = e.target
    const _val = charactersAllow(value)
    setError('')
    setStoreUrl(value)
    formik.setFieldValue('domain', _val)
  }

  const getStoreDomain = () => {
    const domain = window.location.hostname

    if (domain.includes('corksy.io')) {
      return domain
    }
    return 'admin.corksy.io'
  }

  return (
    <FormikProvider value={formik}>
      <div className='d-flex p-15 free-trail-card rounded-10 w-100 w-md-550px mx-auto'>
        <form className='form w-100 ' onSubmit={formik.handleSubmit} noValidate autoComplete='off'>
          <div className='text-center mb-15'>
            <h1 className='text-dark mb-3'>Enter your store details</h1>
          </div>
          <div className='fv-row mb-10 row'>
            <div className='col-6'>
              <div className='fv-row'>
                <label className='form-label fs-6 fw-bold text-dark required'>First Name</label>
                <TextField
                  id='txtFirstName'
                  placeholder='First Name'
                  autoComplete='off'
                  name='firstName'
                />
              </div>
            </div>
            <div className='col-6'>
              <div className='fv-row'>
                <label className='form-label fs-6 fw-bold text-dark required'>Last Name</label>
                <TextField
                  id='txtLastName'
                  placeholder='Last Name'
                  autoComplete='off'
                  name='lastName'
                />
              </div>
            </div>
          </div>
          <div className='fv-row mb-10'>
            <label className='form-label fs-6 fw-bold text-dark required'>Password</label>

            <TextField
              id='txtLoginPassword'
              type='password'
              placeholder='Password'
              autoComplete='off'
              name='password'
            />
          </div>
          <div className='fv-row mb-10 w-100'>
            <label className='form-label fs-6 fw-bold text-dark required'>Store Name</label>
            <TextField
              id='txtStoreName'
              placeholder='Store name'
              name='storeName'
              onChange={handleStoreNameChange}
            />
          </div>
          <div className='fv-row mb-10'>
            <label className='form-label fs-6 fw-bold text-dark required'>Store URL</label>
            <TextField
              id='txtStoreUrl'
              placeholder='Store URL'
              name='domain'
              isInputGroup={true}
              onChange={handleStoreURLChange}
              suffixInputGroup={'.corksy.io'}
            />
            {error && (
              <div className='fv-plugins-message-container invalid-feedback'>
                <i className='bi bi-exclamation-circle-fill text-danger me-2'></i>
                <span role='alert'>{error}</span>
              </div>
            )}
            {domain && (
              <div className='form-label pt-7 fs-6 fw-bold text-dark fw-bolder'>
                Admin URL: {kebabCase(domain)}.{getStoreDomain()}
              </div>
            )}
          </div>

          <div className='text-center'>
            <Button
              id='btnSave'
              type='submit'
              title='Setup Payment'
              isSubmitting={formik.isSubmitting}
              isValid={formik.isValid}
              className='btn-lg btn-primary w-100'
              endIcon='/media/icons/corksy/arrow-right.svg'
            />
          </div>
        </form>
      </div>
    </FormikProvider>
  )
}
export default StoreDetails
