import {FC, useState} from 'react'
import {KTSVG} from '_metronic/helpers'
import CreateCustomerDialog from 'app/features/orders/components/customer/CreateCustomerDialog'

type Props = {
  onSelect: (data: any) => void
  searchText: string
}
const CreateCustomer: FC<Props> = ({onSelect, searchText}) => {
  const [showDialog, setShowDialog] = useState(false)

  const handleCloseCustomer = () => {
    setShowDialog(false)
  }

  const handleOpenCreateCustomer = () => {
    setShowDialog(true)
  }

  return (
    <>
      <div className='text-center'>
        <div className='pt-10 pb-10'>
          <KTSVG path='/media/icons/duotune/files/fil024.svg' className='svg-icon-4x opacity-50' />
        </div>

        <div className='pb-15 fw-bold'>
          <h3 className='text-gray-600 fs-5 mb-2'>No result found</h3>
          <div className='text-muted fs-7'>Please try again with a different query</div>
          <div
            className='btn btn-transparent border-0 text-primary d-flex justify-content-center'
            onClick={handleOpenCreateCustomer}
          >
            <KTSVG
              path='/media/icons/duotune/arrows/arr075.svg'
              className='svg-icon-3 svg-icon-dark'
            />
            { searchText && `Add "${searchText}" as a new customer`}
            { !searchText && `Add new customer`}

          </div>
        </div>
      </div>

      {showDialog && (
        <CreateCustomerDialog
          searchText={searchText}
          onClose={handleCloseCustomer}
          onSelect={onSelect}
        />
      )}
    </>
  )
}

export default CreateCustomer
