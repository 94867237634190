import {FC} from 'react'
import clsx from 'clsx'

export type StepProps = {
  name: string
  currentStep: number
  description?: string
  step: number
}

const StepperNavItem: FC<StepProps> = ({name, description, step, currentStep}) => {
  const isActive = step - 1 === currentStep
  return (
    <div
      className={clsx(
        'stepper-item mx-6 my-4',
        isActive ? 'current' : '',
        step - 1 < currentStep ? 'completed' : ''
      )}
      data-kt-stepper-element='nav'
    >
      <div className='stepper-icon w-250px h-75px justify-content-center gap-4'>
        <span className='stepper-number pb-1'>{step}</span>
        <div className='stepper-label'>
          <h3 className='stepper-title'>{name}</h3>
          <div className='stepper-desc'>{description}</div>
        </div>
      </div>
    </div>
  )
}

export default StepperNavItem
