import {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '_metronic/partials'

export function PrivateRoutes() {
  const DashboardPage = lazy(() => import('app/features/dashboard/DashboardPage'))
  const ProductsPage = lazy(() => import('../features/products/ProductsPage'))
  const SettingsPage = lazy(() => import('../features/settings/SettingsPage'))
  const CustomersPage = lazy(() => import('../features/customers/CustomerPage'))
  const OrdersPage = lazy(() => import('../features/orders/OrdersPage'))
  const DiscountCodesPage = lazy(() => import('../features/discount-codes/DiscountCodesPage'))
  const ClubsPage = lazy(() => import('../features/wine-clubs/WineClubsPage'))
  const SegmentsPage = lazy(() => import('../features/segments/SegmentPage'))
  const ReportsPage = lazy(() => import('app/features/analytics/reports/ReportsPage'))
  const AllReportsPage = lazy(() => import('app/features/reports/ReportsPage'))
  const LoyaltyPage = lazy(() => import('../features/loyalty/LoyaltyPage'))
  const SubscriptionPage = lazy(() => import('../features/subscriptions/SubscriptionPage'))
  const MembershipPage = lazy(() => import('../features/subscriptions/Memberships'))
  const AnalyticsPage = lazy(() => import('../features/analytics/AnalyticsPage'))
  const PackagesPage = lazy(() => import('app/features/wine-tasting-event/PackagesPage'))
  const OnlineStorePage = lazy(() => import('app/features/onlineStore/OnlineStorePage'))
  const HomePage = lazy(() => import('app/features/home/HomePage'))
  const PlansPage = lazy(() => import('../features/plans/PlansPage'))
  const ShippingComplianceOrders = lazy(
    () => import('../features/message/shippingComplianceMessage/MessageList')
  )
  const IntegrationAppsPage = lazy(
    () => import('app/features/settings/integrated-apps/IntegrationPage')
  )

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/home' component={HomePage} />
        <Route path='/dashboard' component={DashboardPage} />
        <Route path='/products' component={ProductsPage} />
        <Route path='/settings' component={SettingsPage} />
        <Route path='/customers' component={CustomersPage} />
        <Route path='/wine_tastings' component={PackagesPage} />
        <Route path='/discounts' component={DiscountCodesPage} />
        <Route path='/orders' component={OrdersPage} />
        <Route path='/wine_clubs' component={ClubsPage} />
        <Route path='/wine/subscriptions' component={SubscriptionPage} />
        <Route path='/subscriptions/memberships' component={MembershipPage} />
        <Route path='/analytics/reports' component={ReportsPage} />
        <Route path='/reports' component={AllReportsPage} />
        <Route path='/analytics' component={AnalyticsPage} />
        <Route path='/marketing/segments' component={SegmentsPage} />
        <Route path='/loyalty' component={LoyaltyPage} />
        <Route path='/integrations' component={IntegrationAppsPage} />
        <Route path='/online_store' component={OnlineStorePage} />
        <Route path='/plans' component={PlansPage} />
        <Route path='/shipping_compliance/orders' component={ShippingComplianceOrders} />
        <Redirect from='/auth' to='/home' />
        <Redirect from='/store' to='/home' />
        <Redirect exact from='/' to='/home' />
        <Redirect from='/payment' to='/home' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
