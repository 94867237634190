export function FallbackView() {
  return (
    // <div className='inner-splash-screen'>
    //   <div className='d-flex justify-content-center w-300px'></div>
    //   <span>Loading ...</span>
    // </div>
    <div className='w-100 vh-75 d-flex justify-content-center align-items-center'>
      <label>
        <span className='indicator-progress d-block'>
          <span className='spinner-border align-middle ms-5 text-info'></span>
        </span>
        Loading...
      </label>
    </div>
  )
}
