import {gql} from '@apollo/client'

export const CREATE_CUSTOMER = gql`
  mutation CreateCustomer($customer: CustomerInput!) {
    createCustomer(input: {customer: $customer}) {
      customer {
        id
        defaultAddress {
          id
        }
      }
      clientMutationId
    }
  }
`

export const UPDATE_CUSTOMER = gql`
  mutation UpdateCustomer($id: UUID!, $customer: CustomerPatch!, $tagIds: [UUID]!) {
    updateCustomer(input: {id: $id, patch: $customer}) {
      clientMutationId
    }
    deleteCustomerTags(input: {cId: $id, tagIds: $tagIds}) {
      clientMutationId
    }
  }
`

export const UPDATE_TAGS = gql`
  mutation UpdateTags(
    $id: UUID!
    $tagIds: [UUID]!
    $customerTags: CustomerTagCustomerIdFkeyInverseInput!
  ) {
    updateCustomer(input: {patch: {customerTags: $customerTags}, id: $id}) {
      clientMutationId
    }
    deleteCustomerTags(input: {cId: $id, tagIds: $tagIds}) {
      clientMutationId
    }
  }
`

export const GET_CUSTOMER_CLASS = gql`
  query GetCustomerClass {
    customerClasses {
      nodes {
        id
        name
        description
      }
    }
  }
`

export const GET_CUSTOMER = gql`
  query GetCustomer($id: UUID!) {
    customer(id: $id) {
      id
      firstName
      lastName
      email
      phone
      shippingPreferenceCompany
      note
      classIds
      acceptsMarketing
      customerTags {
        nodes {
          id
          tagId
        }
      }
      customerSpouses {
        nodes {
          id
          firstName
          lastName
          email
          phone
        }
      }
    }
  }
`
export const GET_SUBSCRIPTION_CUSTOMER = gql`
  query GetCustomer($id: UUID!) {
    customer(id: $id) {
      firstName
      lastName
      email
      phone
      customerTags {
        nodes {
          id
          tagId
        }
      }
      customerAddresses(condition: {isDefault: true}) {
        nodes {
          id
          firstName
          lastName
          addressLine1
          addressLine2
          city
          stateId
          countryId
          phone
          zipCode
          isPrimary
          isLinkedWithPayment
        }
      }
    }
  }
`

export const CREATE_CUSTOMER_NOTIFICATION = gql`
  mutation CreateCustomerNotification($id: UUID!) {
    createCustomerNotification(input: {pCustomerId: $id}) {
      clientMutationId
    }
  }
`
