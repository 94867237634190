import {getStorePayments} from 'app/redux/StorePlanSlice'
import {FC, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from 'setup'
import Card from '_core/components/Card'

type Props = {
  toolbar: any
  children: React.ReactNode
}
const pageSize = 10
const PaymentWrapper: FC<Props> = ({toolbar, children}) => {
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1)
  const paging = useSelector<RootState>(({misc}) => misc.paging) as any
  const [filter, setFilter] = useState<any>({
    offset: 0,
    pageSize,
  })

  useEffect(() => {
    dispatch(getStorePayments(filter))
  }, [dispatch, filter])

  const handlePageChange = async (currentPage: any) => {
    setCurrentPage(currentPage)
    setFilter({
      ...filter,
      offset: (currentPage - 1) * pageSize,
      pageSize,
    })
  }

  return (
    <Card
      title='Payment Method'
      className='mb-6'
      paging={{
        totalCount: paging.totalCount,
        onPageChange: handlePageChange,
        currentPage,
        pageSize: filter.pageSize,
      }}
      toolbar={toolbar}
    >
      {children}
    </Card>
  )
}
export default PaymentWrapper
