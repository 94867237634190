import {FC, useEffect, useCallback} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {ToastContainer, toast} from 'react-toastify'
import {RootState} from 'setup'
import {MessageState, hideMessage} from 'app/redux/messageSlice'

const Message: FC = () => {
  const dispatch = useDispatch()
  const message = useSelector<RootState>(({misc}) => misc.message) as MessageState

  const hide = useCallback(() => {
    dispatch(hideMessage(false))
  }, [dispatch])

  useEffect(() => {
    if (message.state) {
      const {options} = message
      const _options = {...options, onClose: hide}

      switch (message.variant) {
        case 'success':
          toast.success(message.text, _options)
          break
        case 'error':
          toast.error(message.text, _options)
          break
        case 'warning':
          toast.warn(message.text, _options)
          break
        case 'info':
          toast.info(message.text, _options)
          break
        default:
          toast.success(message.text, _options)
          break
      }
    }
  }, [message, hide])
  if (message.state) {
    return (
      <ToastContainer
        containerId='message-containerId'
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        pauseOnHover
        autoClose={5000}
      />
    )
  }
  return null
}

export default Message
