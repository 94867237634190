import React, {useEffect, useMemo, useState} from 'react'
import {useMutation, useLazyQuery} from '@apollo/client'
import {useFormik, FormikProvider} from 'formik'
import {useHistory, useParams, Link, useLocation} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import Button from '_core/components/Button'
import {showMessage} from 'app/redux/messageSlice'
import {
  CustomerElavonModel,
  customerElavonInitialValues,
  CustomerElavonSchema,
} from 'app/features/customers/models/Customer'
import {
  CREATE_CUSTOMER,
  UPDATE_CUSTOMER,
  GET_CUSTOMER,
  CREATE_CUSTOMER_NOTIFICATION,
} from '../graphql/customer'
import {
  mapToCustomerModel,
  getCustomerId,
  mapToCreateCustomerModel,
  mapToUpdateCustomerModel,
} from './helper'
import {SimplePage} from '_core'
import customerService from 'app/services/CustomerService'
import CustomerInformation from './CustomerInformation'
import BreadCrumbHeader from '_core/components/BreadCrumbs/BreadCrumbHeader'
import CustomerEmailAlert from './CustomerEmailAlert'

type Props = {
  onCustomerCreate: (customer: CustomerElavonModel) => any
  searchText?: string
  onClose?: (value: any) => any
  closeBtnChildren?: any
  isCreate?: boolean
}

const CustomerElavon: React.FC<Props> = ({
  searchText,
  closeBtnChildren,
  isCreate,
  onCustomerCreate,
}) => {
  const [createCustomer] = useMutation(CREATE_CUSTOMER)
  const [updateCustomer] = useMutation(UPDATE_CUSTOMER)
  const [createCustomerNotification] = useMutation(CREATE_CUSTOMER_NOTIFICATION)
  const [getCustomer, {loading, error, data}] = useLazyQuery(GET_CUSTOMER, {
    fetchPolicy: 'cache-and-network',
  })

  const [errorMessage, setErrorMessage] = useState('')
  const history = useHistory()
  const {state}: any = useLocation()
  const routerParams: any = useParams()
  const dispatch = useDispatch()

  const formik = useFormik<CustomerElavonModel>({
    initialValues: customerElavonInitialValues,
    validationSchema: CustomerElavonSchema,
    onSubmit: (values) => {
      handleSubmit(values)
    },
  })

  const {setValues, setFieldValue, setFieldError, resetForm, setSubmitting} = formik

  useEffect(() => {
    return () => {
      resetForm()
    }
  }, [resetForm])

  useEffect(() => {
    if (searchText) {
      setFieldValue('firstName', searchText)
    }
  }, [searchText, setFieldValue])

  useEffect(() => {
    if (routerParams.id) {
      getCustomer({variables: {id: routerParams.id}})
    }
  }, [routerParams, getCustomer])

  useEffect(() => {
    if (data && !isCreate) {
      const customer: any = mapToCustomerModel(data.customer)
      setValues(customer)
    }
  }, [data, setValues, isCreate])

  const handleSubmit = async (customer: CustomerElavonModel) => {
    try {
      setSubmitting(true)
      const {address, tagIds, ...rest}: any = customer
      if (routerParams.id && !isCreate) {
        await update(rest, tagIds)
      } else {
        if (await checkCustomerEmail(customer.email, customer?.id)) {
          if (customer.isEnabledCustomerMethods) {
            address.firstName = customer.firstName
            address.lastName = customer.lastName
            await create(rest, address, tagIds)
          } else {
            await onCustomerCreate(customer)
          }
        }
      }
    } catch (error) {
      console.error(error)
      dispatch(showMessage({text: 'An error occurred while saving changes', variant: 'error'}))
    } finally {
      setSubmitting(false)
    }
  }

  const checkCustomerEmail = async (email: string, id?: string) => {
    formik.setSubmitting(true)
    setErrorMessage('')
    const isEmailExist = await customerService.checkEmailExist({email, id})
    formik.setSubmitting(false)
    if (isEmailExist) {
      const message = 'Email already exists'
      setErrorMessage(message)
      setFieldError('email', message)
      return false
    }
    return true
  }

  const create = async (customer: any, address: any, tags: any) => {
    const cust = mapToCreateCustomerModel(address, customer, tags)
    let response: any
    response = await createCustomer({variables: {customer: cust}})
    const {id, addressId} = getCustomerId(response)
    await createCustomerNotification({variables: {id}})
    setFieldValue('id', id)
    setFieldValue('address.id', addressId)
  }

  const update = async (customer: any, tags: any) => {
    const {cust, tagIds} = mapToUpdateCustomerModel(customer, tags)
    await updateCustomer({variables: {customer: cust, id: routerParams.id, tagIds}})
    dispatch(
      showMessage({
        text: 'Customer updated successfully',
        variant: 'success',
      })
    )
    history.push('/customers')
  }

  const path = useMemo(() => {
    if (state) {
      if (state.customerId) {
        return `/customers/${state.customerId}/details`
      } else if (state.redirectURL) {
        return state.redirectURL
      }
    }
    return '/customers'
  }, [state])

  return (
    <>
      <BreadCrumbHeader title='View Customer' path={path} />
      <SimplePage loading={loading} error={error}>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <CustomerInformation error={errorMessage} isCreate={isCreate} />
            <div className='d-flex justify-content-end mb-5 me-9'>
              <div className='me-5'>
                {closeBtnChildren ? (
                  closeBtnChildren
                ) : (
                  <Link
                    to={path}
                    id='btnCustomerCancel'
                    type='button'
                    className='btn explore-btn-outline'
                  >
                    Cancel
                  </Link>
                )}
              </div>
              <CustomerEmailAlert
                email={data?.customer?.email}
                onSave={handleSubmit}
                onEmailCheck={checkCustomerEmail}
              >
                <Button
                  id='btnCustomerSave'
                  type='submit'
                  title='Save'
                  isSubmitting={formik.isSubmitting}
                  isValid={formik.isValid}
                  className='btn-primary'
                />
              </CustomerEmailAlert>
            </div>
          </form>
        </FormikProvider>
      </SimplePage>
    </>
  )
}

export default CustomerElavon
