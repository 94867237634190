import {UserModel} from 'app/features/auth/models/UserModel'
import {forwardRef} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from 'setup'
import ElavonForm from './Elavon'
import StripeForm from './StripeForm'
import {paymentProviderTypes} from 'app/constant'
import toLowerCase from 'lodash/toLower'

type Props = {}

const PaymentForm = forwardRef((props: Props, ref) => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  if (toLowerCase(user.store?.paymentProviderType) === paymentProviderTypes.elavon) {
    return <ElavonForm />
  }
  return <StripeForm ref={ref} />
})

export default PaymentForm
