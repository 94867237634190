import {FC} from 'react'
import clsx from 'clsx'

type Props = {
  show: boolean
  loaderText?: any
}

const Loader: FC<Props> = ({show, loaderText}) => {
  return (
    <div
      className={clsx(
        'splash-screen d-flex flex-column align-items-center justify-content-center p-24 bg-transparent z-index-1080',
        !show && 'd-none'
      )}
    >
      <span className='indicator-progress d-block'>
        <span className='spinner-border align-middle ms-5 text-info'></span>
      </span>
      {loaderText}
    </div>
  )
}

export default Loader

export const Spinner = () => {
  return (
    <div className='d-flex w-100 justify-content-center'>
      <span className='indicator-progress d-block'>
        <span className='spinner-border align-middle ms-5 text-info'></span>
      </span>
    </div>
  )
}
