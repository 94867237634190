import {gql} from '@apollo/client'

export const GET_SUBSCRIPTION_INVOICES_LIST = gql`
  query getSubscriptionInvoices {
    subscriptionInvoices(filter: {total: {greaterThan: "0"}, status: {equalTo: OPEN}}) {
      nodes {
        id
        invoiceNumber
        invoiceDate
        periodStart
        periodEnd
        fee
        status
        storeId
        storePlanId
        subtotal
        tax
        total
        totalApplicationFee
        totalTaxAmounts
      }
      totalCount
    }
  }
`
