import {gql} from '@apollo/client'

export const GET_CUSTOMER_ORDERS = gql`
  query GetCustomerOrders(
    $customerId: UUID!
    $offset: Int!
    $pageSize: Int!
    $orderBy: [OrdersOrderBy!]
  ) {
    orders(
      first: $pageSize
      offset: $offset
      orderBy: $orderBy
      filter: {customerId: {equalTo: $customerId}}
    ) {
      nodes {
        id
        orderNumber
        fulfillmentStatus
        financialStatus
        itemCount
        totalPrice
        createdAt
        customer {
          firstName
          lastName
        }
      }
      totalCount
    }
  }
`
