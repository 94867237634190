import {FC} from 'react'
import {Modal} from 'react-bootstrap-v5'
import {ModalHeader} from '_core/components/Modal'
import PaymentMethodForm from './PaymentMethodForm'

type Props = {
  onClose: any
  customerId: any
  addressList: Array<any>
  isAddressFetch?: boolean
}

const PaymentFormDialog: FC<Props> = ({onClose, customerId, addressList, isAddressFetch}) => {
  return (
    <Modal
      size='lg'
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='h-auto'
      show={true}
      centered
    >
      <ModalHeader
        title='Add New Payment Method'
        toolbar={{
          onClick: onClose,
        }}
      />
      <div className='modal-body align-items-center'>
        <PaymentMethodForm
          onClose={onClose}
          customerId={customerId}
          addressList={addressList}
          isAddressFetch={isAddressFetch}
        />
      </div>
    </Modal>
  )
}
export default PaymentFormDialog
