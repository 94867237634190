import {FC, useState} from 'react'
import {capitalize} from 'lodash'
import clsx from 'clsx'
import {Button} from '_core'
import ChangeBillingAddress from './ChangeBillingAddress'
import DeletePaymentMethod from './DeletePaymentMethod'

type Props = {
  payment: any
  onSelect: (id: any) => void
  address: any
  customerId: string
  onRefetch: () => void
}
const PaymentListItem: FC<Props> = ({payment, onSelect, address, customerId, onRefetch}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const handleSelect = async () => {
    try {
      setIsSubmitting(true)
      await onSelect(payment.id)
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <div className='col-xxl-4 pb-6 col-lg-6 col-md-6'>
      <div className={clsx('card card-dashed flex-row flex-stack flex-wrap p-4 min-h-100')}>
        <div className='d-flex flex-column py-3 mx-5 w-100'>
          <div className='d-flex justify-content-between gap-20'>
            <div className='fs-6 fw-semibold text-gray-700'>
              {`${capitalize(payment.card.brand)} **** ${payment.card.last4}`}
              <br />
              {payment.card.expMonth}/{payment.card.expYear}
            </div>
            <div>
              {payment.isPrimary && (
                <span className={`badge badge badge-light-success`}>Primary</span>
              )}
            </div>
          </div>
          {address && (
            <div className='fs-6 fw-bold text-gray-600 pt-5'>
              {address.addressLine1}
              <div>{address.addressLine2}</div>
              <div>{`${address.city}, ${address.state.code} ${address.zipCode}, ${address.country.code}`}</div>
            </div>
          )}

          <div className='d-flex justify-content-start gap-1 align-items-center pt-3'>
            <DeletePaymentMethod
              isAttachedWineClubMembership={payment.wineClubSubscriptions.totalCount > 0}
              paymentMethodId={payment.id}
              onRefetch={onRefetch}
            />
            <div className='me-2'>|</div>
            <ChangeBillingAddress
              payment={payment}
              selectedAddress={address?.id}
              customerId={customerId}
            />

            {!payment.isPrimary && (
              <>
                <div className='me-2'>|</div>
                <Button
                  id='btnAddressPrimary'
                  type='button'
                  isSubmitting={isSubmitting}
                  className='btn btn-sm btn-transparent m-0 p-0 text-decoration-underline bg-white'
                  onClick={handleSelect}
                  title='Set as primary'
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
export default PaymentListItem
