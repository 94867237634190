import {FC, useState} from 'react'
import MessagePopover from '_core/components/MessagePopover'
import {paymentMethodDeleteMessage} from 'app/constant/payment'
import ConfirmationDialog from '_core/components/ConfirmationDialog'
import {KTSVG} from '_metronic/helpers'
import paymentService from 'app/services/PaymentService'
import {useDispatch} from 'react-redux'
import {showMessage} from 'app/redux/messageSlice'

type Props = {
  isAttachedWineClubMembership: boolean
  paymentMethodId: string
  buttonIsIcon?: boolean
  onRefetch?: () => void
  className?: string
  iconClassName?: string
}
const DeletePaymentMethod: FC<Props> = ({
  isAttachedWineClubMembership,
  paymentMethodId,
  buttonIsIcon,
  onRefetch,
  className = 'btn btn-transparent btn-sm m-0 p-0 me-2 btn-active-text-primary text-decoration-underline bg-white',
  iconClassName = 'btn btn-icon btn-sm btn-outline btn-outline-secondary btn-active-text-white me-2',
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showDialog, setShowDialog] = useState(false)
  const dispatch = useDispatch()

  const handleOpen = () => {
    setShowDialog(true)
  }

  const handleClose = () => {
    setShowDialog(false)
  }

  const handleDeletePaymentMethod = async () => {
    try {
      setIsSubmitting(true)
      await paymentService.deletePaymentMethod(paymentMethodId)
      if (onRefetch) {
        onRefetch()
      }
      handleClose()
      dispatch(
        showMessage({
          text: 'Customer Payment Method has been deleted successfully ',
          variant: 'success',
        })
      )
    } catch (error) {
      console.log(error)
      dispatch(
        showMessage({
          text: 'An error occurred while delete Payment Method',
          variant: 'error',
        })
      )
    } finally {
      setIsSubmitting(false)
    }
  }

  if (isAttachedWineClubMembership) {
    return (
      <MessagePopover
        message={paymentMethodDeleteMessage}
        status={
          <button type='button' className={className} disabled={isAttachedWineClubMembership}>
            Delete
          </button>
        }
      />
    )
  }

  return (
    <>
      {buttonIsIcon ? (
        <button
          id='btnDeletePopUp'
          className={iconClassName}
          onClick={handleOpen}
          title='Delete Payment Method'
        >
          <KTSVG path='/media/icons/corksy/trash.svg' className='svg-icon-3' />
        </button>
      ) : (
        <button
          type='button'
          className={className}
          onClick={handleOpen}
          title='Delete Payment Method'
        >
          Delete
        </button>
      )}
      {showDialog && (
        <ConfirmationDialog
          show
          title='Delete Payment Method'
          message='Are you sure to delete this payment method?'
          onClose={handleClose}
          onConfirm={handleDeletePaymentMethod}
          isSubmitting={isSubmitting}
        />
      )}
    </>
  )
}
export default DeletePaymentMethod
