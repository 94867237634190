import {FC} from 'react'

const CorksyLoader: FC = () => {
  return (
    <div className='splash-screen d-flex flex-column align-items-center justify-content-center p-24 bg-transparent z-index-1080'>
      <div className='loader-logo'>
        <svg
          width='75'
          height='49'
          viewBox='0 0 75 49'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M73.2801 38.5857L48.5116 2.65649C46.555 -0.160788 42.6997 -0.846201 39.9055 1.12654C38.505 2.1152 37.6398 3.57777 37.3646 5.1513C37.0906 6.7188 37.4097 8.39525 38.3877 9.8038L51.9676 29.622L51.7955 29.3739C52.4476 30.3131 52.2223 31.6034 51.2956 32.2675L51.2887 32.2719C50.3549 32.9264 49.0755 32.6988 48.4234 31.7597C48.421 31.7564 48.421 31.7564 48.4186 31.753L39.9193 19.5142C37.9631 16.6973 34.1071 16.0115 31.3133 17.9845C29.9127 18.9729 29.0442 20.4379 28.7703 22.0057C28.4951 23.5782 28.8139 25.253 29.7906 26.6612L29.7892 26.6592C30.4414 27.5984 30.2156 28.8884 29.289 29.5528L29.2824 29.5576C28.3611 30.2027 27.1039 29.9888 26.4443 29.0811L26.4202 29.0461C24.4627 26.2275 20.61 25.5425 17.8144 27.5161C15.0184 29.49 14.3391 33.375 16.2964 36.1936L22.9158 45.7255C24.8737 48.5442 28.7267 49.2293 31.5223 47.2556C34.318 45.2818 34.9974 41.3967 33.0399 38.578L31.1164 35.8083C30.4953 34.8753 30.7287 33.6072 31.6449 32.9599L31.6518 32.9551C32.5799 32.3001 33.8618 32.5309 34.5147 33.4642L43.0246 45.7176C44.9811 48.5351 48.8365 49.2203 51.634 47.2454C53.0312 46.2587 53.8999 44.7941 54.1772 43.2241C54.4491 41.6529 54.1296 39.9766 53.1514 38.5681L53.1492 38.5648C53.1469 38.5613 53.1469 38.5613 53.1445 38.5582C52.4947 37.6223 52.7237 36.33 53.6518 35.6745L53.6583 35.6701C54.5857 35.015 55.8659 35.245 56.5195 36.1764L63.1562 45.7328C65.1151 48.5536 68.9653 49.2379 71.7627 47.2629C73.1595 46.2768 74.0282 44.8117 74.3057 43.2417C74.5774 41.6705 74.2607 39.9977 73.2801 38.5857ZM4.46568 37.1874C1.92738 39.1303 -0.466159 47.0339 0.0779686 47.8508C0.622278 48.668 8.90587 48.3981 11.4442 46.455C13.9823 44.5121 14.4777 40.8624 12.5508 38.3031C10.6238 35.744 7.00397 35.2445 4.46568 37.1874Z'
            fill='url(#paint0_linear_1943_133832)'
          />
          <defs>
            <linearGradient
              id='paint0_linear_1943_133832'
              x1='58.9499'
              y1='21.528'
              x2='18.7314'
              y2='46.9933'
              gradientUnits='userSpaceOnUse'
            >
              <stop stopColor='#A229B6' />
              <stop offset='0.305234' stopColor='#6E47C4' />
              <stop offset='0.638658' stopColor='#4063D5' />
              <stop offset='1' stopColor='#1780DB' />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div className='loader'>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  )
}

export default CorksyLoader
