import {FC} from 'react'
import {useFormikContext} from 'formik'
import clsx from 'clsx'
import NumberFormat from 'react-number-format'

type Props = {
  id?: string
  name: string
  placeholder?: string
  className?: string
  min?: number
  max?: number
  suffix?: string
  isAllowed?: any
  handleChange?: (e: any) => void
  decimalScale?: number | undefined
}

const CurrencyField: FC<Props> = ({
  placeholder,
  className,
  name,
  min,
  max,
  suffix,
  isAllowed,
  handleChange,
  ...props
}) => {
  const {getFieldMeta, getFieldProps} = useFormikContext()
  const {touched, error} = getFieldMeta(name)
  const {onChange, ...rest} = getFieldProps(name)
  const id = props.id || `txt${name}`
  return (
    <>
      <NumberFormat
        thousandSeparator={true}
        prefix={'$'}
        {...rest}
        {...props}
        placeholder={placeholder}
        allowNegative={false}
        name={name}
        min={min}
        max={max}
        suffix={suffix}
        isAllowed={isAllowed}
        onChange={(values: any) => {
          if (handleChange) {
            handleChange(values)
          }
        }}
        onValueChange={(values) => {
          onChange({
            target: {
              name: name,
              value: values.value,
            },
          })
        }}
        className={clsx(
          'form-control',
          className,
          {'is-invalid': touched && error},
          {
            'is-valid': touched && !error,
          }
        )}
        id={id}
      />

      {touched && error && (
        <div className='fv-plugins-message-container invalid-feedback'>
          <i className='bi bi-exclamation-circle-fill text-danger me-2'></i>
          <span role='alert'>{error}</span>
        </div>
      )}
    </>
  )
}

export default CurrencyField
