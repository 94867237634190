import {gql} from '@apollo/client'

export const CREATE_PAYMENT = gql`
  mutation CreatePaymentMethod($model: CardPaymentMethodInput!) {
    createPaymentMethod2(input: $model) {
      errors
      paymentMethodId
      status
    }
  }
`

export const UPDATE_PAYMENT_METHOD = gql`
  mutation UpdatePaymentMethod($id: UUID!, $patch: PaymentMethodPatch!) {
    updatePaymentMethod(input: {patch: $patch, id: $id}) {
      clientMutationId
    }
  }
`

export const PAYMENT_METHOD_UPDATE = gql`
  mutation paymentMethodUpdate($model: PaymentMethodUpdateV2Input!) {
    paymentMethodUpdate(input: $model) {
      error
      status
    }
  }
`

export const GET_PAYMENTS = gql`
  query GetPayments($customerId: UUID!) {
    paymentMethods(
      orderBy: IS_PRIMARY_DESC
      condition: {customerId: $customerId}
      filter: {deletedAt: {isNull: true}}
    ) {
      nodes {
        id
        isPrimary
        wineClubSubscriptions(filter: {status: {notEqualTo: CANCELLED}}) {
          totalCount
        }
        card {
          expMonth
          expYear
          last4
          brand
        }
        address {
          id
          firstName
          lastName
          addressLine1
          addressLine2
          city
          state {
            code
          }
          country {
            code
          }
          zipCode
        }
      }
    }
  }
`

export const CHECK_PAYMENT_METHOD = gql`
  query CheckPaymentMethod($customerId: UUID!, $paymentMethodId: UUID!) {
    checkPaymentMethod(pCustomerId: $customerId, paymentMethodId: $paymentMethodId)
  }
`

export const REMOVE_DRAFT_ORDER_DISCOUNTS = gql`
  mutation RemoveDraftOrderDiscounts($discount: RemoveDraftOrderDiscountsInput!) {
    removeDraftOrderDiscounts(input: $discount) {
      clientMutationId
    }
  }
`
