import {gql} from '@apollo/client'

export const GET_STORE = gql`
  query GetStore {
    stores {
      nodes {
        id
        name
        companyName
        phone
        addressLine2
        addressLine1
        domain
        city
        stateId
        countryId
        timeZone
        zipCode
        email
        customerEmail
        unitSystem
        weightUnit
      }
    }
  }
`

export const UPDATE_STORE = gql`
  mutation UpdateStore(
    $id: UUID!
    $store: StorePatch!
    $setupId: UUID!
    $storeSetup: StoreSetupPatch!
  ) {
    updateStore(input: {id: $id, patch: $store}) {
      clientMutationId
    }
    updateStoreSetup(input: {id: $setupId, patch: $storeSetup}) {
      clientMutationId
    }
  }
`
export const UPDATE_STORE_LOGO = gql`
  mutation UpdateStore($id: UUID!, $store: StorePatch!) {
    updateStore(input: {id: $id, patch: $store}) {
      clientMutationId
    }
  }
`

export const UPDATE_STORE_NAME = gql`
  mutation UpdateStore($id: UUID!, $store: StorePatch!) {
    updateStore(input: {id: $id, patch: $store}) {
      clientMutationId
    }
  }
`

export const GET_STATE = gql`
  query GetCollection($id: UUID!) {
    state(id: $id) {
      name
      code
    }
  }
`
