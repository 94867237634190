import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {setTotalCount} from 'app/redux/pagingSlice'
import customerOrdersService from '../components/service/CustomerOrdersService'

export const getCustomerOrders = createAsyncThunk(
  'customer/orders',
  async (filter: any, {dispatch}) => {
    dispatch(setFetching(true))
    const {data, totalCount} = await customerOrdersService.getCustomerOrders(filter)
    dispatch(setTotalCount(totalCount))
    return data
  }
)

export const getCustomerWineTasting = createAsyncThunk(
  'customer/wineTasting',
  async (filter: any, {dispatch}) => {
    dispatch(setFetching(true))
    const {data, totalCount} = await customerOrdersService.getCustomerWineTasting(filter)
    dispatch(setTotalCount(totalCount))
    return data
  }
)

export const getCustomerWineClub = createAsyncThunk(
  'customer/wineClub',
  async (filter: any, {dispatch}) => {
    dispatch(setFetching(true))
    const {data, totalCount} = await customerOrdersService.getCustomerWineClub(filter)
    dispatch(setTotalCount(totalCount))
    return data
  }
)
export const getCustomerSubscription = createAsyncThunk(
  'customer/subscription',
  async (filter: any, {dispatch}) => {
    dispatch(setFetching(true))
    const {data, totalCount} = await customerOrdersService.getCustomerSubscriptions(filter)
    dispatch(setTotalCount(totalCount))
    return data
  }
)

const customerBookingSlice = createSlice({
  name: 'customer/bookings',
  initialState: {
    orders: [],
    wineTasting: [],
    wineClub: [],
    subscriptions: [],
    isFetching: false,
    filter: {
      offset: 0,
      pageSize: 10,
      orderBy: null,
      customerId: '',
    },
  },
  reducers: {
    setFetching: (state, action) => {
      state.isFetching = action.payload
    },
    setCustomerId: (state, action) => {
      state.filter.customerId = action.payload
    },
    setFilter: (state, action) => {
      state.filter = {...state.filter, ...action.payload}
    },
  },
  extraReducers: {
    [getCustomerOrders.fulfilled.toString()]: (state, action) => {
      state.orders = action.payload
      state.isFetching = false
    },
    [getCustomerWineTasting.fulfilled.toString()]: (state, action) => {
      state.wineTasting = action.payload
      state.isFetching = false
    },
    [getCustomerWineClub.fulfilled.toString()]: (state, action) => {
      state.wineClub = action.payload
      state.isFetching = false
    },
    [getCustomerSubscription.fulfilled.toString()]: (state, action) => {
      state.subscriptions = action.payload
      state.isFetching = false
    },
  },
})

export const {setFetching, setCustomerId, setFilter} = customerBookingSlice.actions

export default customerBookingSlice.reducer
