/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC, useEffect, useState} from 'react'
import {Redirect, Switch, Route, useLocation} from 'react-router-dom'
import {shallowEqual, useSelector} from 'react-redux'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {PrivateRoutes} from './PrivateRoutes'
import {AuthPage} from '../features/auth'
import StorePage from '../features/signup/StorePage'
import {ErrorsPage} from '../features/errors/ErrorsPage'
import {RootState} from '../../setup'
import {SubscriptionProvider} from 'app/features/subscription/SubscriptionContext'

const excludedUrl = (pathname: string) => {
  return pathname.indexOf('auth') > 0 || pathname === '/store'
}

const Routes: FC = () => {
  const isAuthorized = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
  const isLoggedOut = useSelector<RootState>(({auth}) => auth.isLoggedOut, shallowEqual)
  const location = useLocation()
  const [redirectUrl, setRedirectUrl] = useState('/')

  useEffect(() => {
    if (!excludedUrl(location.pathname) && !isAuthorized) {
      setRedirectUrl(location.pathname)
    } else if (isLoggedOut) {
      setRedirectUrl('/')
    }
  }, [location, isAuthorized, isLoggedOut])

  return (
    <Switch>
      <Route path='/store' component={StorePage} />

      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from='/auth' to={redirectUrl} />
      )}

      <Route path='/error' component={ErrorsPage} />
      {/* <Route path='/logout' component={Logout} /> */}

      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to='/auth/login' />
      ) : (
        <MasterLayout>
          <SubscriptionProvider redirectUrl={redirectUrl}>
            <PrivateRoutes />
          </SubscriptionProvider>
        </MasterLayout>
      )}
    </Switch>
  )
}

export {Routes}
