import {FC} from 'react'
import {useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {RootState} from 'setup'
import PaymentList from './PaymentList'

type Props = {}
const PaymentMethods: FC<Props> = () => {
  const paymentMethodList = useSelector<RootState>(
    ({customers}) => customers.paymentMethods.list
  ) as any[]

  const addressList = useSelector<RootState>(
    ({customers}) => customers.customer.customerAddress
  ) as any[]

  const isAddressFetch = useSelector<RootState, boolean>(
    ({customers}) => customers.customer.isFetching
  )

  const {id} = useParams<any>()

  return (
    <PaymentList
      isAddressFetch={isAddressFetch}
      paymentMethodList={paymentMethodList}
      customerId={id}
      addressList={addressList}
    />
  )
}
export default PaymentMethods
