import {FC} from 'react'
import clsx from 'clsx'
import {useSelector} from 'react-redux'
import {RootState} from 'setup'
import {LoaderState} from 'app/redux/loaderSlice'

const Loading: FC = () => {
  const loader = useSelector<RootState>(({misc}) => misc.loader) as LoaderState

  return (
    <div
      className={clsx(
        'splash-screen d-flex flex-column align-items-center justify-content-center p-24 bg-transparent z-index-1080',
        !loader.show && 'd-none'
      )}
    >
      <span className='indicator-progress d-block'>
        <span className='spinner-border align-middle ms-5 text-info'></span>
      </span>
    </div>
  )
}

export default Loading
