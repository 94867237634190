import {FC} from 'react'
import filter from 'lodash/filter'
import {Select} from '_core/forms'

const units = [
  // {
  //   unitSystem: 'Metric',
  //   id: 'Kilogram (kg)',
  //   name: 'Kilogram (kg)',
  // },
  // {
  //   unitSystem: 'Metric',
  //   id: 'Gram (g)',
  //   name: 'Gram (g)',
  // },
  {
    unitSystem: 'Imperial',
    id: 'Pound (lb)',
    name: 'Pound (lbs)',
  },
  // {
  //   unitSystem: 'Imperial',
  //   id: 'Ounce (oz)',
  //   name: 'Ounce (oz)',
  // },
]

type Props = {
  name: string
  unitSystem: string
  onChange?: any
  className?: string
  label: string
}

const UnitLookup: FC<Props> = ({className, name, unitSystem, label}) => {
  return (
    <div className={className}>
      <label className='form-label fs-6 fw-bold text-dark required'>{label}</label>
      <Select id='sltUnitSystem' name={name}>
        <option value=''>Select</option>
        {filter(units, {unitSystem}).map(({id, name}) => (
          <option value={id} key={id}>
            {name}
          </option>
        ))}
      </Select>
    </div>
  )
}

export default UnitLookup
