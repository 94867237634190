/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {UserModel} from '../../../../app/features/auth/models/UserModel'
import {RootState} from '../../../../setup'
import * as auth from '../../../../app/features/auth/redux/AuthRedux'
import {useDispatch} from 'react-redux'
import {toAbsoluteUrl} from '../../../helpers'
import truncate from 'lodash/truncate'
import {DataUtil} from '_metronic/assets/ts/_utils'

const HeaderUserMenu: FC = () => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  const dispatch = useDispatch()
  const logout = () => {
    DataUtil.clear()
    dispatch(auth.actions.logout())
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-40px me-5'>
            <div
              className='symbol-label'
              style={{backgroundImage: `url('${toAbsoluteUrl('/media/avatars/avatar.svg')}')`}}
            >
              <span className='avatar-name'>{user?.avatarText}</span>
            </div>
          </div>

          <div>
            <div className='fw-bolder align-items-center fs-5 '>
              <span className='text-break'>
                {user.firstName} {user.lastName && truncate(user.lastName, {length: 20})}
              </span>
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {user.email &&
                truncate(user.email, {
                  length: 30,
                })}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <a id='linkLogout' onClick={logout} className='menu-link px-5'>
          Sign Out
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
