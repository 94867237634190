import CustomerTagService from 'app/services/CustomerService/CustomerTagService'
import * as yup from 'yup'

export const CustomerTagSchema = yup.object().shape({
  name: yup.string().required('Name is required').test({
    name: 'customertagDefinition',
    message: 'Customer tag already exists',
    test: async (name = '') => {
      if (name.length > 2) {
        const totalCount = await CustomerTagService.checkCustomerTagAvailable(name)
        return !(totalCount > 0)
      }
      return true
    },
  }),
  description: yup.string().trim().max(299, 'Description maximum 300 characters'),
})

export const defaultValues = {
  name: '',
  tagType: 'MANUAL',
  description: '',
}

export const tags = [{id: 'MANUAL', name: 'Manual'}]

export interface InputFormat {
  readonly value: string
  readonly label: string
}

export const searchTags = (inputValue: string, array: any) => {
  return array.filter((i: any) => i.label.toLowerCase().includes(inputValue.toLowerCase()))
}
