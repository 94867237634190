import {FC} from 'react'
import StepperNavItem from './StepperNavItem'

type Props = {
  steps: any[]
  children: React.ReactNode
  currentStep: number
}
const Stepper: FC<Props> = ({steps, children, currentStep}) => {
  return (
    <div className='stepper stepper-pills' id='kt_stepper_example_basic'>
      <div className='stepper-nav flex-center flex-wrap mb-10 flex-row flex-column-fluid ps-15'>
        {steps.map((step, index) => (
          <StepperNavItem {...step} key={index} currentStep={currentStep} />
        ))}
      </div>
      {children}
    </div>
  )
}

export default Stepper
