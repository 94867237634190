import {FC, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from 'setup'
import PaymentForm from 'app/features/plans/components/payment/PaymentForm'
import {hideLoader} from 'app/redux/loaderSlice'

type Props = {
  onSubmit: (values: any) => void
  onCurrentPage?: (page: number) => void
}

const AddPaymentMethod: FC<Props> = ({onSubmit, onCurrentPage}) => {
  const dispatch = useDispatch()
  const storePaymentsList = useSelector<RootState>(
    ({misc}) => misc.storePlan.storePaymentsList
  ) as any[]

  const [showDialog, setShowDialog] = useState<boolean>(false)

  const handleOpen = () => {
    setShowDialog(true)
  }

  const handleClose = () => {
    setShowDialog(false)
    if (onCurrentPage) {
      onCurrentPage(1)
    }
    dispatch(hideLoader())
  }

  return (
    <div className='col-xxl-6'>
      <div className='card notice d-flex bg-light rounded border-secondary border border-dashed '>
        <div className='d-flex justify-content-center m-8 '>
          <button
            id='btnAddPayment'
            type='button'
            onClick={handleOpen}
            className='btn btn-outline btn-light-dark btn-outline '
          >
            Add New Card
          </button>
        </div>

        {showDialog && (
          <PaymentForm
            showDialog={handleOpen}
            storePaymentsList={storePaymentsList}
            onClose={handleClose}
            onSubmit={onSubmit}
          />
        )}
      </div>
    </div>
  )
}
export default AddPaymentMethod
