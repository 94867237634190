import apolloClient from 'apollo-client'
import {SEARCH_CUSTOMER} from 'app/components/graphql'
import {orderTypes} from 'app/constant'
import {SEARCH_PRODUCTS, SEARCH_WINE_CLUB_PRODUCTS} from './graphql'

class SearchProductsService {
  searchProducts = async (search: string) => {
    const {data} = await apolloClient.query({
      query: SEARCH_PRODUCTS,
      variables: {search, orderType: orderTypes.ADMIN_PORTAL},
    })

    return mapToSearchProductsModel(data.products.nodes, orderTypes.ADMIN_PORTAL)
  }

  searchWineClubProducts = async (search: string) => {
    const {data} = await apolloClient.query({
      query: SEARCH_WINE_CLUB_PRODUCTS,
      variables: {search, orderType: orderTypes.WINE_CLUB},
    })
    return mapToSearchProductsModel(data.products.nodes, orderTypes.WINE_CLUB)
  }

  searchCustomers = async (search: string) => {
    const {data} = await apolloClient.query({
      query: SEARCH_CUSTOMER,
      variables: {search},
    })
    return data.searchCustomersInOrder.nodes
  }
}

const searchProductsService = new SearchProductsService()
export default searchProductsService

export const mapToSearchProductsModel = (products: any[], orderType: string) => {
  return products.map((product) => {
    const {variants} = product
    const nodes = mapToVariantModel(variants.nodes, orderType)

    return {...product, variants: {nodes}}
  })
}

//  code defines a function mapToVariantModel that takes an array of variants as input.
//  It filters the variants based on certain criteria using filterVariantItems,
//  sets specific properties to a large number 9007199254740991 if isAllowNegativeInventory function returns true for a variant,
//  and then returns a new array of modified variants

const mapToVariantModel = (variants: any[], orderType: string) => {
  return filterVariantItems(variants, orderType).map((variant: any) => {
    if (isAllowNegativeInventory(variant)) {
      const maxNumber = 9007199254740991
      variant.available = maxNumber
      variant.shipAvailableForAdminPortal = maxNumber
      variant.pickupAvailableForAdminPortal = maxNumber
    } else if (parseInt(variant.available) === 0) {
      variant.available =
        parseInt(variant.shipAvailableForAdminPortal || 0) +
        parseInt(variant.pickupAvailableForAdminPortal || 0)
    }
    return {...variant}
  })
}

const isAllowNegativeInventory = (variant: any) => {
  if (variant.inventoryItem && variant.inventoryItem.canAllowNegativeInventory) {
    return true
  }
  return false
}

const isAvailableVariant = (variant: any, orderType: string): boolean => {
  switch (orderType) {
    case orderTypes.ADMIN_PORTAL:
      return (
        parseFloat(variant.shipAvailableForAdminPortal) > 0 ||
        parseFloat(variant.pickupAvailableForAdminPortal) > 0
      )
    case orderTypes.WINE_CLUB:
      return (
        parseFloat(variant.shipAvailableForWineClub) > 0 ||
        parseFloat(variant.pickupAvailableForWineClub) > 0
      )
    default:
      return parseFloat(variant.available) > 0
  }
}

const filterVariantItems = (variants: any[], orderType: string) => {
  return variants.filter(
    (variant) => isAvailableVariant(variant, orderType) || isAllowNegativeInventory(variant)
  )
}
