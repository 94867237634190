import {eventNames} from 'app/constant'
import Service from '../Service'

class EventPublishService extends Service {
  constructor() {
    super('/v1/events')
  }

  async publish(event: {name: string; message: any}) {
    return await this.post(event, '/publish')
  }

  async createPackageSlipByBatchId(message: any) {
    const event = {
      name: eventNames.packageSlipCreate,
      message,
    }
    return this.publish(event)
  }

  async retryWineClubOrder(message: any) {
    const event = {
      name: eventNames.retryWineClubOrder,
      message,
    }
    return this.publish(event)
  }

  async retryBulkFulfillment(message: any) {
    const event = {
      name: eventNames.fulfillmentRetry,
      message,
    }
    return this.publish(event)
  }

  async invoicePayment(message: any) {
    const event = {
      name: eventNames.invoicePayment,
      message,
    }
    return await this.publish(event)
  }

  async createExternalOrder(message: any) {
    const event = {
      name: eventNames.externalOrderCreate,
      message,
    }
    return await this.publish(event)
  }
}

const eventPublishService = new EventPublishService()

export default eventPublishService
