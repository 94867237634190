import {toAbsoluteUrl} from '_metronic/helpers'
import {FC} from 'react'

const ErrorLinkUsed: FC = () => {
  return (
    <>
      <div>
        <div className='row'>
          <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo.svg')} className='h-60px mb-5' />
        </div>
        <h1 className='row fw-bolder fs-4x text-gray-700 mb-10'>
          Account creation link was already used.
        </h1>
      </div>
    </>
  )
}

export {ErrorLinkUsed}
