import { gql } from '@apollo/client'

export const GET_SHIPPING_CARRIERS = gql`
  query GetShippingCarriers {
    carrierServiceProviders {
      nodes {
        id
        carrierType
        name
      }
    }
  }
`

export const GET_PRODUCT_TYPES = gql`
  query GetProductTypes {
    productTypes(orderBy: DISPLAY_ORDER_ASC, filter: {name: {notEqualTo: "Bundle"}}) {
      nodes {
        id
        name
      }
    }
  }
`
export const GET_PRODUCT_TYPE = gql`
  query GetProductType($id:UUID!) {
    productType(id: $id) {
        name
    }
  }
`

export const GET_COUNTRIES = gql`
  query GetCountries {
    countries {
      nodes {
        id
        name
      }
    }
  }
`

export const GET_COUNTRY_NAME = gql`
  query GetCountry($id: UUID!) {
    country(id: $id) {
      name
      id
    }
  }
`;