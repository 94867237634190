import {FC} from 'react'
import {useFormikContext} from 'formik'
import Select from 'react-select'
import get from 'lodash/get'
import makeAnimated from 'react-select/animated'

const animatedComponents = makeAnimated()

type Props = {
  id: string
  name: string
  className?: string
  options: any[]
  isMulti?: boolean
  valueField: string
  labelField: string
  onChange?: (value: any) => void
}

const Select2: FC<Props> = ({options, valueField, labelField, name, isMulti, onChange}) => {
  const {getFieldMeta, values, setFieldValue, setFieldTouched} = useFormikContext()
  const {error, touched} = getFieldMeta(name)
  const selectedOptions = get(values, name)

  const handleChange = (selectedOption: any) => {
    setFieldValue(name, selectedOption)
    setFieldTouched(name, true, true)
    if (onChange) {
      onChange(selectedOption)
    }
  }

  const _options = options.map((option) => ({
    value: option[valueField],
    label: option[labelField],
  }))

  return (
    <>
      <Select
        name={name}
        defaultValue={selectedOptions}
        components={animatedComponents}
        isMulti={isMulti}
        options={_options}
        onChange={handleChange}
        onBlur={() => setFieldTouched(name, true, true)}
      />

      {touched && error && (
        <div className='fv-plugins-message-container invalid-feedback'>
          <i className='bi bi-exclamation-circle-fill text-danger me-2'></i>
          <span role='alert'>{error}</span>
        </div>
      )}
    </>
  )
}

export default Select2
