import { FC } from 'react'
import { useFormikContext } from 'formik'
import clsx from 'clsx'

type Props = {
  id?: string
  name: string
  placeholder?: string
  type?: string
  className?: string
  handleChange?: any
  maxLength?: any
  autoComplete?: string
  isInputGroup?: boolean
  prefixInputGroup?: any
  suffixInputGroup?: any
  validate?: (e: any) => boolean
  autoFocus?: boolean
  onChange?: any
  disabled?: boolean
}

const TextFiled: FC<Props> = ({
  className,
  handleChange,
  name,
  validate,
  prefixInputGroup,
  suffixInputGroup,
  isInputGroup,
  ...props
}) => {
  const { getFieldMeta, getFieldProps } = useFormikContext()
  const { touched, error } = getFieldMeta(name)
  const { onChange, ...rest } = getFieldProps(name)
  const id = props.id || `txt${name}`

  const handleValueChange = (e: any) => {
    if (validate) {
      if (validate(e)) {
        onChange(e)
        if (handleChange) {
          handleChange(e)
        }
      }
    } else {
      onChange(e)
      if (handleChange) {
        handleChange(e)
      }
    }
  }

  if (isInputGroup) {
    return (
      <div className={(prefixInputGroup || suffixInputGroup) && 'input-group'}>
        {prefixInputGroup && (
          <div className='input-group-prepend'>
            <span
              className={clsx(
                'input-group-text text-gray-500 form-control rounded-end-0 ',
                { 'is-invalid': touched && error },
                {
                  'is-valid': touched && !error,
                }
              )}
            >
              {prefixInputGroup}
            </span>
          </div>
        )}
        <input
          onChange={handleValueChange}
          className={clsx(
            'form-control',
            className,
            { 'border-left-0': prefixInputGroup },
            { 'border-right-0': suffixInputGroup },
            { 'is-invalid': touched && error },
            {
              'is-valid': touched && !error,
            }
          )}
          autoComplete='off'
          {...props}
          {...rest}
          id={id}
        ></input>
        {suffixInputGroup && (
          <div className='input-group-append'>
            <span
              className={clsx(
                'input-group-text text-gray-500 form-control rounded-start-0 ',
                { 'is-invalid': touched && error },
                { 'is-valid': touched && !error }
              )}
            >
              {suffixInputGroup}
            </span>
          </div>
        )}
        {touched && error && (
          <div className='fv-plugins-message-container invalid-feedback'>
            <i className='bi bi-exclamation-circle-fill text-danger me-2'></i>
            <span role='alert'>{error}</span>
          </div>
        )}
       
      </div>
    )
  }
  return (
    <div>
      <input
        onChange={handleValueChange}
        className={clsx(
          'form-control',
          className,
          { 'is-invalid': touched && error },
          {
            'is-valid': touched && !error,
          }
        )}
        autoComplete='off'
        {...props}
        {...rest}
        id={id}
      ></input>
      {touched && error && (
        <div className='fv-plugins-message-container invalid-feedback'>
          <i className='bi bi-exclamation-circle-fill text-danger me-2'></i>
          <span role='alert'>{error}</span>
        </div>
      )}
    </div>
  )
}

export default TextFiled
