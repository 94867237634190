import {FC} from 'react'
import StepperNavItem from './StepperNavItem'

type Props = {
  steps: any[]
  children: React.ReactNode
  currentStep: number
}
const VerticalStepper: FC<Props> = ({steps, children, currentStep}) => {
  return (
    <div
      className='stepper stepper-pills stepper-column d-flex flex-column flex-lg-row'
      id='kt_stepper_example_vertical'
    >
      {/* <div className='d-flex flex-row-auto w-100 w-lg-300px ms-5'> */}
      <div className='stepper-nav '>
        {steps.map((step, index) => (
          <StepperNavItem {...step} key={index} currentStep={currentStep} />
        ))}
        {/* </div> */}
      </div>
      {children}
    </div>
  )
}

export default VerticalStepper
