import {KTSVG} from '_metronic/helpers'
import React from 'react'
import {useSelector} from 'react-redux'
import {RootState} from 'setup'

interface Props {}

const supportLink = 'https://share.hsforms.com/1v87y69nBR5GpieJg2zOZZwdis75?email'

export const SupportTicket: React.FC<Props> = (props) => {
  const userEmail = useSelector<RootState, string>(({auth}) => auth.user.email)

  const handleClick = () => {
    window.open(`${supportLink}=${userEmail}`, '_blank')
  }
  return (
    <div className='d-flex align-items-center ms-1 ms-lg-3' id='kt_header_subscription_menu_toggle'>
      <div
        className='cursor-pointer symbol '
        data-kt-menu-trigger='click'
        data-kt-menu-attach='parent'
        data-kt-menu-placement='bottom-end'
        data-kt-menu-flip='bottom'
      >
        <div className='btn btn-active-light-primary d-flex' onClick={handleClick}>
          <KTSVG
            path='/media/icons/duotune/general/gen017.svg'
            className='svg-icon-dark svg-icon-1'
          />
          <span className='text-bolder'>Need help? Submit a ticket!</span>
        </div>
      </div>
    </div>
  )
}

export default SupportTicket
