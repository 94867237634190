import React from 'react'
import SVG from 'react-inlinesvg'
import {toAbsoluteUrl} from '../AssetHelpers'
type Props = {
  className?: string
  path: string
  svgClassName?: string
  onClick?: any
}

const KTSVG: React.FC<Props> = ({className = '', path, svgClassName = 'mh-50px', onClick}) => {
  const setupColor = className.includes('svg-icon-success') ? '#07bc0c' : ''
  return (
    <span className={`svg-icon ${className}`} onClick={onClick}>
      {setupColor && (
        <SVG id={path} src={toAbsoluteUrl(path)} className={svgClassName} fill={setupColor} />
      )}
      {!setupColor && <SVG id={path} src={toAbsoluteUrl(path)} className={svgClassName} />}
    </span>
  )
}

export {KTSVG}
