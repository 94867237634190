import React, {useMemo} from 'react'
import orderBy from 'lodash/orderBy'
import SalesChannelMenu from './SalesChannelMenu'
import {useSelector} from 'react-redux'
import {RootState} from 'setup'
import {AsideMenuItemWithSub} from '../AsideMenuItemWithSub'
import {AsideMenuItem} from '../AsideMenuItem'
import {useLayout} from '_metronic/layout/core'
import {menuPermission} from 'app/constant/permission'

interface Props {
  menus: any[]
}

const SalesChannels: React.FC<Props> = ({menus}) => {
  const {isPosMenuEnable} = useLayout()
  const permissions = useSelector<RootState, any[]>(({auth}) => auth.user?.store?.permissions)

  const salesChannels = useMemo(
    () =>
      orderBy(menus, 'displayOrder').filter(
        (item: any) => item.displayOrder >= 8 && item.displayOrder <= 12
      ),
    [menus]
  )

  const integration = useMemo(
    () =>
      orderBy(menus, 'displayOrder')?.find(
        (item: any) => item.code === menuPermission.integrations
      ),
    [menus]
  )

  if (salesChannels && salesChannels.length === 0) {
    return null
  }

  return (
    <>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Sales Channels</span>
        </div>
      </div>
      <SalesChannelMenu menus={menus} />
      {permissions.includes(menuPermission.integrations) && (
        <AsideMenuItem
          id={`linkIntegrations`}
          to={integration?.url ?? '/integrations/all'}
          title={integration?.name ?? 'Integrations & Connected Apps'}
          icon={integration?.icon ?? '/media/icons/duotune/general/gen025.svg'}
        />
      )}
      {permissions.includes(menuPermission.marketing) && (
        <AsideMenuItemWithSub
          id='linkMarketing'
          to='/marketing/segments'
          icon='/media/icons/corksy/marketing-solid.svg'
          title='Marketing'
        >
          <AsideMenuItem
            id='linkSegmentAll'
            to='/marketing/segments'
            title='Segments'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
      )}
      {permissions.includes(menuPermission.analytics) && (
        <AsideMenuItemWithSub
          id='linkAnalytics'
          to='/analytics'
          icon='/media/icons/corksy/report.svg'
          title='Analytics'
          fontIcon='bi bi-bar-chart-line'
        >
          <AsideMenuItem
            id='linkReports'
            to='/analytics/reports'
            title='Reports'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
      )}
      {permissions.includes(menuPermission.reports) && (
        <AsideMenuItemWithSub
          id='linkReports'
          to='/reports'
          icon='/media/icons/corksy/reports.svg'
          title='Reports'
          fontIcon='bi bi-bar-chart-line'
        >
          {permissions.includes('report_sales.all') && (
            <AsideMenuItem id='linkReports' to='/reports/sales' title='Sales' hasBullet={true} />
          )}
          {permissions.includes('reports_inventory.all') && (
            <AsideMenuItem
              id='linkReports'
              to='/reports/inventory'
              title='Inventory'
              hasBullet={true}
            />
          )}
          {permissions.includes('reports_marketing.all') && (
            <AsideMenuItem
              id='linkReports'
              to='/reports/marketing'
              title='Marketing'
              hasBullet={true}
            />
          )}
          {permissions.includes('reports_wineclub.all') && (
            <AsideMenuItem
              id='linkReports'
              to='/reports/wineclub'
              title='Wine club'
              hasBullet={true}
            />
          )}
          {isPosMenuEnable && permissions.includes('reports_pos.all') && (
            <AsideMenuItem id='linkReportsPos' to='/reports/pos' title='POS' hasBullet={true} />
          )}
        </AsideMenuItemWithSub>
      )}
    </>
  )
}

export default React.memo(SalesChannels)
