import {FC, useEffect, useState} from 'react'
import {useFormikContext} from 'formik'
import {set, range, reverse, split} from 'lodash'
import clsx from 'clsx'

const month = [
  {id: '01', name: 'Jan'},
  {id: '02', name: 'Feb'},
  {id: '03', name: 'Mar'},
  {id: '04', name: 'Apr'},
  {id: '05', name: 'May'},
  {id: '06', name: 'Jun'},
  {id: '07', name: 'Jul'},
  {id: '08', name: 'Aug'},
  {id: '09', name: 'Sep'},
  {id: '10', name: 'Oct'},
  {id: '11', name: 'Nov'},
  {id: '12', name: 'Dec'},
]

const date: any = range(1, 32)
const currentYear = new Date().getFullYear()
const year: any = reverse(range(1905, currentYear + 1))

type Props = {
  id?: string
  name: string
  className?: string
  label?: string
}

const DateOfBirth: FC<Props> = ({name, label, className, ...props}) => {
  const [birthDate, setBirthDate] = useState<any>({})
  const {setFieldValue, getFieldMeta, setFieldTouched} = useFormikContext<any>()
  const {touched, error, value}: any = getFieldMeta(name)

  useEffect(() => {
    if (value) {
      const dateParts = split(value, '-')
      setBirthDate({
        month: dateParts[0],
        date: parseInt(dateParts[1]),
        year: dateParts[2],
      })
    }
  }, [value])

  const handleChange = (e: any) => {
    const {name: _name, value} = e.target
    const newState = {...birthDate}
    set(newState, _name, value)
    setBirthDate(newState)
    if (newState.month && newState.year && newState.date) {
      setFieldTouched(name, true)
      setFieldValue(name, `${newState.month}-${newState.date}-${newState.year}`)
    }
  }

  return (
    <>
      {label && <label className='required fs-6 fw-semibold form-label mb-2'>{label}</label>}
      <div className='row'>
        <div className='col-xl-4 col-md-4 '>
          <select
            className={clsx(
              'form-select',
              className,
              {'is-invalid': touched && error},
              {
                'is-valid': touched && !error,
              }
            )}
            {...props}
            name='month'
            value={birthDate.month}
            onChange={handleChange}
          >
            <option value='' selected disabled hidden>
              Month
            </option>
            {month.map((month) => (
              <option key={month.id} value={month.id}>
                {month.name}
              </option>
            ))}
          </select>
        </div>
        <div className='col-xl-4 col-md-4'>
          <select
            className={clsx(
              'form-select',
              className,
              {'is-invalid': touched && error},
              {
                'is-valid': touched && !error,
              }
            )}
            {...props}
            name='date'
            value={birthDate.date}
            onChange={handleChange}
          >
            <option value='' selected disabled hidden>
              Date
            </option>
            {date.map((date: any) => (
              <option key={date} value={date.value}>
                {date}
              </option>
            ))}
          </select>
        </div>
        <div className='col-xl-4 col-md-4'>
          <select
            className={clsx(
              'form-select',
              className,
              {'is-invalid': touched && error},
              {
                'is-valid': touched && !error,
              }
            )}
            {...props}
            name='year'
            value={birthDate.year}
            onChange={handleChange}
          >
            <option selected disabled hidden>
              Year
            </option>
            {year.map((year: any) => (
              <option key={year} value={year.value}>
                {year}
              </option>
            ))}
          </select>
        </div>
      </div>
      {touched && error && (
        <div className='fv-plugins-message-container invalid-feedback'>
          <i className='bi bi-exclamation-circle-fill text-danger me-2'></i>
          <span role='alert'>{error}</span>
        </div>
      )}
    </>
  )
}
export default DateOfBirth
