import {FC} from 'react'
import {useFormikContext, Field} from 'formik'
import clsx from 'clsx'

type Props = {
  id?: string
  name: string
  className?: string
  label: string
  disabled?: boolean
}

const Checkbox2: FC<Props> = ({id, className, name, label, ...props}) => {
  const {getFieldMeta} = useFormikContext()
  const {touched, error} = getFieldMeta(name)

  return (
    <div>
      <label className='form-check form-check-inline form-check-solid me-5'>
        <Field
          type='checkbox'
          name={name}
          className={clsx('form-check-input', className)}
          id={id}
          {...props}
        />
        <span className='fw-bold ps-2 fs-6'>{label}</span>
      </label>

      {touched && error && (
        <div className='fv-plugins-message-container invalid-feedback'>
          <i className='bi bi-exclamation-circle-fill text-danger me-2'></i>
          <span role='alert'>{error}</span>
        </div>
      )}
    </div>
  )
}

export default Checkbox2
