import {FC, useEffect, useRef} from 'react'
import sessionStorage from 'redux-persist/es/storage/session'

interface Props {
  onFilterChange: (value: any) => void | any
}

const strName = 'current_state'

const PagingWithFilterAction: FC<Props> = ({onFilterChange}) => {
  const didRequest = useRef<boolean>(true)
  useEffect(() => {
    const getItem = async () => {
      const item = await sessionStorage.getItem(strName)
      if (item && didRequest.current) {
        try {
          const state = JSON.parse(item)
          if (state && state.filter && state.route === window.location.pathname) {
            await onFilterChange({...state.filter, canLoad: true})
            sessionStorage.removeItem(strName)
          } else {
            onFilterChange({canLoad: true})
            sessionStorage.removeItem(strName)
          }
          didRequest.current = false
        } catch (error) {
          console.error('Error parsing JSON from sessionStorage:', error)
        }
      } else if (didRequest.current) {
        onFilterChange({canLoad: true})
        didRequest.current = false
      }
    }
    getItem()
  }, [onFilterChange])

  return <></>
}

export default PagingWithFilterAction

export const setCurrentPageOne = (state: any) => {
  if (state.filter.offset === 0) {
    state.filter.currentPage = 1
  }
  return state
}
