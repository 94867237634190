import {FC} from 'react'
import {useFormikContext} from 'formik'
import MaskedNumberField from './MaskedNumberField'

type Props = {
  id: string
  name: string
  placeholder?: string
  className?: string
  disabled?: boolean
}

const PhoneFiled: FC<Props> = (props) => {
  const {getFieldMeta} = useFormikContext()
  const field: any = getFieldMeta(props.name)

  let value = field.value
  if (value) {
    value = value.replace(/[()-]/g, '')
    value = value.replace(/\s/g, '')
    value = value.replace(/\+/g, '')
  }

  if (value?.length > 10) {
    return <MaskedNumberField {...props} format='+# (###) ###-####' mask='_' />
  }
  return <MaskedNumberField {...props} format='(###) ###-####' mask='_' />
}

export default PhoneFiled
