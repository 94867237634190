import {FC} from 'react'
import {Button} from '_core'
import {RootState} from 'setup'
import {useSelector} from 'react-redux'

type Props = {
  onPay: () => void
  isSubmitting: boolean
  message: string
}
const PaymentProcessButton: FC<Props> = ({isSubmitting, message, onPay}) => {
  const storePaymentsList = useSelector<RootState, Array<any>>(
    ({misc}) => misc.storePlan.storePaymentsList
  )

  return (
    <>
      {isSubmitting && (
        <div className='position-absolute d-flex justify-content-center z-index-1 page-loader bg-transparent'>
          <label className='d-flex flex-column align-items-center'>
            <span className='indicator-progress d-block'>
              <span className='spinner-border align-middle text-info'></span>
            </span>
            {message}
          </label>
        </div>
      )}
      <Button
        id='btnPay'
        title='Pay Invoice Payment'
        isSubmitting={isSubmitting}
        onClick={onPay}
        className='btn btn-primary btn-sm'
        disabled={storePaymentsList?.length === 0}
      />
    </>
  )
}
export default PaymentProcessButton
