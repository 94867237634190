import {FC, createContext, useContext, useRef, useState, useEffect} from 'react'
import {gql, useLazyQuery} from '@apollo/client'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from 'setup'
import {SubscriptionAlert} from './components'
import {premiumFeaturesType, subscriptionStatus} from 'app/constant'
import {useLayout} from '_metronic/layout/core'

export interface SubscriptionContextModel {
  isLocked: boolean
  premiumFeatures: any[]
  setIsLocked: (value: any | undefined) => any
  setPremiumFeatures: (value: any[] | undefined) => any
  isPOSFeatureEnabled: boolean
  setIsPOSFeatureEnabled: (value: any | undefined) => any
  shippingComplianceProvider: string | null
  setShippingComplianceProvider: (value: any | undefined) => any
  isCrv: boolean
  setIsCrv: (value: any | undefined) => any
  connectedApps: any[]
  setConnectedApps: (value: any | undefined) => any
}

const Subscription = createContext<SubscriptionContextModel>({
  isLocked: false,
  premiumFeatures: [],
  setIsLocked: () => {},
  setIsPOSFeatureEnabled: () => {},
  setPremiumFeatures: () => [],
  isPOSFeatureEnabled: false,
  shippingComplianceProvider: null,
  setShippingComplianceProvider: () => {},
  isCrv: false,
  setIsCrv: () => {},
  connectedApps: [],
  setConnectedApps: () => {},
})

const useSubscription = () => {
  return useContext(Subscription)
}

const GET_SUBSCRIPTION_STATUS = gql`
  query getUserSubscriptionStatus {
    shop {
      storePlan {
        status
      }
    }
    storePremiumFeatures {
      nodes
    }
    shippingCompliance: shippingComplianceServiceAccounts(first: 1) {
      nodes {
        serviceProvider {
          providerType
        }
        isCrvEnabled
      }
    }
    appIntegrationSettings(filter: {deletedAt: {isNull: true}}) {
      nodes {
        id
        appType
      }
    }
  }
`

const SubscriptionProvider: FC<any> = ({redirectUrl, children}) => {
  const [isLocked, _setIsLocked] = useState(false)
  const [premiumFeatures, _setPremiumFeatures] = useState([])
  const [isPOSFeatureEnabled, _setIsPOSFeatureEnabled] = useState(false)
  const [shippingComplianceProvider, setShippingCompliance] = useState(null)
  const [connectedApps, _setConnectedApps] = useState<Array<any>>([])
  const [isCrv, setCrv] = useState(false)

  const setIsLocked = (value: boolean) => {
    _setIsLocked(value)
  }

  const setIsPOSFeatureEnabled = (value: boolean) => {
    _setIsPOSFeatureEnabled(value)
  }

  const setPremiumFeatures = (value: any) => {
    _setPremiumFeatures(value)
  }

  const setShippingComplianceProvider = (value: any) => {
    setShippingCompliance(value)
  }

  const setIsCrv = (value: any) => {
    setCrv(value)
  }

  const setConnectedApps = (apps: any[]) => {
    _setConnectedApps(apps)
  }

  const value: SubscriptionContextModel = {
    isLocked,
    premiumFeatures,
    setIsLocked,
    setPremiumFeatures,
    isPOSFeatureEnabled,
    setIsPOSFeatureEnabled,
    shippingComplianceProvider,
    setShippingComplianceProvider,
    isCrv,
    setIsCrv,
    connectedApps,
    setConnectedApps,
  }

  return (
    <Subscription.Provider value={value}>
      <SubscriptionContext redirectUrl={redirectUrl}>{children}</SubscriptionContext>
    </Subscription.Provider>
  )
}

const SubscriptionContext: FC<any> = ({children}) => {
  const {
    isLocked,
    setIsLocked,
    setPremiumFeatures,
    setIsPOSFeatureEnabled,
    setShippingComplianceProvider,
    setIsCrv,
    setConnectedApps,
  } = useSubscription()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(false)
  const [getSubscription] = useLazyQuery(GET_SUBSCRIPTION_STATUS)
  const isAuthorized = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
  const {setIsPosMenuEnable} = useLayout()

  useEffect(() => {
    const checkUserLocked = async () => {
      try {
        setShowSplashScreen(true)
        const data = (await getSubscription()).data
        if (data) {
          const {isLocked, isPosEnable, features, provider, isCrv, connectedApps} = mapToModel(data)
          setIsPOSFeatureEnabled(isPosEnable)
          setIsPosMenuEnable(isPosEnable)
          setIsLocked(isLocked)
          setPremiumFeatures(features)
          setShippingComplianceProvider(provider)
          setIsCrv(isCrv)
          setConnectedApps(connectedApps)
          didRequest.current = true
        }
      } catch (err) {
        console.error(err)
      } finally {
        setShowSplashScreen(false)
      }
    }
    if (!didRequest.current && isAuthorized) {
      checkUserLocked()
    }
    // eslint-disable-next-line
  }, [])

  if (showSplashScreen) {
    return (
      <div className='w-100 vh-75 d-flex justify-content-center align-items-center'>
        <label>
          <span className='indicator-progress d-block'>
            <span className='spinner-border align-middle ms-5 text-info'></span>
          </span>
          Loading...
        </label>
      </div>
    )
  }

  if (isLocked) {
    return <SubscriptionAlert />
  }

  return children
}
export {useSubscription, SubscriptionContext, SubscriptionProvider}

const mapToModel = ({
  shop,
  storePremiumFeatures,
  shippingCompliance,
  appIntegrationSettings,
}: any) => {
  let isLocked = false,
    isPosEnable = false,
    features = [],
    provider = null,
    isCrv = false,
    connectedApps = []
  if (shop) {
    const {status} = shop.storePlan
    if (status === subscriptionStatus.locked || status === subscriptionStatus.suspended) {
      isLocked = true
    }
  }

  if (shippingCompliance && shippingCompliance.nodes && shippingCompliance.nodes.length) {
    const {serviceProvider} = shippingCompliance.nodes[0]
    if (shippingCompliance.nodes[0].isCrvEnabled) {
      isCrv = shippingCompliance.nodes[0].isCrvEnabled
    }
    provider = serviceProvider.providerType
  }

  if (storePremiumFeatures && storePremiumFeatures.nodes) {
    const values: any[] = storePremiumFeatures.nodes
    if (values.includes(premiumFeaturesType.POS)) {
      isPosEnable = true
    }
    features = values
  }

  if (
    appIntegrationSettings &&
    appIntegrationSettings.nodes &&
    appIntegrationSettings.nodes.length
  ) {
    connectedApps = appIntegrationSettings.nodes
  }

  return {isLocked, isPosEnable, features, provider, isCrv, connectedApps}
}
