import * as yup from 'yup'

const phoneReg = RegExp(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)
var alphaNumeric = /^[A-Za-z0-9]+$/

yup.addMethod(yup.string, 'phone', function (errorMessage) {
  return this.test(`test-phone`, errorMessage, function (value: any) {
    const {path, createError} = this

    return phoneReg.test(value) || createError({path, message: errorMessage})
  })
})

yup.addMethod(yup.string, 'alphaNumeric', function (errorMessage) {
  return this.test(`test-alpha-numeric`, errorMessage, function (value: any) {
    const {path, createError} = this

    return alphaNumeric.test(value) || createError({path, message: errorMessage})
  })
})
