import {gql} from '@apollo/client'

export const GET_CUSTOMER_WINE_CLUB = gql`
  query getCustomerWineClubs($customerId: UUID!, $orderBy: String, $offset: Int!, $pageSize: Int!) {
    searchSubscriptions(
      offset: $offset
      orderBy: $orderBy
      first: $pageSize
      filter: {wineClubId: {isNull: false}, customerId: {equalTo: $customerId}}
    ) {
      nodes {
        id
        createdAt
        customer {
          firstName
          lastName
        }
        subscriptionOrderSchedules {
          nodes {
            lastOrderDate
            nextOrderDate
          }
        }
        wineClub {
          name
          frequency
        }
      }
      totalCount
    }
  }
`
