import {FC} from 'react'
import {FormikProvider, useFormik} from 'formik'
import {MultilineTextField, Select, TextField} from '_core/forms'
import Button from '_core/components/Button'
import {useDispatch} from 'react-redux'
import {showMessage} from 'app/redux/messageSlice'
import {Modal} from 'react-bootstrap-v5'
import {KTSVG} from '_metronic/helpers'
import {CustomerTagSchema, defaultValues, tags} from './helper'
import {getCustomerTags} from 'app/features/customers/redux/customerSlice'
import customerTagService from 'app/services/CustomerService/CustomerTagService'

type Props = {
  show?: any
  onClose?: any
}

const CustomerTag: FC<Props> = ({show, onClose}) => {
  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema: CustomerTagSchema,
    onSubmit: handleSubmit,
  })

  async function handleSubmit(values: any) {
    try {
      formik.setSubmitting(true)
      await customerTagService.createCustomerTag(values)
      dispatch(
        showMessage({
          text: 'Customer tag saved successfully',
          variant: 'success',
        })
      )
      dispatch(getCustomerTags())
      onClose()
    } catch {
      dispatch(showMessage({text: 'An error occurred while saving changes', variant: 'error'}))
    } finally {
      formik.setSubmitting(false)
    }
  }
  return (
    <Modal
      size='lg'
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='h-auto'
      show={show}
      centered
    >
      <div className='modal-header d-flex'>
        <h5 className='modal-title'>Customer Tag</h5>
        <div className='btn btn-icon btn-sm btn-light-secondary' onClick={onClose}>
          <KTSVG
            className='svg-icon-3 border border-1 border-primary svg-icon-primary rounded-1'
            path='/media/icons/duotune/arrows/arr061.svg'
          />
        </div>
      </div>
      <div className='container my-7'>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <div className='mb-10'>
              <label className='form-label fs-6 fw-bold text-dark required'>Title</label>
              <TextField id='txtTagName' name='name' maxLength={200} />
            </div>
            <div className='mb-10'>
              <div>
                <label className='form-label fs-6 fw-bold text-dark'>Tag Type</label>
                <Select id='sltTagType' name='tagType'>
                  {tags.map((tag: any) => {
                    return (
                      <option value={tag.id} key={tag.id}>
                        {tag.name}
                      </option>
                    )
                  })}
                </Select>
              </div>
            </div>
            <div className='mb-10'>
              <label className='form-label fs-6 fw-bold text-dark'>Description</label>
              <MultilineTextField
                id='txtCollectionDescription'
                name='description'
                rows={5}
                maxLength={300}
              />
            </div>
            <div className='text-end'>
              <Button
                id='btnCustomerTagSave'
                type='submit'
                title='Save'
                isSubmitting={formik.isSubmitting}
                isValid={formik.isValid}
                className='btn-primary'
              />
            </div>
          </form>
        </FormikProvider>
      </div>
    </Modal>
  )
}
export default CustomerTag
