import {FC} from 'react'
import NumberField from './NumberField'

type Props = {
  id?: string
  name: string
  placeholder?: string
  className?: string
  min?: number
  max?: number
}

const PercentageField: FC<Props> = (props) => {
  const validate = (input: any) => {
    const {value} = input
    if (value <= 100) {
      return true
    }
    return false
  }
  return <NumberField {...props} suffix={'%'} allowNegative={false} isAllowed={validate} />
}

export default PercentageField
