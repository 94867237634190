import apolloClient from 'apollo-client'
import {
  GET_STORE_SETUP,
  GET_STORE_TOTAL_SALES_ORDERS_AND_CUSTOMERS,
  UPDATE_STORE_SETUP,
} from './graphql'

class StoreSetupService {
  async getStoreSetup() {
    const {data} = await apolloClient.query({
      query: GET_STORE_SETUP,
    })
    const {shop} = data
    var res = {
      setup: shop.storeSetups.nodes[0],
    }
    return res
  }

  async updateStoreSetup(storeSetup: any) {
    return await apolloClient.mutate({
      mutation: UPDATE_STORE_SETUP,
      variables: storeSetup,
    })
  }

  async getTotalOrderAndSales(filter: any) {
    const {data} = await apolloClient.query({
      query: GET_STORE_TOTAL_SALES_ORDERS_AND_CUSTOMERS,
      variables: filter,
    })
    const {
      totalOrders,
      totalSales,
      totalCustomers,
      prevMonthOrders,
      prevMonthSales,
      prevMonthCustomers,
    } = data
    let orderPercentage: number | any = 0
    let salesPercentage: number | any = 0
    let customersPercentage: number | any = 0
    if (totalOrders > 0) {
      orderPercentage = ((totalOrders - prevMonthOrders) / totalOrders) * 100
    }
    if (totalSales > 0) {
      salesPercentage = ((totalSales - prevMonthSales) / totalSales) * 100
    }
    if (totalCustomers > 0) {
      customersPercentage = ((totalCustomers - prevMonthCustomers) / totalCustomers) * 100
    }
    return {
      totalOrders,
      totalSales,
      totalCustomers,
      orderPercentage: parseFloat(orderPercentage).toFixed(2),
      salesPercentage: parseFloat(salesPercentage).toFixed(2),
      customersPercentage: parseFloat(customersPercentage).toFixed(2),
    }
  }
}

const storeSetupService = new StoreSetupService()
export default storeSetupService
