import {gql} from '@apollo/client'

export const CREATE_STORE_PAYMENT = gql`
  mutation CreateStorePaymentMethod($storePaymentMethod: StoreCardPaymentMethodInput!) {
    createStorePaymentMethod(input: $storePaymentMethod) {
      paymentMethodId
      errors
      status
    }
  }
`
export const GET_STORE_PAYMENTS = gql`
  query GetAccounts($pageSize: Int!, $offset: Int!) {
    storePaymentMethods(first: $pageSize, offset: $offset, filter: {deletedAt: {isNull: true}}) {
      nodes {
        id
        isDefault
        card {
          expYear
          expMonth
          last4
          brand
        }
        addressId
      }
      totalCount
    }
  }
`

export const SAVE_STORE_PLAN = gql`
  mutation saveStorePlan($billingAccount: SaveStorePlanInput!) {
    saveStorePlan(input: $billingAccount) {
      clientMutationId
    }
  }
`
export const GET_BILLING_ADDRESSES = gql`
  query MyQuery($pageSize: Int!, $offset: Int!) {
    billingAddresses(first: $pageSize, offset: $offset) {
      totalCount
      nodes {
        addressLine2
        addressLine1
        firstName
        lastName
        id
        stateId
        zipCode
        countryId
      }
    }
  }
`

export const UPDATE_STORE_PLAN = gql`
  mutation UpdateStorePlan($id: UUID!, $patch: StorePlanPatch!) {
    updateStorePlan(input: {patch: $patch, id: $id}) {
      clientMutationId
    }
  }
`
export const DELETE_STORE_PAYMENT = gql`
  mutation DeleteStorePayment($id: UUID!) {
    deleteStorePaymentMethod(input: {id: $id}) {
      clientMutationId
    }
  }
`
export const UPDATE_STORE_PAYMENT_METHOD_DEFAULT = gql`
  mutation UpdateStorePaymentMethodDefault($id: UUID!) {
    updateStorePaymentMethodDefault(input: {paymentMethodId: $id}) {
      clientMutationId
    }
  }
`
