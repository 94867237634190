import {KTSVG} from '_metronic/helpers'
import Pagination, {PagingWithFilterAction} from 'app/components/pagination'
import {FC, useCallback} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import clsx from 'clsx'
import PageSizeFilter from 'app/components/pagination/PageSizeFilter'
import {RecordProps} from 'app/components/pagination/PaginationList'

type NavigationProps = {
  linkPath: string
  iconPath?: string
  isHide?: boolean
  isResetState?: boolean
}

type PageSizeChangeProps = {
  pageSize?: number
  onPageSizeChange: (value: any) => void
}

interface PagingProps {
  totalCount: number
  onPageChange: (value: any) => any
  currentPage: number
  pageSize: number
  className?: string
  isDisableResetPaging?: boolean
  className2?: string
  records?: RecordProps
  onPageFilterChange?: (filter: any) => void
}

type Props = {
  className?: string
  title?: string | any
  search?: any
  description?: any
  subTitle?: string
  children: any
  footer?: any
  showBorder?: boolean
  toolbar?: any
  filter?: any
  separator?: boolean
  navigation?: NavigationProps
  paging?: PagingProps
  pageSizeChange?: PageSizeChangeProps
}

const TableCard: FC<Props> = ({
  className,
  title,
  subTitle,
  description,
  children,
  footer,
  showBorder,
  toolbar,
  search,
  filter,
  navigation,
  separator,
  paging,
  pageSizeChange,
}) => {
  const border = showBorder ? 'border-1' : 'border-0'

  const history = useHistory()
  const {state} = useLocation<any>()

  const handleNavigate = () => {
    if (navigation?.linkPath) {
      let values: any = state
      if (navigation?.isResetState) {
        values = null
      }
      history.push(navigation.linkPath, values)
    }
  }

  return (
    <div className={`card rounded shadow-none bg-light ${className}`}>
      <div className={`card-header ${border} pt-5 p-0`}>
        <div className='d-flex align-items-center position-relative my-1'>
          {title && (
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label mb-1 d-flex align-items-center'>
                {navigation && !navigation.isHide && (
                  <KTSVG
                    path={
                      navigation.iconPath
                        ? navigation.iconPath
                        : '/media/icons/corksy/arrow-left.svg'
                    }
                    className='svg-icon-2hx svg-icon-dark me-3 cursor-pointer'
                    onClick={handleNavigate}
                  />
                )}
                <span className='fs-1'>{title}</span>
              </span>
              <div className='text-dark mt-3 fw-bold fs-7'>{description}</div>
              <span className='text-muted mt-1 fw-bold fs-7'>{subTitle}</span>
            </h3>
          )}

          {search && <>{search}</>}
        </div>

        {toolbar && <div className='card-toolbar pb-3'>{toolbar}</div>}
      </div>
      <div className='px-10 pt-5 bg-white'>
        {filter}
        {pageSizeChange && (
          <div className='pt-3 bg-white mb-4'>
            <PageSizeFilter {...pageSizeChange} />
          </div>
        )}
      </div>

      {separator && <div className='separator mb-3'></div>}
      <div className='bg-white'>
        {children}
        {paging && paging.totalCount > 1 && (
          <div className={clsx('py-2 mb-3', paging.className)}>
            <Pagination {...paging} className={paging.className2 ? paging.className2 : 'mx-5'} />
            {paging.onPageFilterChange && (
              <PagingWithFilterAction onFilterChange={paging.onPageFilterChange} />
            )}
          </div>
        )}
      </div>

      {footer && <div className='card-footer'>{footer}</div>}
    </div>
  )
}

export default TableCard
