import {addPaymentStatus} from 'app/constant/index'
import paymentService from 'app/services/PaymentService/PaymentService'

export const setupIntentIf3DRequired = async (
  stripePaymentMethod: any,
  customerId: string,
  stripe: any
) => {
  const {
    id,
    card: {three_d_secure_usage},
  } = stripePaymentMethod

  if (three_d_secure_usage.supported) {
    const out = await setupIntent(stripe, customerId, id)
    if (out.setupIntentId) {
      stripePaymentMethod.setupIntentId = out.setupIntentId
    }
    return out
  }
}
export const setupIntent = async (stripe: any, customerId: string, paymentMethodId: any) => {
  const out: any = await paymentService.setupIntentByCustomer(customerId)

  const {error, setupIntent} = await stripe.confirmCardSetup(out.clientSecretKey, {
    payment_method: paymentMethodId,
  })

  if (error) {
    return {
      error: error.message,
    }
  } else if (
    setupIntent &&
    setupIntent.status.toLowerCase() === addPaymentStatus.succeeded.toLowerCase()
  ) {
    return {
      setupIntentId: setupIntent.id,
    }
  } else if (setupIntent && setupIntent.last_setup_error) {
    return {
      error: setupIntent.last_setup_error.message,
    }
  }

  return {
    error: 'Something went wrong',
  }
}
