import {FC} from 'react'
import {useHistory} from 'react-router-dom'

type Props = {
  id?: string
  title?: string
  message?: string
  createRoute?: string
  buttonText?: string
  to?: string
  createButton?: any
}

const NoRecords: FC<Props> = ({message, buttonText, createButton, createRoute}) => {
  const history = useHistory()

  const handleClick = () => {
    if (createRoute) {
      history.push(createRoute)
    }
  }

  return (
    <div className='d-flex justify-content-center align-self-center h-100'>
      <div className='col-md-6 col-xxl-4 align-self-center'>
        <div className='card card-xxl-stretch rounded shadow-sm mb-5 mb-xxl-8'>
          <div className='card-body d-flex flex-center flex-column p-9'>
            <div className='fw-bold text-gray-400 mb-6'>{message}</div>
            <div className='d-flex flex-center flex-wrap mb-5 py-50'></div>
            {createRoute && (
              <button id='btnCreateRoute' className='btn btn-sm btn-primary' onClick={handleClick}>
                {buttonText ? buttonText : 'Create'}
              </button>
            )}
            {createButton}
          </div>
        </div>
      </div>
    </div>
  )
}

export default NoRecords
