export const paymentLinkedMessage =
  'Cannot edit because the address is mapped to an existing payment method on file.'

export const paymentLinkedMessageDelete =
  'Cannot delete because the address is mapped to an existing payment method on file'

export const countryName = {
  CA: 'Canada',
  US: 'United States',
}
