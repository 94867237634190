import {FC, useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {showLoader, hideLoader} from 'app/redux/loaderSlice'
import {showMessage} from 'app/redux/messageSlice'
import PageOptions from '../pages/PageOptions'

const SimplePage: FC<PageOptions> = ({children, loading, error}) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (loading) {
      dispatch(showLoader())
    } else {
      dispatch(hideLoader())
    }
  }, [dispatch, loading])

  useEffect(() => {
    if (error) {
      dispatch(showMessage({text: error.message, variant: 'error'}))
    }
  }, [dispatch, error])

  return <> {children}</>
}

export default SimplePage
