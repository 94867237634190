import {gql} from '@apollo/client'
import {find} from 'lodash'

export const GET_COUNTRY_STATES = gql`
  query GetCountryAndStates {
    countries {
      nodes {
        id
        name
        code
      }
    }
    states(orderBy: NAME_ASC) {
      nodes {
        id
        name
        code
        countryId
      }
    }
  }
`

export const findCountry = (data: any, code: any) => {
  return find(data.countries.nodes, {code})
}

export const findState = (data: any, code: any) => {
  return find(data.states.nodes, {code})
}

export interface InputFormat {
  readonly value: string
  readonly label: string
}

export const components = {
  DropdownIndicator: null,
  IndicatorSeparator: null,
}
