import {combineReducers} from '@reduxjs/toolkit'
import message from './messageSlice'
import loader from './loaderSlice'
import dialog from './dialogSlice'
import storePlan from './StorePlanSlice'
import storeSetup from './StoreSetupSlice'
import paging from './pagingSlice'
import order from 'app/features/orders/redux/order/ordersSlice'

const miscReducers = combineReducers({
  message,
  loader,
  dialog,
  storePlan,
  storeSetup,
  paging,
  order,
})

export default miscReducers
