import {FC, useMemo} from 'react'
import MaskedNumberField from './MaskedNumberField'
import {gql, useQuery} from '@apollo/client'
import InputMask from './InputMask'
import {countryName} from 'app/constant/address'
import find from 'lodash/find'

type Props = {
  id: string
  name: string
  placeholder?: string
  className?: string
  disabled?: boolean
  countryId?: string
}
const GET_COUNTRIES = gql`
  query GetCountries {
    countries {
      nodes {
        id
        name
      }
    }
  }
`
const ZipCodeField: FC<Props> = (props) => {
  const {data} = useQuery(GET_COUNTRIES)

  return useMemo(() => {
    const getCountryNameById = (countryId: any) => {
      if (data?.countries?.nodes) {
        const country = find(data?.countries?.nodes, ({id}) => id === countryId)
        if (country) {
          return country.name
        }
      }
      return ''
    }

    if (props.countryId && getCountryNameById(props.countryId) === countryName.CA) {
      return (
        <>
          <label className='form-label fs-6 text-dark required'>Postal Code</label>
          <InputMask {...props} />
        </>
      )
    }
    return (
      <>
        <label className='form-label fs-6 text-dark required'>ZIP Code</label>
        <MaskedNumberField {...props} maxLength={5} allowNegative={false} decimalScale={0} />
      </>
    )
  }, [data, props])
}

export default ZipCodeField
