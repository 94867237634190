import {FC, useEffect, useState} from 'react'
import {FormikProvider} from 'formik'
import {Button, ErrorMessage} from '_core'
import {useFormik} from 'formik'
import {showMessage} from 'app/redux/messageSlice'
import {useDispatch, useSelector} from 'react-redux'
import storePlanService from 'app/services/StorePlanService'
import {Modal} from 'react-bootstrap-v5'
import StorePaymentModel, {
  storePaymentInitialState,
  PaymentDetailsSchema,
} from '../../models/StorePaymentModel'
import * as models from '../../models/StorePaymentModel'
import AddressDetails from './AddressDetails'
import {
  getStoreAddresses,
  getStorePayments,
  selectStoreAddressList,
  selectIsFetching,
} from 'app/redux/StorePlanSlice'
import AddressList from 'app/features/settings/billing/addresslist/AddressList'
import {RootState} from 'setup'
import {Checkbox2} from '_core/forms'
import {ModalBody, ModalHeader} from '_core/components/Modal'
import StorePaymentDetails from './StorePaymentDetails'

type Props = {
  showDialog: any
  storePaymentsList?: any
  onClose?: any
  onSubmit?: (value: any) => any
}

const pageSize = 5

const PaymentForm: FC<Props> = ({showDialog, onClose, onSubmit}) => {
  const dispatch = useDispatch()
  const [paymentError, setPaymentError] = useState('')
  const storeAddressList = useSelector<RootState, any[]>(selectStoreAddressList)
  const isFetching = useSelector<RootState, boolean>(selectIsFetching)

  const formik = useFormik<StorePaymentModel>({
    initialValues: storePaymentInitialState,
    validationSchema: PaymentDetailsSchema,
    onSubmit: handleSubmit,
  })

  const {setFieldValue, values} = formik

  useEffect(() => {
    dispatch(getStoreAddresses({pageSize}))
  }, [dispatch])

  async function handleSubmit(values: models.StorePaymentModel, {setSubmitting}: any) {
    try {
      setSubmitting(true)
      const {status, paymentMethodId, errors} = await storePlanService.createStorePayment(values)
      if (status === 'success') {
        dispatch(showMessage({text: 'Card Details Saved Successfully', variant: 'success'}))
        if (values.payment.isDefault) {
          await storePlanService.updateStorePaymentMethodDefault(paymentMethodId)
        }
        formik.resetForm()
        if (onSubmit) {
          onSubmit(values)
        }
        dispatch(
          getStorePayments({
            pageSize: 10,
            offset: 0,
          })
        )
        handleClose()
      } else {
        setPaymentError(errors[0])
        console.log(errors[0])
      }
    } catch (error) {
      console.error(error)
      dispatch(
        showMessage({text: 'Error occurred while processing your request', variant: 'error'})
      )
    } finally {
      setSubmitting(false)
    }
  }

  useEffect(() => {
    if (storeAddressList?.length) {
      setFieldValue('hasAddress', true)
    }
  }, [setFieldValue, storeAddressList])

  const handleClose = () => {
    if (storeAddressList?.length) {
      setFieldValue('hasAddress', true)
    } else {
      setFieldValue('hasAddress', false)
    }
    onClose()
  }
  return (
    <Modal
      size='lg'
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='h-auto'
      show={showDialog}
      centered
    >
      <ModalHeader title='Add Credit Card' toolbar={{onClick: onClose}} />
      <ModalBody isFetching={isFetching}>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <StorePaymentDetails>
              <Checkbox2 name='payment.isDefault' label='Set Default Payment Card' />
            </StorePaymentDetails>
            {!values.hasAddress ? (
              <AddressDetails />
            ) : (
              <AddressList formik={formik} storeAddressList={storeAddressList} />
            )}

            <div className='px-10'>
              <ErrorMessage error={paymentError} />
            </div>
            <div className='d-flex justify-content-end m-5'>
              <div className='me-5'>
                <button
                  id='btnAddressCancel'
                  type='button'
                  onClick={handleClose}
                  className='btn explore-btn-outline'
                >
                  Cancel
                </button>
              </div>

              <Button
                id='btnAddressSave'
                type='submit'
                title='Save'
                isSubmitting={formik.isSubmitting}
                isValid={formik.isValid}
                className='btn-primary'
              />
            </div>
          </form>
        </FormikProvider>
      </ModalBody>
    </Modal>
  )
}
export default PaymentForm
