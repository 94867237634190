import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestPassword} from '../redux/AuthCRUD'
import {Button} from '_core'
import {KTSVG} from '_metronic/helpers'

const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .required('Email is required'),
})

export function ForgotPassword() {
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [notFoundUser, setNotFoundUser] = useState<boolean | undefined>(undefined)
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setSubmitting(true)
      setHasErrors(undefined)
      requestPassword(values.email)
        .then(({data: {error}}) => {
          if (error) {
            setHasErrors(true)
            setNotFoundUser(true)
            setSubmitting(false)
          } else {
            setNotFoundUser(false)
            setHasErrors(false)
            setSubmitting(false)
          }
        })
        .catch((error) => {
          console.log(error)
          setHasErrors(true)
          setSubmitting(false)
          setNotFoundUser(false)
          setStatus('The login detail is incorrect')
        })
    },
  })

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>Forgot Password ?</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-400 fw-bold fs-4'>Enter your email to reset your password.</div>
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {hasErrors === true && notFoundUser !== true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              <i className='bi bi-exclamation-circle-fill text-danger me-2'></i>
              Sorry, looks like there are some errors detected, please try again.
            </div>
          </div>
        )}

        {hasErrors === true && notFoundUser === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              <i className='bi bi-exclamation-circle-fill text-danger me-2'></i>
              Account not found
            </div>
          </div>
        )}

        {hasErrors === false && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>
              <KTSVG
                path='/media/icons/duotune/arrows/arr016.svg'
                className='svg-icon svg-icon-3 svg-icon-success me-2'
              />
              Sent password reset link. Please check your email
            </div>
          </div>
        )}
        {/* end::Title */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fw-bold text-gray-900 fs-6 required'>Email</label>
          <input
            type='email'
            id='txtForgetEmail'
            placeholder=''
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <i className='bi bi-exclamation-circle-fill text-danger me-2'></i>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <Button
            id='btnSubmit'
            className='btn btn-lg btn-primary fw-bolder me-4'
            type='submit'
            title='Submit'
            isSubmitting={formik.isSubmitting}
          />

          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-lg btn-secondary fw-bolder'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              Cancel
            </button>
          </Link>
        </div>
        {/* end::Form group */}
      </form>
    </>
  )
}
