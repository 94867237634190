import {format} from 'd3-format'
import _ from 'lodash'

export const simple = (value: number) => {
  if (value === undefined || value === 0) {
    return '$0.00'
  }
  if (value > 10000) {
    return format('$,.2s')(value)
  }
  return format('$,.2f')(value)
}

export const simple2 = (value: any) => {
  value = parseFloat(value)

  if (value === 0 || _.isNaN(value)) {
    return '$0.00'
  }
  if (value > 10000) {
    return format('$,.2s')(value)
  }
  return format('$,.2f')(value)
}

export const simple3 = (value: number) => {
  if (value === undefined || value === 0) {
    return '$0.00'
  }
  return format('$,.2f')(value)
}

export const simple4 = (value: any) => {
  value = parseFloat(value)

  if (value === 0 || _.isNaN(value)) {
    return '-'
  }
  return format('$,.2f')(value)
}

export const simpleCurrency = simple

export const currency = simple2

export const currency2 = simple2

export const simpleCurrency2 = simple3
