import apolloClient from 'apollo-client'
import {GET_SUBSCRIPTION_INVOICES_LIST} from './graphql/invoice'
import Service from '../Service'

class InvoiceService extends Service {
  constructor() {
    super('/billing/v1')
  }
  async invoiceList() {
    const {data, errors} = await apolloClient.query({
      query: GET_SUBSCRIPTION_INVOICES_LIST,
    })
    if (errors) {
      throw errors
    }
    return {data: data.subscriptionInvoices.nodes, totalCount: data.subscriptionInvoices.totalCount}
  }

  async processPayment(params: {invoiceIds: any; paymentMethodId: string}) {
    const {data} = await this.post(params, '/payment/pending/invoice')
    return data
  }
}
const invoiceService = new InvoiceService()

export default invoiceService
