import clsx from 'clsx'
import {FC} from 'react'
import {KTSVG} from '_metronic/helpers'
import SetupGuide from './SetupGuide'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1'

const SetupGuideHeader: FC = () => {
  return (
    <>
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_set_menu_toggle'
      >
        <div
          className={clsx('btn btn-active-light-primary btn-custom', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <KTSVG
            path='/media/icons/duotune/maps/map001.svg'
            className={clsx(toolbarButtonIconSizeClass, 'svg-icon-primary')}
          />
          Setup Guide
        </div>

        <SetupGuide />
      </div>
    </>
  )
}

export default SetupGuideHeader
