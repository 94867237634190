import {selectSubscriptionInvoices, setSubscriptionInvoices} from 'app/redux/StorePlanSlice'
import {FC, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from 'setup'
import {map} from 'lodash'
import PaymentProcessButton from './PaymentProcess'
import eventPublishService from 'app/services/EventPublishService/EventPublishService'
import {useHistory} from 'react-router-dom'
import {UserModel} from 'app/features/auth/models/UserModel'
import invoiceService from 'app/services/InvoiceService/InvoiceService'
import storePlanService from 'app/services/StorePlanService/StorePlanService'
import {showMessage} from 'app/redux/messageSlice'
import {subscriptionStatus} from 'app/constant'
import {useSubscription} from '../SubscriptionContext'
import PaymentList from './PaymentList'
import PaymentWrapper from './PaymentWrapper'

type Props = {}

const PaymentMethods: FC<Props> = () => {
  const dispatch = useDispatch()
  const [paymentMethodId, setPaymentMethodId] = useState('')
  const [error, setError] = useState('')
  const [isSubmitting, setIsSubmitting] = useState({
    message: 'The payment process using the default payment card',
    isLoading: false,
  })
  const invoices = useSelector<RootState, Array<any>>(selectSubscriptionInvoices)
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const {setIsLocked} = useSubscription()
  const history = useHistory()

  const handleSelectPaymentMethod = (id: any) => {
    setPaymentMethodId(id)
    setError('')
  }

  const handlePay = async () => {
    try {
      setIsSubmitting({...isSubmitting, isLoading: true})
      const message = {
        storeId: user.store?.id,
        invoiceIds: map(invoices, 'id'),
      }
      await eventPublishService.invoicePayment(message)
      setTimeout(() => {
        setIsSubmitting({
          isLoading: true,
          message: 'Processing Payment, Please be patient',
        })
      }, 1000)
      setTimeout(() => {
        setIsSubmitting({
          isLoading: true,
          message:
            'Currently, your payment is in progress. however, it may take some time to complete...',
        })
      }, 30000)

      setTimeout(async () => {
        if (await checkInvoicePaidOrNot()) {
          window.location.reload()
        }
      }, 40500)
    } catch (err) {
      handleClearState()
      console.error(err)
      dispatch(showMessage({text: 'An error occurred while paying invoice', variant: 'error'}))
    }
  }

  const checkInvoicePaidOrNot = async () => {
    const {data, totalCount} = await invoiceService.invoiceList()
    dispatch(setSubscriptionInvoices(data))
    if (totalCount === 0) {
      setIsLocked(false)
      dispatch(showMessage({text: 'Invoice has been paid successfully', variant: 'success'}))
      if (user && user.store) {
        await storePlanService.updateStorePlan(user.store.storePlanId, {
          status: subscriptionStatus.active,
        })
        history.push('/home')
      }
      return false
    }
    return true
  }

  const handleClearState = () => {
    setIsSubmitting({isLoading: false, message: ''})
  }

  return (
    <PaymentWrapper
      toolbar={
        <PaymentProcessButton
          isSubmitting={isSubmitting.isLoading}
          onPay={handlePay}
          message={isSubmitting.message}
        />
      }
    >
      <PaymentList
        onSubmit={handlePay}
        onSelectPaymentMethod={handleSelectPaymentMethod}
        paymentMethodId={paymentMethodId}
      />

      {error && (
        <div className='fv-plugins-message-container invalid-feedback'>
          <i className='bi bi-exclamation-circle-fill text-danger me-2'></i>
          <span role='alert'>{error}</span>
        </div>
      )}
    </PaymentWrapper>
  )
}
export default PaymentMethods
