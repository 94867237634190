import { OverlayTrigger } from '_core'
import {FC} from 'react'
import {Popover} from 'react-bootstrap-v5'

type Props = {
  message: string
  title?: string
  status: any
}

const MessagePopover: FC<Props> = ({message, status, title}) => {
  if (!message) {
    return null
  }

  const popover = (
    <Popover id='popover-basic'>
      {title && <Popover.Title as='h3'>{title}</Popover.Title>}
      <Popover.Content>
        <div className='d-flex flex-column'>
          <div>
            <span className='fw-semibold pe-10 text-gray-600 fs-7'>{message}</span>
          </div>
        </div>
      </Popover.Content>
    </Popover>
  )

  return (
    <OverlayTrigger trigger={['hover', 'focus']} placement='top' overlay={popover}>
      <span>{status}</span>
    </OverlayTrigger>
  )
}

export default MessagePopover
