import {setPageSize} from 'app/redux/pagingSlice'
import {FC} from 'react'
import {useDispatch} from 'react-redux'

const sizes = [
  {name: 10, value: 10},
  {name: 25, value: 25},
  {name: 50, value: 50},
  {name: 100, value: 100},
]

type Props = {
  onPageSizeChange: (value: any) => void
  pageSize?: number
}

const PageSizeFilter: FC<Props> = ({onPageSizeChange, pageSize}) => {
  const dispatch = useDispatch()

  const handlePageSizeChange = (e: any) => {
    const pageSize = parseInt(e.target.value)
    if (onPageSizeChange) {
      onPageSizeChange(pageSize)
    }
    dispatch(setPageSize(pageSize))
    window.scrollTo({
      top: 50,
      behavior: 'smooth' || 'auto',
    })
  }
  return (
    <div>
      <span className='d-flex align-items-center'>
        <select
          className='form-select form-select-sm w-auto h-50 font-weight-bold mr-4 border-0 bg-light'
          onChange={handlePageSizeChange}
          value={pageSize}
        >
          {sizes.map((size: any) => (
            <option value={size.value} key={size.value}>
              {size.name}
            </option>
          ))}
        </select>
      </span>
    </div>
  )
}
export default PageSizeFilter
