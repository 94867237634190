import {Button} from '_core'
import ConfirmationDialog from '_core/components/ConfirmationDialog'
import {useFormikContext} from 'formik'
import React, {FC, useState} from 'react'
import trim from 'lodash/trim'
import {useParams} from 'react-router-dom'
import customerService from 'app/services/CustomerService'
import {useDispatch} from 'react-redux'
import {showMessage} from 'app/redux/messageSlice'

interface Props {
  children: React.ReactNode
  onSave: (values: any) => void | any
  email: string
  onEmailCheck: (email: string, id: string) => boolean | any
}

const CustomerEmailAlert: FC<Props> = ({onSave, email, children, onEmailCheck}) => {
  const [showDialog, setShowDialog] = useState(false)
  const {values, isSubmitting, isValid, setSubmitting} = useFormikContext<any>()
  const dispatch = useDispatch()
  const {id} = useParams<any>()

  const handleSave = async () => {
    try {
      setSubmitting(true)
      await customerService.updateCustomerEmail({id, email: values.email})
      await onSave(values)
    } catch (error) {
      console.error(error)
      dispatch(showMessage({text: 'An error occurred while saving changes', variant: 'error'}))
    } finally {
      setSubmitting(false)
    }
  }

  const handleOpen = async () => {
    if (await onEmailCheck(values.email, id)) {
      setShowDialog(true)
    }
  }

  const handleClose = () => {
    setShowDialog(false)
    setSubmitting(false)
  }

  if (!id) {
    return <>{children}</>
  }

  if (trim(email) === trim(values?.email)) {
    return <>{children}</>
  }

  return (
    <div>
      <Button
        id='btnCustomerSave'
        type='button'
        title='Save'
        isSubmitting={isSubmitting}
        isValid={isValid}
        className='btn-primary'
        onClick={handleOpen}
      />
      <ConfirmationDialog
        show={showDialog}
        title='Email Update'
        message={`Are you sure you want to update the email address from ' ${email} ' to ' ${values.email} ' ?`}
        onClose={handleClose}
        onConfirm={handleSave}
        isSubmitting={isSubmitting}
      />
    </div>
  )
}

export default CustomerEmailAlert
