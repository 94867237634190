import {ModalHeader} from '_core/components/Modal'
import {
  getCustomerAddresses,
  selectCustomerAddressList,
  selectCustomersIsFetching,
} from 'app/features/customers/redux/customerSlice'
import {getPaymentMethods} from 'app/features/customers/redux/paymentMethodSlice'
import AddressList from 'app/features/orders/components/customer/AddressList'
import {hideLoader} from 'app/redux/loaderSlice'
import {showMessage} from 'app/redux/messageSlice'
import draftOrderPaymentService from 'app/services/DraftOrderPaymentService/DraftOrderPaymentService'
import React, {useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from 'setup'

interface Props {
  payment: any
  customerId: string
  selectedAddress: any
  title?: string
  onSelectedAddress?: (getValue: any) => void
}

export const ChangeBillingAddress: React.FC<Props> = ({
  payment,
  selectedAddress,
  customerId,
  title = 'Change Billing Address',
  onSelectedAddress,
}) => {
  const dispatch = useDispatch()
  const [showDialog, setShowDialog] = useState(false)
  const [showAddAddress, setShowAddAddress] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [addressId, setAddressId] = useState('')
  const [error, setError] = useState('')

  const addressList = useSelector<RootState, any[]>(selectCustomerAddressList)
  const isFetching = useSelector<RootState, boolean>(selectCustomersIsFetching)

  const handleOpen = () => {
    if (customerId) {
      dispatch(getCustomerAddresses(customerId))
    }
    setShowDialog(true)
  }

  const handleClose = () => {
    setShowDialog(false)
    dispatch(hideLoader())
  }

  const handleAddAddress = () => {
    setShowAddAddress(true)
  }

  const handleCloseAddress = () => {
    if (showAddAddress) {
      setShowAddAddress(false)
      setAddressId('')
      setError('')
    } else {
      handleClose()
    }
  }

  const handleSetAddress = (show: boolean, id: string) => {
    setShowAddAddress(show)
    setAddressId(id)
    setError('')
  }

  const handleChangeAddress = async (address: any) => {
    try {
      setIsSubmitting(true)
      const res = await draftOrderPaymentService.paymentMethodUpdate({
        id: payment.id,
        addressId: address.id,
      })
      if (res && res.status === 'success') {
        dispatch(getPaymentMethods(customerId))
        dispatch(
          showMessage({
            text: 'Customer Payment billing address has been updated successfully ',
            variant: 'success',
          })
        )
        if (onSelectedAddress) {
          onSelectedAddress(address)
        }
        handleCloseAddress()
      } else {
        setError(res.error)
        dispatch(
          showMessage({
            text: 'An error occurred while saving billing address',
            variant: 'error',
          })
        )
      }
    } catch (error) {
      console.log(error)
      dispatch(
        showMessage({
          text: 'An error occurred while saving billing address',
          variant: 'error',
        })
      )
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <>
      {' '}
      <button
        type='button'
        className='btn btn-transparent btn-sm m-0 p-0 me-2 btn-active-text-primary text-decoration-underline bg-white'
        onClick={handleOpen}
      >
        {title}
      </button>
      <Modal
        size='lg'
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='h-auto w-auto'
        show={showDialog}
        centered
      >
        <ModalHeader
          title={showAddAddress && !addressId ? 'Add New Address ' : 'Address'}
          toolbar={{onClick: handleCloseAddress}}
        />
        <AddressList
          addressList={addressList}
          isFetching={isFetching}
          onClose={handleCloseAddress}
          customerId={customerId}
          onShowAddress={handleSetAddress}
          onChangeAddress={handleChangeAddress}
          checkedAddress={selectedAddress}
          isSubmitting={isSubmitting}
          address={{
            id: addressId,
            dialog: showAddAddress,
          }}
          error={error}
          onError={() => setError('')}
        >
          <div className='me-5'>
            <button
              type='button'
              id='btnAddPaymentCard'
              title='Add New Address'
              onClick={handleAddAddress}
              className='btn btn-secondary'
            >
              Add New Address
            </button>
          </div>
        </AddressList>
      </Modal>
    </>
  )
}

export default ChangeBillingAddress
