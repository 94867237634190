import { gql } from '@apollo/client'

export const GET_CUSTOMER_TAGS = gql`
  query GetCustomerTags {
    customerTagDefintions {
      nodes {
        id
        name
        description
        tagType
      }
      totalCount
    }
  }
`
export const GET_CUSTOMER_TAGS_BY_NAME = gql`
  query GetCustomerTags($name: String!) {
    customerTagDefintions(
      filter: {
        name: {includesInsensitive: $name}
      }) {
      totalCount
    }
  }
`

export const CREATE_CUSTOMER_TAG = gql`
  mutation CreateCustomerTag($customerTagDefintion: CustomerTagDefintionInput!) {
    createCustomerTagDefintion(input: {customerTagDefintion: $customerTagDefintion}) {
      clientMutationId
    }
  }
`

export const DELETE_CUSTOMER_TAG = gql`
  mutation DeleteCustomerTag($id: UUID!) {
    deleteCustomerTagDefintion(input: {id: $id}) {
      clientMutationId
    }
  }
`
