import apolloClient from 'apollo-client'
import {
  GET_STORE_PLAN,
  GET_STORE_PAYMENTS,
  CREATE_STORE_PAYMENT,
  SAVE_STORE_PLAN,
  GET_BILLING_ADDRESSES,
  UPDATE_STORE_PLAN,
  DELETE_STORE_PAYMENT,
  UPDATE_STORE_PAYMENT_METHOD_DEFAULT,
} from './graphql'
import {mapToCreateBillingAccount} from './helper'

class StorePlanService {
  async getStorePlan() {
    const {data} = await apolloClient.query({
      query: GET_STORE_PLAN,
    })
    const {shop} = data
    return {
      ...shop,
      ...shop.storePlan,
      storePlan: shop.storePlan.id,
      trialPeriod: shop.storePlan.trialPeriod,
      subscriptionPlan: shop.storePlan.subscriptionPlan,
      isStorePaymentMethod: shop.storePaymentMethods.totalCount > 0,
    }
  }

  async createStorePayment(storePayment: any) {
    const {data, errors} = await apolloClient.mutate({
      mutation: CREATE_STORE_PAYMENT,
      variables: {storePaymentMethod: mapToCreateBillingAccount(storePayment)},
    })
    if (errors) {
      throw errors
    }
    return data.createStorePaymentMethod
  }

  async createStorePayment2(storePaymentMethod: any) {
    const {data, errors} = await apolloClient.mutate({
      mutation: CREATE_STORE_PAYMENT,
      variables: {storePaymentMethod},
    })
    if (errors) {
      throw errors
    }
    return data.createStorePaymentMethod
  }

  async saveStorePlan(billingAccount: any) {
    return apolloClient.mutate({
      mutation: SAVE_STORE_PLAN,
      variables: {billingAccount},
    })
  }

  async getStorePayments(filter: any) {
    const {data} = await apolloClient.query({
      query: GET_STORE_PAYMENTS,
      variables: filter,
    })
    return {data: data.storePaymentMethods.nodes, totalCount: data.storePaymentMethods.totalCount}
  }
  async getStoreAddress(filter: any) {
    const {data} = await apolloClient.query({
      query: GET_BILLING_ADDRESSES,
      variables: filter,
    })
    return {data: data.billingAddresses.nodes, totalCount: data.billingAddresses.totalCount}
  }

  async updateStorePlan(id: string, data: any) {
    return await apolloClient.mutate({
      mutation: UPDATE_STORE_PLAN,
      variables: {
        id,
        patch: data,
      },
    })
  }

  async deleteStorePayment(id: string) {
    return await apolloClient.mutate({
      mutation: DELETE_STORE_PAYMENT,
      variables: {id},
    })
  }

  async updateStorePaymentMethodDefault(id: string) {
    return await apolloClient.mutate({
      mutation: UPDATE_STORE_PAYMENT_METHOD_DEFAULT,
      variables: {id},
    })
  }
}

const storePlanService = new StorePlanService()
export default storePlanService
