import {FC} from 'react'
import clsx from 'clsx'
import {MaskedNumberField, Select} from '_core/forms'
import {Month, YearRanges} from '../../models/StorePaymentModel'

type Props = {
  className?: any
  children?: React.ReactNode
}

const StorePaymentDetails: FC<Props> = ({className, children}) => {
  return (
    <div className={(clsx('mx-5'), className)}>
      <div className='fs-4 fw-bolder text-dark mb-3 py-4'>Credit Card Information</div>
      <div className='row mb-10'>
        <div className='col'>
          <label className='form-label fs-6  required'>Card Number </label>
          <MaskedNumberField
            name='payment.number'
            placeholder='Card Number'
            format='#### #### #### ####'
          />
        </div>
      </div>
      <div className='row mb-10'>
        <div className='col-md-8 fv-row'>
          <label className='required fs-6 fw-semibold form-label mb-2'>Expiration Date</label>

          <div className='row fv-row'>
            <div className='col-6'>
              <Select id='sltMonth' className='form-select' name='payment.expMonth'>
                <option value=''>Month </option>
                {Month.map((month: any) => (
                  <option key={month} value={month}>
                    {month}
                  </option>
                ))}
              </Select>
            </div>

            <div className='col-6'>
              <Select id='sltMonth' className='form-select' name='payment.expYear'>
                <option value=''> Year</option>
                {YearRanges.map((year: any) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </Select>
            </div>
          </div>
        </div>

        <div className='col-md-4'>
          <label className='form-label fs-7  required'>CVV</label>
          <MaskedNumberField
            name='payment.cvv'
            placeholder='CVV'
            format='####'
            allowNegative={false}
            className='py-3'
          />
        </div>
      </div>
      {children && <div className='mb-5'>{children}</div>}
    </div>
  )
}
export default StorePaymentDetails
