import {gql} from '@apollo/client'

export const GET_LOGGED_IN_USER = gql`
  query GetLoggedInUser {
    me {
      email
      firstName
      lastName
      phone
      permissions {
        nodes
      }
      isStoreOwner
    }
    shop {
      domain
      name
      id
      paymentProviderType
      storePlan {
        trialExpired
        id
      }
      storeSetups {
        nodes {
          id
        }
      }
    }
  }
`

export const GET_STORE_ADMIN_MENUS = gql`
  query StoreAdminMenus {
    storeAdminMenus {
      nodes {
        storeAdminMenuItemsByMenuId {
          nodes {
            code
            displayOrder
            icon
            id
            menuId
            name
            url
          }
        }
        code
        displayOrder
        icon
        id
        name
        url
        showInNavbar
      }
    }
  }
`
