import {FC, useEffect, useState} from 'react'
import {FormikProvider} from 'formik'
import {Button} from '_core'
import {useFormik} from 'formik'
import {useDispatch, useSelector} from 'react-redux'
import {Modal} from 'react-bootstrap-v5'
import {showMessage} from 'app/redux/messageSlice'
import CustomerAddress from '../CustomerAddress'
import {
  addressDefaultValues,
  AddressModel,
  addressValidationSchema,
} from 'app/features/customers/models/Customer'
import customerService from 'app/services/CustomerService/CustomerService'
import {
  fetchCustomerAddress,
  getCustomerAddresses,
  resetAddressState,
  setErrors,
} from 'app/features/customers/redux/customerSlice'
import {RootState} from 'setup'
import CustomerDetail from '../CustomerDetail'
import {omit} from 'lodash'
import {ModalHeader} from '_core/components/Modal'
import Loader from '_core/components/Loader/Loader'

type Props = {
  onClose: any
  customerId: any
  customerOverView: any
  addressId: any
}

const AddressForm: FC<Props> = ({onClose, customerId, customerOverView, addressId}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)

  const address = useSelector<RootState>(({customers}) => customers.customer.address) as any
  const isFetching = useSelector<RootState>(({customers}) => customers.customer.isFetching) as any

  const dispatch = useDispatch()
  const formik = useFormik<AddressModel>({
    initialValues: addressDefaultValues,
    validationSchema: addressValidationSchema,
    onSubmit: handleSubmit,
  })
  const {setFieldValue, setValues} = formik

  useEffect(() => {
    if (addressId) {
      dispatch(fetchCustomerAddress(addressId))
    }
  }, [dispatch, addressId])

  useEffect(() => {
    return () => {
      dispatch(resetAddressState())
    }
  }, [dispatch])

  useEffect(() => {
    if (address && addressId) {
      const _address = {
        address,
        firstName: address.firstName,
        lastName: address.lastName,
        email: address.email,
        phone: address.phone,
      }
      setValues(_address)
    }
  }, [address, addressId, setValues])

  useEffect(() => {
    if (customerOverView && !addressId) {
      Object.entries(customerOverView).forEach(([name, value]) => {
        setFieldValue(`${name}`, value)
      })
    }
  }, [customerOverView, addressId, setFieldValue])

  async function handleSubmit(model: any) {
    const {address} = model
    const customer = {
      ...address,
      ...omit(model, ['address', 'isLinkedWithPayment']),
    }

    try {
      setIsSubmitting(true)
      if (addressId) {
        await customerService.updateAddress(customer)
        dispatch(
          showMessage({
            text: 'Customer Address has been updated successfully ',
            variant: 'success',
          })
        )
      } else {
        await customerService.createAddress(customer)
        dispatch(
          showMessage({
            text: 'Customer Address has been created successfully ',
            variant: 'success',
          })
        )
      }
      dispatch(setErrors(''))
      dispatch(getCustomerAddresses(customerId))
      onClose()
    } catch (error) {
      console.log(error)
      dispatch(
        showMessage({
          text: 'An error occurred while address ',
          variant: 'error',
        })
      )
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Modal
      size='lg'
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='h-auto'
      show={true}
      centered
      loading={isFetching}
    >
      <FormikProvider value={formik}>
        <ModalHeader
          title={!addressId ? 'Add Address' : 'Edit Address'}
          toolbar={{onClick: onClose}}
        />
        <Loader show={isFetching} />
        <form onSubmit={formik.handleSubmit}>
          <div className='row gy-5 gx-xl-8'>
            <div className='col-xl-12'>
              <CustomerAddress>
                <CustomerDetail />
              </CustomerAddress>
            </div>
          </div>
          <div className='d-flex justify-content-end mb-5 mx-4'>
            <div className='me-5'>
              <button
                id='btnAddressCancel'
                type='button'
                onClick={onClose}
                className='btn explore-btn-outline'
              >
                Cancel
              </button>
            </div>
            <div>
              <Button
                id='btnPaymentSave'
                type='submit'
                title='Save'
                isSubmitting={isSubmitting}
                isValid={formik.isValid}
                className='btn-primary'
              />
            </div>
          </div>
        </form>
      </FormikProvider>
    </Modal>
  )
}
export default AddressForm
