import {FC, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import {ModalHeader} from '_core/components/Modal'
import Elavon from 'app/components/PaymentForm/Elavon'
import {useFormikContext} from 'formik'
import {useHistory} from 'react-router-dom'

type Props = {}

const ElavonForm: FC<Props> = () => {
  const [show, setShow] = useState(false)
  const history = useHistory()
  const {values} = useFormikContext()

  const {
    id: customerId,
    address: {id: addressId},
  }: any = values

  useEffect(() => {
    if (customerId && addressId) {
      setShow(true)
    }
  }, [customerId, addressId])

  const handleNavigate = () => {
    if (!window.location.pathname.includes('/orders/draft')) {
      history.push('/customers')
    }
  }
  const handleSave = () => {
    setShow(false)
    handleNavigate()
  }

  const handleClose = () => {
    setShow(false)
    handleNavigate()
  }

  if (customerId && addressId) {
    return (
      <Modal
        size='lg'
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='h-auto'
        show={show}
        centered
      >
        <ModalHeader
          title='Add Payment Method'
          toolbar={{
            onClick: handleClose,
          }}
        />
        <div className='modal-body align-items-center'>
          <Elavon onSave={handleSave} customerId={customerId} addressId={addressId} />
        </div>
      </Modal>
    )
  }

  return null
}

export default ElavonForm
