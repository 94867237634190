import {FC, useEffect, useState, useCallback, useRef} from 'react'
import {elavon} from 'app/Payment'
import {setPaymentError} from 'app/features/orders/redux/paymentMethodSlice'
import {addPaymentStatus} from 'app/constant'
import {useDispatch, useSelector} from 'react-redux'
import {Button, ErrorMessage} from '_core'
import capitalize from 'lodash/capitalize'
import paymentService from 'app/services/PaymentService'
import clsx from 'clsx'
import {Spinner} from '_core/components/Loader/Loader'

type Props = {
  onClose?: any
  onSave: (id: string) => void
  paymentMethodList?: any
  customerId: any
  address?: any
  addressId: string
  children?: any
}

const message =
  "Sorry, we're unable to add your payment method, please contact our support team for further assistance"

const Elavon: FC<Props> = ({customerId, addressId, onSave, onClose}) => {
  const dispatch = useDispatch()
  const [isSubmitting, setSubmitting] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [showCard, setShowCard] = useState(false)
  const [card, setCard] = useState({})
  const [paymentMethodId, setPaymentMethodId] = useState('')
  const ref = useRef({customerId: '', addressId: '', isPrimary: false})
  const [isPrimary, setIsPrimary] = useState(false)
  const [error, setError] = useState('')

  const handlePaymentCompleted = useCallback(
    async (pm: any) => {
      const handleError = (message: any) => {
        dispatch(setPaymentError(message))
        setError(message)
        setSubmitting(false)
      }

      if (pm.status === addPaymentStatus.succeeded) {
        try {
          setShowCard(true)
          setSubmitting(true)
          setCard(pm.card)
          const card = {
            ...pm.card,
            isPrimary: ref.current.isPrimary,
          }
          if (!card.externalId) {
            setError(message)
            setSubmitting(false)
            return
          }
          const {id} = await paymentService.createCardPaymentMethod(card)
          onSave(id)
          setPaymentMethodId(id)
        } catch (error) {
          handleError(pm.data?.errorMessage)
        }
      } else {
        handleError(pm.data?.errorMessage)
        // if (onClose) {
        //   onClose()
        // }
      }
    },
    [dispatch, onSave, onClose]
  )

  useEffect(() => {
    const triggerLightBox = async () => {
      try {
        setError('')
        setSubmitting(true)
        await elavon.addCard({addressId, customerId}, handlePaymentCompleted, 'id_payment')
        setIsLoading(false)
      } catch (error) {
        console.error(error)
        setSubmitting(false)
      }
    }
    if (ref.current.addressId !== addressId || ref.current.customerId !== customerId) {
      ref.current = {...ref.current, customerId, addressId}
      triggerLightBox()
    }
  }, [handlePaymentCompleted, addressId, customerId])

  const handleSave = async () => {
    setError('')
    dispatch(setPaymentError(''))
    if (paymentMethodId) {
      onSave(paymentMethodId)
    } else {
      setSubmitting(true)
      const {id} = await paymentService.createCardPaymentMethod(card)
      onSave(id)
      setPaymentMethodId(id)
      setSubmitting(false)
    }
  }

  const handleChangePrimary = (e: any) => {
    const {checked} = e.target
    setIsPrimary(checked)
    ref.current.isPrimary = checked
  }

  return (
    <div className='w-100'>
      {!showCard && (
        <>
          <div className='fv-row mt-10 ms-8'>
            <label className='form-check form-check-inline form-check-solid me-5'>
              <input
                className='form-check-input'
                type='checkbox'
                checked={isPrimary}
                id='flexCheckChecked'
                onChange={handleChangePrimary}
              />

              <span className='fw-bold ps-2 fs-6'>Set as primary payment method</span>
            </label>
          </div>
          {isLoading && <Spinner />}
          <iframe
            name='id_payment'
            title='payment'
            id='id_payment'
            className={clsx('border-0 overflow-hidden', isLoading ? '' : 'w-100 h-400px')}
          ></iframe>
        </>
      )}
      {showCard && (
        <ElavonCard card={card} isSubmitting={isSubmitting} onCompleteSale={handleSave} />
      )}
      {error && <ErrorMessage error={error} />}
    </div>
  )
}

export default Elavon

type Props2 = {
  isSubmitting: boolean
  card: any
  onCompleteSale: any
}

const ElavonCard: FC<Props2> = ({isSubmitting, card, onCompleteSale}) => {
  return (
    <div className='row'>
      <div className='col-xxl-6 col-md-6 col-lg-6 col-xs-8 pb-3 mb-5'>
        <div className='btn btn-active-primary d-flex h-100 border-dotted py-3'>
          <div className='d-flex align-items-center me-4'></div>
          <div className='d-flex justify-content-between w-100'>
            <span className='d-flex align-items-center me-2'>
              <span className='d-flex flex-column'>
                <span className='fw-bolder fs-6'>
                  {`${capitalize(card.brand)}  **** ${getCardLast4(card.maskedNumber)}`}
                </span>
                <span className='fs-7 text-muted text-start'>
                  {`${getExpMonth(card.expDate)}/${getExpYear(card.expDate)}`}
                </span>
              </span>
            </span>
          </div>
        </div>
      </div>
      <div className='d-flex justify-content-center c_payment my-10'>
        <Button
          id='btnSave'
          type='button'
          title='Save'
          isSubmitting={isSubmitting}
          className='btn-primary'
        />
      </div>
    </div>
  )
}

const getCardLast4 = (value: string) => {
  try {
    return value.substring(value.length - 4, value.length)
  } catch (error) {
    return ''
  }
}

const getExpMonth = (value: string) => {
  try {
    return value.substring(0, 2)
  } catch (error) {
    return ''
  }
}

const getExpYear = (value: string) => {
  try {
    return value.substring(2, 4)
  } catch (error) {
    return ''
  }
}
