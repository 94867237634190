import * as yup from 'yup'
import moment from 'moment'
import {checkPhoneNumberLength} from 'app/features/customers/models/Customer'
import lookupsService from 'app/services/LookupsService/LookupsService'
import {countryName} from 'app/constant/address'

interface PaymentModel {
  number?: string
  expMonth: string
  expYear: string
  cvv: string
  isDefault: boolean
}
export default PaymentModel

const zipCodeMessage = 'Please enter a valid zip code'

const addressSchema = yup.object().shape({
  firstName: yup
    .string()
    .required('First name is required')
    .max(199, 'First name should not exceed 200 characters'),
  lastName: yup
    .string()
    .required('Last name is required')
    .max(199, 'Last name should not exceed 200 characters'),
  email: yup.string().email('Email is invalid').required('Email is required'),
  phone: yup
    .string()
    .test(function (value) {
      const {path, createError} = this
      const isCheck = checkPhoneNumberLength(value).includes('+') ? 11 : 9
      if (checkPhoneNumberLength(value).length <= isCheck) {
        return createError({
          path,
          message: 'Please enter valid phone number',
        })
      }
      return true
    })
    .required('Phone is required'),
  addressLine1: yup
    .string()
    .required('Address line 1 is required')
    .max(199, 'Address should not exceed 200 characters'),
  addressLine2: yup
    .string()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .max(199, 'Address should not exceed 200 characters'),
  city: yup.string().required('City is required').max(199, 'City should not exceed 200 characters'),
  countryId: yup.string().required('Country is required'),
  stateId: yup.string().required('State is required'),
  zipCode: yup
    .string()
    .required('Zip Code is required')
    .test(async function (zipCode) {
      const {path, createError, parent} = this
      const id = parent?.countryId
      if (id) {
        const name = await lookupsService.getCountryName(id)
        if (name === countryName.US && zipCode?.trim().length !== 5) {
          return createError({
            path,
            message: zipCodeMessage,
          })
        } else if (name === countryName.CA && zipCode?.trim().replace(/\s+/g, '').length !== 6) {
          return createError({
            path,
            message: zipCodeMessage,
          })
        }
        return true
      } else if (zipCode && zipCode?.trim().length < 5) {
        return createError({
          path,
          message: zipCodeMessage,
        })
      }
      return true
    }),
  dateOfBirth: yup
    .string()
    .required('Date of birth is required')
    .typeError('Date of birth is required')
    .test(function (value) {
      const {path, createError} = this
      if (!value) {
        return createError({
          path,
          message: 'Date of birth is required',
        })
      }
      const dob = moment(value, 'MM-DD-YYYY')
      if (dob.isValid()) {
        const age = moment(new Date()).diff(dob, 'years')
        if (age < 21) {
          return createError({
            path,
            message: 'Date birth is not valid, should be more than 21 years',
          })
        }
      } else {
        return createError({
          path,
          message: 'Date of birth is required',
        })
      }

      return true
    }),
})

export type PaymentMethodModel = {
  hasAddress?: boolean
  address: any
  payment: any
  addressId?: string
  isPrimary?: boolean
}

export const paymentMethodInitialState: PaymentMethodModel = {
  address: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    stateId: '',
    countryId: '',
    zipCode: '',
    dateOfBirth: '',
  },
  payment: {expMonth: '', expYear: '', cvv: '', number: ''},
  isPrimary: false,
}

export const PaymentMethodSchema = yup.object().shape({
  address: yup.object().when('hasAddress', {
    is: false,
    then: addressSchema,
    otherwise: yup.object().notRequired(),
  }),
  addressId: yup.string().when('hasAddress', {
    is: true,
    then: yup.string().required('Please Select Address'),
    otherwise: yup.string().notRequired(),
  }),
  payment: yup.object().shape({
    number: yup
      .string()
      .trim()
      .min(18, 'Please enter valid card number')
      .required('Please enter a Card Number'),
    expMonth: yup.string().required(' Select a Month '),
    expYear: yup.string().required(' Select a Year '),
    cvv: yup
      .string()
      .trim()
      .min(3, 'CVV not less than 3')
      .max(4, 'CVV not greater than 4')
      .required('Enter a CVV Number'),
  }),
})

export type ElavonPaymentMethodModel = {
  hasAddress?: boolean
  address: any
  addressId?: string
  isPrimary?: boolean
}

export const elavonPaymentMethodInitialState: ElavonPaymentMethodModel = {
  address: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    stateId: '',
    countryId: '',
    zipCode: '',
    dateOfBirth: '',
  },
  isPrimary: false,
}

export const ElavonPaymentMethodSchema = yup.object().shape({
  address: yup.object().when('hasAddress', {
    is: false,
    then: addressSchema,
    otherwise: yup.object().notRequired(),
  }),
  addressId: yup.string().when('hasAddress', {
    is: true,
    then: yup.string().required('Please Select Address'),
    otherwise: yup.string().notRequired(),
  }),
})
