import {gql} from '@apollo/client'

export const GET_STORE_BASIC_INFO = gql`
  query GetStore {
    shop {
      email
      name
      domain
      country {
        code
      }
    }
  }
`

export const GET_STORE = gql`
  query GetStore {
    shop {
      name
      domain
      customerEmail
      addressLine1
      addressLine2
      city
      state {
        code
      }
      country {
        name
      }
      zipCode
    }
  }
`

export const GET_STORE_DETAILS = gql`
  query GetStore {
    stores {
      nodes {
        id
        name
        companyName
        phone
        addressLine2
        addressLine1
        domain
        city
        stateId
        countryId
        timeZone
        zipCode
        email
        customerEmail
        unitSystem
        weightUnit
        storeUrl
        storeLogo
        sendgridDomainId
        isDomainVerified
      }
    }
  }
`

export const CHECK_STORE_EMAIL_EXIST = gql`
  query CheckStoreEmailExist($email: String!) {
    storeEmailExist(pEmail: $email)
  }
`

export const GET_FEE_DETAILS = gql`
  query AccountCreationLinkRequests($token: String) {
    accountCreationLinkRequests(filter: {token: {equalTo: $token}}){
      nodes {
        transactionFee
        fee
        feeType
        active
      }
    }
  }
`