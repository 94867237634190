import {FC} from 'react'
import TimePicker from 'rc-time-picker'
import {useFormikContext} from 'formik'
import clsx from 'clsx'
import moment from 'moment'

type Props = {
  id: string
  name: string
  className?: string
  handleChange?: any
  placeholder?: string
  onChange?: (value: any) => any
}

const format = 'hh:mm A'

const TimeFiled: FC<Props> = ({className, name, onChange}) => {
  const {getFieldMeta, setFieldValue, setFieldTouched} = useFormikContext()
  const {value, error, touched}: any = getFieldMeta(name)
  const time = value ? moment(value, format) : undefined
  const handleChange = (value: any) => {
    setFieldValue(name, value ? value : '', true)
    setFieldTouched(name, true, true)
    if (onChange) {
      onChange(value)
    }
  }

  return (
    <>
      <TimePicker
        value={time}
        showSecond={false}
        className={clsx(
          {'is-invalid': touched && error},
          {
            'is-valid': touched && !error,
          }
        )}
        onChange={handleChange}
        format={format}
        use12Hours
        minuteStep={5}
        inputReadOnly
      />

      {touched && error && (
        <div className='fv-plugins-message-container invalid-feedback'>
          <i className='bi bi-exclamation-circle-fill text-danger me-2'></i>
          <span role='alert'>{error}</span>
        </div>
      )}
    </>
  )
}

export default TimeFiled
