import {ApolloClient, InMemoryCache, createHttpLink} from '@apollo/client'
import {setContext} from '@apollo/client/link/context'

const prodBaseURL = 'https://graphql.corksy.io/graphql'
const stagBaseURL = 'http://54.193.108.62:8500/graphql'
const devBaseURL = 'http://localhost:8080/graphql'
const demoBaseUrl = '/graphql'

let graphQL = config()

function config() {
  const host = window.location.href
  if (host.startsWith('https://admin.corksy.io')) {
    return prodBaseURL
  } else if (host.includes('localhost:3011') || host.includes('127.0.0.1')) {
    return devBaseURL
  } else if (
    host.startsWith('https://demo.corksy.io') ||
    host.startsWith('https://staging.corksy.io')
  ) {
    return demoBaseUrl
  } else {
    return demoBaseUrl
  }
}

// function config(node_env: any) {
//   if (node_env === 'production') {
//     return prodBaseURL
//   } else if (node_env === 'development') {
//     return demoBaseUrl
//   } else if (node_env === 'demo') {
//     return demoBaseUrl
//   } else {
//     return stagBaseURL
//   }
// }

const httpLink = createHttpLink({
  uri: graphQL,
})

const authLink = setContext((_, {headers}) => {
  const token = sessionStorage.getItem('accessToken')
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})

export default apolloClient
