import {KTSVG} from '_metronic/helpers'
import {PageLink, PageTitle} from '_metronic/layout/core'
import {useHistory, useLocation} from 'react-router-dom'

type Props = {
  title: string | any
  path: string
  isCustomize?: boolean
  icon?: string
  toolbar?: any
  children?: React.ReactNode
}

const BreadCrumbHeader: React.FC<Props> = ({title, path, isCustomize, icon, toolbar, children}) => {
  const history = useHistory()
  const {state} = useLocation<any>()

  const handleNavigate = () => {
    history.push(path, state)
  }

  const breadCrumbs: Array<PageLink> = [
    {
      title,
      path,
      isSeparator: false,
      isActive: false,
      children,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]
  if (!isCustomize) {
    return <PageTitle breadcrumbs={breadCrumbs}>{title}</PageTitle>
  }
  return (
    <div className='card bg-transparent'>
      <div className='card-header ps-0'>
        <h3 className='card-title align-items-start flex-column mb-5'>
          <span className='mb-1 d-flex align-items-center'>
            <KTSVG
              path={icon ? icon : '/media/icons/corksy/arrow-left.svg'}
              className='svg-icon-2hx svg-icon-dark me-3 cursor-pointer'
              onClick={handleNavigate}
            />

            <span className='fs-1'>{title}</span>
          </span>
        </h3>
        {toolbar && <div className='card-toolbar pb-3'>{toolbar}</div>}
      </div>
    </div>
  )
}
export default BreadCrumbHeader
