import {Checkbox} from '_core/forms'
import {useFormikContext} from 'formik'
import {useEffect} from 'react'

interface Props {
  children: React.ReactNode
  isCreate?: boolean
}

const EnableCustomerInformation: React.FC<Props> = ({children, isCreate}) => {
  const {
    values: {isEnabledCustomerMethods},
    setFieldValue,
  } = useFormikContext<any>()

  useEffect(() => {
    if (isCreate) {
      setFieldValue('isEnabledCustomerMethods', true)
    }
  }, [isCreate, setFieldValue])

  if (isCreate) {
    return <>{children}</>
  }

  if (isEnabledCustomerMethods) {
    return (
      <>
        <SwitchButton />
        {children}
      </>
    )
  }

  return <SwitchButton />
}
export default EnableCustomerInformation

const SwitchButton: React.FC = () => {
  return (
    <div className='fv-row mb-6 ms-5'>
      <label className='form-switch form-switch-md'>
        <Checkbox
          id='chkIsEnabledCustomerMethods'
          className='form-switch form-check-input cursor-pointer me-3'
          name='isEnabledCustomerMethods'
        />
        <span className='fw-bold fs-6 cursor-pointer'>Switch to Add Address & Payment Methods</span>
      </label>
    </div>
  )
}
