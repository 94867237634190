import {FC} from 'react'
import clsx from 'clsx'
import StripeCard from 'app/components/PaymentForm/Stripe/StripeCard'
import Card from '_core/components/Card'

type Props = {
  className?: any
  children?: React.ReactNode
  showCard?: boolean
}

const PaymentDetails: FC<Props> = ({className, children, showCard}) => {
  if (showCard) {
    return (
      <Card title='Credit Card Information'>
        <StripeCard />

        {children && <div className='mb-5'>{children}</div>}
      </Card>
    )
  }

  return (
    <div className={(clsx('mx-5'), className)}>
      <div className='fs-4 fw-bolder text-dark mb-3 py-4'>Credit Card Information</div>
      <StripeCard />

      {children && <div className='mb-5'>{children}</div>}
    </div>
  )
}
export default PaymentDetails
