export default function setupAxios(axios: any, store: any) {
  axios.interceptors.request.use(
    (config: any) => {
      const {
        auth: {accessToken},
      } = store.getState()

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`
      } else {
        const _accessToken = window.localStorage.getItem('accessToken')
        if (_accessToken) {
          config.headers.Authorization = `Bearer ${_accessToken}`
        }
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )
}
