import React, {FC} from 'react'
import Indicator from '../Indicator'
import clsx from 'clsx'

type ButtonProps = {
  id: string
  className?: any
  onClick: any
  text: any
  disabled?: any
  isSubmitting?: boolean
}

type CloseButtonProps = {
  id: string
  className?: any
  onClick: any
  text: any
  disabled?: any
}

type Props = {
  button?: ButtonProps
  close: CloseButtonProps
  children?: React.ReactNode
  className?: any
}

const ModalFooter: FC<Props> = ({className, button, close, children}) => {
  return (
    <div className={clsx('modal-footer', className)}>
      {close && (
        <button
          id={close.id}
          className={close?.className ? close.className : 'btn btn-sm explore-btn-outline me-5'}
          onClick={close?.onClick}
          disabled={close.disabled}
        >
          {close.text}
        </button>
      )}
      {button && (
        <div className='me-5'>
          <button
            id={button.id}
            disabled={button.disabled || button.isSubmitting}
            className={button.className ? button.className : 'btn-primary btn btn-sm'}
            onClick={button.onClick}
          >
            {button.isSubmitting && <Indicator text=' Please wait...' />}
            {!button.isSubmitting && button.text}
          </button>
        </div>
      )}
      {children}
    </div>
  )
}

export default ModalFooter
