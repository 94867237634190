import React, {FC} from 'react'
import Pagination from 'app/components/pagination'

export type PaginationProps = {
  totalCount: number
  pageSize: any
  currentPage: any
  onPageChange: any
  isDisableResetPaging?: boolean
}

type Props = {
  isFetching?: any
  className?: any
  loaderText?: string
  children: React.ReactNode
  pagination?: PaginationProps
  isOuterPaging?: boolean
}

const ModalBody: FC<Props> = ({
  isFetching,
  className,
  loaderText,
  children,
  pagination,
  isOuterPaging,
}) => {
  return (
    <div className='modal-body align-items-center'>
      <div className={className}>
        {isFetching && (
          <div className='d-flex justify-content-center'>
            <label>
              <span className='indicator-progress d-block'>
                <span className='spinner-border spinner-border ms-5 mt-5 align-middle text-info'></span>
              </span>
              {loaderText}
            </label>
          </div>
        )}
        {!isFetching && children}
        {pagination && !isOuterPaging && (
          <>
            {pagination.totalCount && !isFetching ? (
              <Pagination
                totalCount={pagination.totalCount}
                onPageChange={pagination.onPageChange}
                currentPage={pagination.currentPage}
                pageSize={pagination.pageSize}
                isDisableResetPaging={pagination.isDisableResetPaging}
                className='mx-5 mb-4'
              />
            ) : null}
          </>
        )}
      </div>
      {pagination && isOuterPaging && (
        <div className='pt-2'>
          {pagination.totalCount && !isFetching ? (
            <Pagination
              totalCount={pagination.totalCount}
              onPageChange={pagination.onPageChange}
              currentPage={pagination.currentPage}
              pageSize={pagination.pageSize}
              isDisableResetPaging={pagination.isDisableResetPaging}
            />
          ) : null}
        </div>
      )}
    </div>
  )
}
export default ModalBody
