import {FC} from 'react'
import {Modal} from 'react-bootstrap-v5'
import {KTSVG} from '_metronic/helpers'
import {Button} from '_core'

type Props = {
  title: string
  message: any
  show: boolean
  onClose: () => void
  onConfirm: () => void
  isHideConfirm?: boolean
  labelNo?: string
  labelYes?: string
  isSubmitting: boolean
  size?: any | 'sm' | 'lg' | 'xl'
  errorMessage?: string
  centered?: boolean
}

const ConfirmationDialog: FC<Props> = ({
  show,
  title,
  message,
  labelNo,
  labelYes,
  onClose,
  onConfirm,
  isSubmitting,
  size,
  errorMessage,
  centered,
  isHideConfirm,
}) => {
  return (
    <Modal
      size={size}
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='h-auto'
      show={show}
      onHide={onClose}
      centered={centered}
    >
      <>
        <div className='modal-header d-flex'>
          <h5 className='modal-title'>{title}</h5>
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={onClose}>
            <KTSVG
              className='svg-icon-2 border rounded-1 border-primary svg-icon-primary'
              path='/media/icons/duotune/arrows/arr061.svg'
            />
          </div>
        </div>

        <div className='modal-body align-items-center fs-4'>{message}</div>
        {errorMessage && <span className='text-danger fs-6 ms-6'>{errorMessage}</span>}
        <div className='modal-footer'>
          <button id='btnProductClose' className='btn btn-primary btn-sm' onClick={onClose}>
            {labelNo || 'No'}
          </button>

          {!isHideConfirm && (
            <Button
              id='btnOk'
              className='btn btn-secondary btn-sm ms-5'
              onClick={onConfirm}
              isSubmitting={isSubmitting}
              type='button'
              title={labelYes || 'Yes'}
            />
          )}
        </div>
      </>
    </Modal>
  )
}

export default ConfirmationDialog
