import {FC} from 'react'
import {useFormikContext} from 'formik'
import clsx from 'clsx'

type Props = {
  id?: string
  name: string
  className?: string
  children: any
  onChange?: (value: any) => void
  disabled?: boolean
}

const Select: FC<Props> = ({children, className, name, onChange, disabled, ...prop}) => {
  const {getFieldMeta, getFieldProps} = useFormikContext()
  const {touched, error} = getFieldMeta(name)
  const props = getFieldProps(name)
  const id = prop.id || `dd${name}`

  const handleChange = (e: any) => {
    const {value} = e.target
    props.onChange(e)
    if (onChange) {
      onChange(value)
    }
  }

  return (
    <>
      <select
        className={clsx(
          'form-select',
          className,
          {'is-invalid': touched && error},
          {
            'is-valid': touched && !error,
          }
        )}
        {...props}
        onChange={handleChange}
        id={id}
        disabled={disabled}
      >
        {children}
      </select>

      {touched && error && (
        <div className='fv-plugins-message-container invalid-feedback'>
          <i className='bi bi-exclamation-circle-fill text-danger me-2'></i>
          <span role='alert'>{error}</span>
        </div>
      )}
    </>
  )
}

export default Select
