import {FC} from 'react'

type Props = {
  text?: string
}

const Indicator: FC<Props> = ({text}) => {
  return (
    <span className='indicator-progress d-block'>
      {text}
      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
    </span>
  )
}

export default Indicator
