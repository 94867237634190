import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import DraftOrderPaymentService from 'app/services/DraftOrderPaymentService'

export const getPaymentMethods = createAsyncThunk(
  'draft/order/list',
  async (customerId: any, {dispatch, rejectWithValue}: any) => {
    try {
      dispatch(setIsFetching(true))
      const payments = await DraftOrderPaymentService.getPaymentMethods(customerId)
      if (!payments?.length) {
        dispatch(setShowPaymentCard(true))
      } else {
        dispatch(setShowPaymentCard(false))
      }
      return payments
    } catch (error) {
      return rejectWithValue(error)
    } finally {
      dispatch(setIsFetching(false))
    }
  }
)

const initialState = {
  list: [],
  isFetching: true,
  isSubmitting: false,
  showAddPaymentCard: false,
  error: null,
}

const deliveryMethodsSlice = createSlice({
  name: 'draft/order/payments',
  initialState,
  reducers: {
    setSubmitting: (state, action) => {
      state.isSubmitting = action.payload
    },
    setIsFetching: (state, action) => {
      state.isFetching = action.payload
    },
    setShowPaymentCard: (state, action) => {
      state.showAddPaymentCard = action.payload
    },
    setPaymentError: (state, action) => {
      state.error = action.payload
    },
    resetPaymentState: () => {
      return initialState
    },
  },
  extraReducers: {
    [getPaymentMethods.fulfilled.toString()]: (state, action) => {
      state.list = action.payload
      state.isFetching = false
    },
  },
})

export const {
  setSubmitting,
  setIsFetching,
  setShowPaymentCard,
  setPaymentError,
  resetPaymentState,
} = deliveryMethodsSlice.actions

export default deliveryMethodsSlice.reducer
