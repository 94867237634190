import {FC} from 'react'
import {useFormikContext} from 'formik'
import clsx from 'clsx'
import Flatpickr from 'react-flatpickr'

type Props = {
  id: string
  name: string
  className?: string
  handleChange?: any
  placeholder?: string
  getPopupContainer?: any
  minDate?: any
  maxDate?: any
  disabled?: boolean
}
const dateFormat = 'm-d-Y'

const DateFiled: FC<Props> = ({id, className, name, placeholder, minDate, maxDate, disabled}) => {
  const {getFieldMeta, setFieldValue, setFieldTouched} = useFormikContext()

  const {touched, error, value}: any = getFieldMeta(name)

  const handleChange = ([date]: any) => {
    setFieldValue(name, date, true)
    setFieldTouched(name, true, true)
  }

  return (
    <div>
      <Flatpickr
        className={clsx(
          'form-control',
          className,
          {'is-invalid': touched && error},
          {
            'is-valid': touched && !error,
          }
        )}
        value={value}
        options={{dateFormat, minDate, maxDate}}
        onChange={handleChange}
        disabled={disabled}
        placeholder={placeholder}
        id={id}
      />

      {touched && error && (
        <div className='fv-plugins-message-container invalid-feedback'>
          <i className='bi bi-exclamation-circle-fill text-danger me-2'></i>
          <span role='alert'>{error}</span>
        </div>
      )}
    </div>
  )
}

export default DateFiled
