import {useEffect, useRef} from 'react'
import {DataUtil} from '_metronic/assets/ts/_utils'
// import {DataUtil} from '_metronic/assets/ts/_utils'
import {
  MenuComponent,
  DrawerComponent,
  ScrollComponent,
  ScrollTopComponent,
  StickyComponent,
  ToggleComponent,
  SwapperComponent,
} from '../assets/ts/components'

import {useLayout} from './core'

export function MasterInit() {
  const {config} = useLayout()
  const isFirstRun = useRef(true)
  const pluginsInitialization = () => {
    isFirstRun.current = false

    if (!DataUtil.initialized) {
      setTimeout(() => {
        ToggleComponent.bootstrap()
        ScrollTopComponent.bootstrap()
        DrawerComponent.bootstrap()
        StickyComponent.bootstrap()
        MenuComponent.bootstrap()
        ScrollComponent.bootstrap()
        SwapperComponent.bootstrap()
        DataUtil.initialized = true
      }, 500)
    }
  }

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false
      pluginsInitialization()
    }
  }, [config])

  return <></>
}
