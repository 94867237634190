import {FC, useEffect, useRef, useState} from 'react'
import Card from '_core/components/Card'
import {SearchComponent} from '_metronic/assets/ts/components'
import {KTSVG} from '_metronic/helpers'
import searchProductsService from 'app/services/SearchProductsService/SearchProductsService'
import CreateCustomer from './CreateCustomer'
import trim from 'lodash/trimStart'

type Props = {
  onSelect: (data: any) => void
  className?: any
}
const SearchCustomer: FC<Props> = ({onSelect, className}) => {
  const [searchText, setSearchText] = useState('')
  const [isFetching, setIsFetching] = useState(false)
  const [customers, setCustomers] = useState<any[]>([])
  const timeoutRef = useRef<any>()

  useEffect(() => {
    SearchComponent.createInsance('#kt_customer_search')
  }, [])

  const fetchCustomers = async (searchText: string) => {
    setIsFetching(true)
    const data = await searchProductsService.searchCustomers(searchText)
    setCustomers(data)
    setIsFetching(false)
  }

  const handleSearch = (e: any) => {
    let {value} = e.target
    value = trim(value)
    setSearchText(value)

    if (value.length > 1) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }

      const timeoutId = setTimeout(() => {
        fetchCustomers(value)
      }, 1000)

      timeoutRef.current = timeoutId
    }
  }

  const handSelect = async (customer: any) => {
    let paymentMethod: any = {}
    let defaultAddress: any = {}
    const {
      paymentMethods: {nodes},
      customerAddresses: {nodes: customerAddresses},
      ...cus
    } = customer
    if (nodes && nodes.length) {
      paymentMethod = nodes[0]
    }
    if (customerAddresses && customerAddresses.length) {
      defaultAddress = customerAddresses[0]
    }
    onSelect({...cus, paymentMethod, defaultAddress})
    setSearchText('')
  }

  return (
    <Card title='Customer' className={`${className}`}>
      <div
        id='kt_customer_search'
        className='d-flex align-items-stretch w-100'
        data-kt-search-keypress='true'
        data-kt-search-min-length='2'
        data-kt-search-enter='enter'
        data-kt-search-layout='menu'
        data-kt-menu-trigger='auto'
        data-kt-menu-overflow='false'
        data-kt-menu-permanent='true'
        data-kt-menu-placement='bottom-end'
      >
        <div
          className='d-flex align-items-center w-300px w-md-350px'
          data-kt-search-element='toggle'
          id='kt_customer_search_toggle'
        >
          <div data-kt-search-element='form' className='w-100 position-relative mb-3'>
            <KTSVG
              path='/media/icons/duotune/general/gen021.svg'
              className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 translate-middle-y ms-5'
            />

            <input
              id='txtSearchCustomer'
              value={searchText}
              type='text'
              className='form-control py-2 ps-15'
              name='search'
              placeholder='Search...'
              data-kt-search-element='input'
              onChange={handleSearch}
              autoComplete='off'
            />

            {isFetching && (
              <span
                className='position-absolute top-50 end-0 translate-middle-y lh-0 me-1'
                data-kt-search-element='spinner'
              >
                <span className='spinner-border h-15px w-15px align-middle text-gray-400' />
              </span>
            )}

            <span
              className='btn btn-flush btn-active-color-primary
               position-absolute top-50 end-0 translate-middle-y 
               lh-0 d-none'
              data-kt-search-element='clear'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon-2 svg-icon-lg-1 me-0'
              />
            </span>
          </div>
        </div>

        <div
          data-kt-search-element='content'
          className='menu menu-sub menu-sub-dropdown p-7 w-300px w-md-350px'
        >
          <div data-kt-search-element='wrapper'>
            <div className='scroll-y mh-200px mh-lg-325px'>
              {customers &&
                customers.map((customer: any) => (
                  <div className='d-flex flex-column' key={customer.id}>
                    <button
                      className='btn btn-link fs-6 text-gray-800 text-hover-primary fw-bold text-start'
                      onClick={() => handSelect(customer)}
                    >
                      {customer.firstName} {customer.lastName}
                      {customer.hasWineClub && (
                        <span className='svg-icon svg-icon-1 ms-2'>
                          <KTSVG
                            path='/media/icons/corksy/crown.svg'
                            className='svg-icon-warning svg-icon-2 p-0 m-0 pb-2'
                          />
                        </span>
                      )}
                      <div className='text-muted'>{customer.email}</div>
                    </button>
                    <div className='separator border-gray-200'></div>
                  </div>
                ))}
            </div>
            {customers.length === 0 && !isFetching && (
              <CreateCustomer onSelect={onSelect} searchText={searchText} />
            )}
          </div>
        </div>
      </div>
    </Card>
  )
}

export default SearchCustomer
