import featureFlagService from 'app/services/FeatureFlagService'
import {FC, useEffect, useState} from 'react'
//comment

type Props = {
  flagKey: string
  children: any
}

const FeatureFlag: FC<Props> = ({flagKey, children}) => {
  const [isFlagEnabled, setIsFlagEnabled] = useState(false)
  useEffect(() => {
    const fetch = async () => {
      try {
        const isFlagEnabled = await featureFlagService.evaluation(flagKey)
        setIsFlagEnabled(isFlagEnabled)
      } catch (error) {
        console.error(error)
      }
    }
    if (flagKey) {
      fetch()
    }
  }, [flagKey])
  if (isFlagEnabled) {
    return <>{children}</>
  }
  return null
}

export default FeatureFlag
