import React, {useMemo} from 'react'
import orderBy from 'lodash/orderBy'
import {AsideMenuItemWithSub} from '../AsideMenuItemWithSub'
import {AsideMenuItem} from '../AsideMenuItem'
import ChannelMenuItem from './ChannelMenuItem'

interface Props {
  menus: any[]
}

const SalesChannelMenu: React.FC<Props> = ({menus}) => {
  const salesChannelMenus = useMemo(() => {
    return orderBy(menus, 'displayOrder').filter(
      (item: any) => item.displayOrder >= 8 && item.displayOrder <= 9
    )
  }, [menus])

  if (salesChannelMenus && salesChannelMenus.length === 0) {
    return null
  }

  return (
    <>
      {salesChannelMenus.map((menu: any) => {
        return menu.storeAdminMenuItemsByMenuId.nodes.length > 0 ? (
          <AsideMenuItemWithSub
            id={`link${menu.name}`}
            to={menu.url}
            title={menu.name}
            icon={menu.icon}
            fontIcon='bi-person'
          >
            {menu.storeAdminMenuItemsByMenuId &&
              orderBy(menu.storeAdminMenuItemsByMenuId.nodes, 'displayOrder').map(
                (menuItem: any) => <ChannelMenuItem menuItem={menuItem} />
              )}
          </AsideMenuItemWithSub>
        ) : (
          <AsideMenuItem
            id={`link${menu.name}`}
            to={menu.url}
            icon={menu.icon}
            title={menu.name}
            fontIcon='bi-app-indicator'
          />
        )
      })}
    </>
  )
}

export default React.memo(SalesChannelMenu)
