import {combineReducers} from 'redux'
import customer from './customerSlice'
import customerBooking from './customerBookingSlice'
import paymentMethods from './paymentMethodSlice'

const customerReducer = combineReducers({
  customer,
  customerBooking,
  paymentMethods,
})
export default customerReducer
