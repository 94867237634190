import {FC} from 'react'
import {Field} from 'formik'
import clsx from 'clsx'

type Props = {
  id: string
  name: string
  className?: string
  handleChange?: any
  value: any
  label: string
  disabled?: boolean
}

const RadioButton: FC<Props> = ({name, value, label, className, disabled, handleChange}) => {
  return (
    <label className={clsx('d-flex align-items-center cursor-pointer me-5', className)}>
      <span className='form-check form-check-custom form-check-solid'>
        {handleChange ? (
          <Field
            type='radio'
            name={name}
            value={value}
            className='form-check-input'
            onChange={handleChange}
            disabled={disabled}
          />
        ) : (
          <Field
            type='radio'
            name={name}
            value={value}
            className='form-check-input'
            disabled={disabled}
          />
        )}
      </span>
      <span className='d-flex align-items-center ms-2'>
        <span className='fw-bold ps-2 fs-6'>{label}</span>
      </span>
    </label>
  )
}

export default RadioButton
