import * as Yup from 'yup'
import {useFormik, FormikProvider} from 'formik'
import {useHistory, useParams} from 'react-router-dom'
import {TextField} from '_core/forms'
import {Button} from '_core'
import storeService from 'app/services/StoreService'
import {toAbsoluteUrl} from '_metronic/helpers'
import {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {ErrorLinkUsed} from 'app/features/errors/components/ErrorLinkUsed'

const ContactDetailsSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email address')
    .required('Please enter a Business Email'),
})

const initialValues = {
  email: '',
}

function ContactDetails() {
  const history = useHistory()
  const {token} = useParams<any>()
  const dispatch = useDispatch()
  const [isLinkUsed, setIsLinkUsed] = useState<any>(null)

  useEffect(() => {
    const fetch = async () => {
      const data = await storeService.getAccountCreationRequest(token)
      setIsLinkUsed(!data.active)
    }
    if (token) {
      fetch()
    }
  }, [token, dispatch])

  const formik = useFormik({
    initialValues,
    validationSchema: ContactDetailsSchema,

    onSubmit: async (model, {setSubmitting, resetForm}) => {
      try {
        setSubmitting(true)
        const isEmailExists = await storeService.checkEmailIsExist(model.email)
        if (isEmailExists) {
          formik.setFieldError('email', 'Email already exists')
        } else {
          history.push('/store/details', {...model, token})
        }
      } catch (error) {
        console.error(error)
      } finally {
        setSubmitting(false)
      }
    },
  })

  if (token && isLinkUsed) {
    return <ErrorLinkUsed />
  }

  return (
    <FormikProvider value={formik}>
      <div>
        <span className='mb-5'>
          <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo.svg')} className='h-35px mb-5' />
        </span>
        <div className='free-trail-container d-flex flex-column flex-lg-row justify-content-around gap-10'>
          <div className=' w-100 w-lg-50 free-trail-left-container d-flex flex-column flex-card-fluid m-4'>
            {/* <div className='greeting'>— HI, WE’RE CORKSY</div> */}
            <div className='free-trail-text d-flex flex-column'>
              <span>Get Your Corksy</span>
              <span>Store today!</span>
            </div>
            <ul className='future'>
              <li className='mb-2'>No-Cost Corksy Marketplace to drive sales.</li>
              <li className='mb-2 text-wrap'>
                Best-in-class CRM to manage your customers and provide you with a 360 degree view
                across store, online, & marketing touch points.
              </li>
              <li className='mb-2'>Point of sale system</li>
              <li className='mb-2'>Order management & fulfillment</li>
              <li className='mb-2'>Tax & shipping compliance</li>
            </ul>
          </div>
          <div className='sm-pt-10 d-flex flex-center p-14 free-trail-card mt-10 rounded-10 w-100 w-lg-50 flex-card-fluid h-225px '>
            <form
              className='form w-100'
              onSubmit={formik.handleSubmit}
              noValidate
              autoComplete='disabled'
            >
              <div className='text-start mb-5'>
                <h2 className='text-dark free-trail-right-test'>Let’s get started</h2>
              </div>

              <div className='fv-row mb-10'>
                <label className='form-label fs-6 fw-bold text-dark required'>Email</label>
                <TextField
                  id='txtEmail'
                  placeholder='Business Email'
                  autoComplete='off'
                  name='email'
                />
              </div>

              <div className='text-center'>
                <Button
                  id='btnSave'
                  type='submit'
                  title='Continue Sign up'
                  isSubmitting={formik.isSubmitting}
                  isValid={formik.isValid}
                  className='btn-lg btn-lg btn-primary w-100'
                  endIcon='/media/icons/corksy/arrow-right.svg'
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </FormikProvider>
  )
}
export default ContactDetails
