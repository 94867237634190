import CustomerAddress from 'app/features/customers/customer/components/CustomerAddress'
import CustomerDetail from 'app/features/customers/customer/components/CustomerDetail'
import {
  addressDefaultValues,
  AddressModel,
  addressValidationSchema,
} from 'app/features/customers/models/Customer'
import {FormikProvider, useFormik} from 'formik'
import {FC, useEffect, useState} from 'react'
import {Button} from '_core'
import {omit} from 'lodash'
import {useDispatch} from 'react-redux'
import customerService from 'app/services/CustomerService/CustomerService'
import {showMessage} from 'app/redux/messageSlice'
import {getCustomerAddresses} from 'app/features/customers/redux/customerSlice'

type Props = {
  onClose: (id?: string) => void
  customerId: any
  isAdd?: any
  addressId?: string
  onSubmit?: (id?: string) => any
}

const AddressForm: FC<Props> = ({onClose, customerId, isAdd, addressId, onSubmit}) => {
  const dispatch = useDispatch()
  const formik = useFormik<AddressModel>({
    initialValues: addressDefaultValues,
    validationSchema: addressValidationSchema,
    onSubmit: handleSubmit,
  })
  const [isFetching, setIsFetching] = useState(false)

  const {setValues} = formik

  useEffect(() => {
    const fetchAddress = async () => {
      setIsFetching(true)
      const data = await customerService.fetchCustomerAddress(addressId)
      setValues(mapToAddressModel(data))
      setIsFetching(false)
    }
    if (addressId) {
      fetchAddress()
    }
  }, [dispatch, setValues, addressId])

  async function handleSubmit(model: any) {
    const {address} = model
    const customer = {
      ...address,
      customerId,
      ...omit(model, ['address', 'isLinkedWithPayment']),
    }
    let id
    try {
      if (addressId) {
        await customerService.updateAddress(customer)
        if (onSubmit) {
          await onSubmit(addressId)
        }
        dispatch(
          showMessage({
            text: 'Customer Address has been updated successfully ',
            variant: 'success',
          })
        )
      } else {
        const address = await customerService.createAddress(customer)
        id = address?.id
        if (onSubmit) {
          await onSubmit(id)
        }
        dispatch(
          showMessage({
            text: 'Customer Address has been created successfully ',
            variant: 'success',
          })
        )
      }
      dispatch(getCustomerAddresses(customerId))
      onClose(id)
    } catch (error) {
      console.log(error)
      dispatch(
        showMessage({
          text: 'An error occurred while address ',
          variant: 'error',
        })
      )
    }
  }
  if (isFetching) {
    return Loader()
  }
  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <div className='row gy-5 gx-xl-8'>
          <div className='col-xl-12'>
            <CustomerAddress>
              <CustomerDetail />
            </CustomerAddress>
          </div>
        </div>

        <div className='d-flex justify-content-end mb-5 mx-4'>
          {isAdd && (
            <div className='me-5'>
              <button
                id='btnAddressCancel'
                type='button'
                onClick={() => onClose()}
                className='btn explore-btn-outline'
              >
                Cancel
              </button>
            </div>
          )}
          <div>
            <Button
              id='btnAddress'
              type='submit'
              title='Save'
              isSubmitting={formik.isSubmitting}
              isValid={formik.isValid}
              className='btn-primary'
            />
          </div>
        </div>
      </form>
    </FormikProvider>
  )
}
export default AddressForm

const mapToAddressModel = (address: any) => {
  return {
    address,
    firstName: address.firstName,
    lastName: address.lastName,
    email: address.email,
    phone: address.phone,
  }
}

function Loader() {
  return (
    <div className='modal-body align-items-center pt-5'>
      <div className='d-flex justify-content-center '>
        <label>
          <span className='indicator-progress d-block'>
            <span className='spinner-border ms-5 align-middle text-info'></span>
          </span>
        </label>
      </div>
    </div>
  )
}
