import {gql} from '@apollo/client'

export const SEARCH_WINE_CLUB_PRODUCTS = gql`
  query SearchWineClubProducts($search: String!, $orderType: OrderTypes!) {
    products: searchProductsByOrderType(
      searchText: $search
      pOrderType: $orderType
      filter: {productType: {equalTo: "Wine"}, exclusiveForWineClub: {equalTo: true}}
    ) {
      nodes {
        id
        name
        imageSrc
        variants(filter: {deletedAt: {isNull: true}}) {
          nodes {
            id
            productId
            name
            price
            discountPrice
            available
            pickupAvailableForWineClub
            shipAvailableForWineClub
            inventoryItem {
              id
              cost
              canAllowNegativeInventory
            }
            weight {
              units
              value
            }
          }
        }
      }
    }
  }
`

export const SEARCH_PRODUCTS = gql`
  query SearchProducts($search: String!, $orderType: OrderTypes!) {
    products: searchProductsByOrderType(searchText: $search, pOrderType: $orderType) {
      nodes {
        id
        name
        imageSrc
        variants(filter: {deletedAt: {isNull: true}}) {
          nodes {
            id
            productId
            name
            price
            discountPrice
            available
            shipAvailableForAdminPortal
            pickupAvailableForAdminPortal
            inventoryItem {
              id
              cost
              canAllowNegativeInventory
            }
            weight {
              units
              value
            }
          }
        }
      }
    }
  }
`
