import {FC} from 'react'
import capitalize from 'lodash/capitalize'

type Props = {
  payment: any
  onHandleChange: any
  accountId: any
}
const PaymentListItem: FC<Props> = ({payment, onHandleChange, accountId}) => {
  return (
    <div className='col-xxl-6 pb-6'>
      <div className='card card-dashed flex-row flex-stack flex-wrap p-6 d-flex justify-content-between'>
        <div className='d-flex flex-column py-2 ms-7'>
          <div className='fs-6 fw-semibold text-gray-600'>
            {`${capitalize(payment.card.brand)} **** ${payment.card.last4}`}
            <br />
            {payment.card.expMonth}/{payment.card.expYear}
          </div>
        </div>
        {/* <div className='d-flex align-items-center'>
          {accountId === payment.id ? (
            <button
              className='btn btn-sm btn px-10 btn-active-light-primary btn-outline'
              onClick={() => onHandleChange(payment.id)}
            >
              Selected
            </button>
          ) : (
            <button
              className='btn btn-sm btn btn-secondary px-10'
              onClick={() => onHandleChange(payment.id)}
            >
              Select
            </button>
          )}
        </div> */}
      </div>
    </div>
  )
}
export default PaymentListItem
