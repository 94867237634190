import {FC, forwardRef} from 'react'
import Elavon from './Elavon'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from 'setup'
import {UserModel} from 'app/features/auth/models/UserModel'
import {paymentProviderTypes} from 'app/constant'
import {getPaymentMethods, setShowPaymentCard} from 'app/features/orders/redux/paymentMethodSlice'
import {showMessage} from 'app/redux/messageSlice'
import toLowerCase from 'lodash/toLower'
import {Checkbox} from '_core/forms'
import Stripe from './Stripe'

type Props = {
  onClose: any
  paymentMethodList?: any
  customerId: any
  addressId: any
  address?: any
  onSave?: () => void
  children?: any
  isPrimary: boolean | undefined
}

const PaymentForm = forwardRef((prop: Props, ref) => {
  const dispatch = useDispatch()
  const {onSave, onClose, paymentMethodList, address, addressId, customerId, children, ...props} =
    prop
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  const handleClose = () => {
    if (paymentMethodList?.length) {
      dispatch(setShowPaymentCard(false))
    } else {
      onClose()
    }
  }

  const handleSave = (id?: any) => {
    dispatch(getPaymentMethods(customerId))
    dispatch(
      showMessage({
        text: 'Payment card has been created successfully',
        variant: 'success',
      })
    )
    handleClose()
    if (onSave) {
      onSave()
    }
  }

  if (
    toLowerCase(user.store?.paymentProviderType) === paymentProviderTypes.elavon &&
    addressId &&
    customerId
  ) {
    return (
      <>
        <div id='pay_header' className='me-4'>
          <span className='fs-5 fw-bolder'>Credit Card</span>
        </div>
        <Elavon
          {...props}
          onClose={handleClose}
          onSave={handleSave}
          address={address}
          addressId={addressId}
          customerId={customerId}
        >
          {children}
        </Elavon>
      </>
    )
  }

  if (toLowerCase(user.store?.paymentProviderType) === paymentProviderTypes.stripe) {
    return (
      <Stripe config={user.store?.config} ref={ref}>
        <div className='fv-row mb-10'>
          <label className='form-check form-check-inline form-check-solid me-5'>
            <Checkbox id='chkIsPrimaryPayment' name='payment.isPrimary' />
            <span className='fw-bold ps-2 fs-6'>Set as primary payment method</span>
          </label>
        </div>
      </Stripe>
    )
  }

  return null
})

export default PaymentForm
