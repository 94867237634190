import {FC} from 'react'
import {Modal} from 'react-bootstrap-v5'
import withReducer from 'setup/withReducer'
import reducer from 'app/features/customers/redux'
import Customer from 'app/features/customers/customer/Customer'
import customerService from 'app/services/CustomerService/CustomerService'

type Props = {
  searchText: string
  onClose: () => void
  onSelect: (customer: any) => any
}

const CreateCustomerDialog: FC<Props> = ({searchText, onClose, onSelect}) => {
  const handleClose = async (customerId: any) => {
    onClose()
    if (customerId) {
      const data = await customerService.getCustomerById(customerId)
      onSelect(data)
    }
  }

  return (
    <Modal
      size='lg'
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='h-auto'
      show={true}
      centered
      onHide={handleClose}
    >
      <Customer
        searchText={searchText}
        onClose={handleClose}
        isCreate
        closeBtnChildren={
          <button id='btnClose' className='btn explore-btn-outline' onClick={onClose}>
            Close
          </button>
        }
      />
    </Modal>
  )
}
export default withReducer('customers', reducer)(CreateCustomerDialog)
