import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {setTotalCount} from 'app/redux/pagingSlice'
import MessageService from '../service/MessageService'

export const getMessageList = createAsyncThunk(
  'message/list',
  async (filter: any, {dispatch}: any) => {
    dispatch(setFetching(true))
    const {data, totalCount} = await MessageService.getMessages(filter)
    dispatch(setTotalCount(totalCount))
    return data
  }
)

export const getShippingComplianceMessage = createAsyncThunk(
  'message/shipping/compliance',
  async (filter: any, {dispatch}) => {
    dispatch(setFetching(true))
    const {data, totalCount} = await MessageService.getShippingComplianceMessage(filter)
    dispatch(setTotalCount(totalCount))
    return data
  }
)
const initialState = {
  messageList: [],
  shippingCompliance: [],
  filter: {
    offset: 0,
    name: '',
    pageSize: 10,
  },
  isFetching: false,
}
const getMessageSlice = createSlice({
  name: 'message/list',
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.filter = {...state.filter, ...action.payload}
    },
    setFetching: (state, action) => {
      state.isFetching = action.payload
    },
    resetMessages: (state) => {
      state.messageList = initialState.messageList
      state.filter = initialState.filter
    },
  },
  extraReducers: {
    [getMessageList.fulfilled.toString()]: (state, action) => {
      state.messageList = action.payload
      state.isFetching = false
    },
    [getShippingComplianceMessage.fulfilled.toString()]: (state, action) => {
      state.shippingCompliance = action.payload
      state.isFetching = false
    },
  },
})
export const {setFilter, setFetching, resetMessages} = getMessageSlice.actions

export default getMessageSlice.reducer
