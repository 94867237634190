import clsx from 'clsx'
import {FC, useMemo} from 'react'
import {Link} from 'react-router-dom'
import {useLayout} from '../../../core/LayoutProvider'
import {usePageData} from '../../../core/PageData'
import {KTSVG} from '_metronic/helpers'
import {CustomTitle} from './CutomerTitle'

const DefaultTitle: FC = () => {
  const {pageTitle, pageBreadcrumbs} = usePageData()
  const {classes} = useLayout()

  const pageBreadCrumb = useMemo(() => {
    if (pageBreadcrumbs && pageBreadcrumbs.length > 0) {
      return pageBreadcrumbs[0]
    }
    return null
  }, [pageBreadcrumbs])

  return (
    <div
      id='kt_page_title'
      data-kt-swapper='true'
      data-kt-swapper-mode='prepend'
      data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
      className={clsx('page-title d-flex', classes.pageTitle.join(' '))}
    >
      {/* begin::Title */}
      {pageBreadCrumb && (
        <>
          {pageBreadCrumb.children ? (
            <CustomTitle {...pageBreadCrumb} />
          ) : (
            <>
              {pageTitle && pageBreadCrumb ? (
                <h1 className='d-flex align-items-center text-dark fw-bolder my-1 fs-1 mb-5'>
                  <Link to={pageBreadCrumb.path} className='cursor-pointer'>
                    <KTSVG
                      path='/media/icons/corksy/arrow-left.svg'
                      className='svg-icon-2hx svg-icon-dark me-3'
                    />
                  </Link>
                  {pageTitle}
                  {/* {pageDescription && config.pageTitle && config.pageTitle.description && (
                  <>
                    <span className='h-20px border-gray-200 border-start ms-3 mx-2'></span>
                    <small className='text-muted fs-7 fw-bold my-1 ms-1'>{pageDescription}</small>
                  </>
                )} */}
                </h1>
              ) : null}
            </>
          )}
        </>
      )}

      {/* end::Title */}

      {/* {pageBreadcrumbs &&
        pageBreadcrumbs.length > 0 &&
        config.pageTitle &&
        config.pageTitle.breadCrumbs && (
          <>
            {config.pageTitle.direction === 'row' && (
              <span className='h-20px border-gray-200 border-start mx-4'></span>
            )}
            <ul className='breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1'>
              {Array.from(pageBreadcrumbs).map((item, index) => (
                <li
                  className={clsx('breadcrumb-item', {
                    'text-dark': !item.isSeparator && item.isActive,
                    'text-muted': !item.isSeparator && !item.isActive,
                  })}
                  key={`${item.path}${index}`}
                >
                  {!item.isSeparator ? (
                    <Link className='text-muted text-hover-primary' to={item.path}>
                      {item.title}
                    </Link>
                  ) : (
                    <span className='bullet bg-gray-200 w-5px h-2px'></span>
                  )}
                </li>
              ))}
              <li className='breadcrumb-item text-dark'>{pageTitle}</li>
            </ul>
          </>
        )} */}
    </div>
  )
}

export {DefaultTitle}
