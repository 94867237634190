import { gql } from '@apollo/client'

export const GET_DRAFT_ORDERS = gql`
  query GetDraftOrders {
    draftOrders {
      nodes {
        id
        name
        orderNumber
        totalPrice
        createdAt
        status
        customer {
          firstName
          lastName
        }
      }
    }
  }
`
export const DELETE_ORDER_ITEM = gql`
  mutation DeleteOrder($id: UUID!) {
    deleteOrder(input: {id: $id}) {
      clientMutationId
    }
  }
`

export const DELETE_DRAFT_ORDER_ITEM = gql`
  mutation DeleteDraftOrder($id: UUID!) {
    deleteDraftOrder(input: {id: $id}) {
      clientMutationId
    }
  }
`