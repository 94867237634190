import {FC} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG} from '_metronic/helpers'
import {RootState} from '../../../../setup'
import {useSelector} from 'react-redux'
import {StoreSetupModel} from 'app/features/auth/models/StoreModel'
import redux from 'app/redux'
import withReducer from 'setup/withReducer'

const SetupGuide: FC = () => {
  const setup: StoreSetupModel = useSelector<RootState>(
    ({misc}) => misc.storeSetup.setup
  ) as StoreSetupModel

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>Setup Guide</div>
          </div>
        </div>
        <div className='separator my-2' />
      </div>
      <div className='menu-item '>
        <Link id='linkStore' to='/settings/store' className='menu-link px-8 gap-4'>
          <KTSVG
            path='/media/icons/corksy/store.svg'
            className={
              setup && setup.storeDetails
                ? 'svg-icon-2 svg-icon-success'
                : 'svg-icon-2 svg-icon-dark'
            }
          />
          Store Information
        </Link>
      </div>

      <div className='menu-item '>
        <Link id='linkShippingProfile' to='/settings/shipping' className='menu-link px-8 gap-3'>
          <KTSVG
            path='/media/icons/corksy/shipping.svg'
            className={
              setup && setup.shippingDelivery
                ? 'svg-icon-2 svg-icon-success'
                : 'svg-icon-2 svg-icon-dark'
            }
          />
          Shipping and Delivery
        </Link>
      </div>
      <div className='menu-item '>
        <Link
          id='linkShipppingCompliance'
          to='/settings/compliance'
          className='menu-link px-8 gap-4'
        >
          <KTSVG
            path='/media/icons/corksy/compliance.svg'
            className={
              setup && setup.shippingCompliance
                ? 'svg-icon-2 svg-icon-success'
                : 'svg-icon-2 svg-icon-dark'
            }
          />
          Shipping Compliance
        </Link>
      </div>
      <div className='menu-item '>
        <Link id='linkPayment' to='/settings/payments/accounts' className='menu-link px-8 gap-4'>
          <KTSVG
            path='/media/icons/corksy/payments.svg'
            className={
              setup && setup.payment ? 'svg-icon-2 svg-icon-success' : 'svg-icon-2 svg-icon-dark'
            }
          />
          Payment
        </Link>
      </div>
      <div className='menu-item '>
        <Link id='linkproducts' to='/products' className='menu-link px-8 gap-4'>
          <KTSVG
            path='/media/icons/corksy/products.svg'
            className={
              setup && setup.products ? 'svg-icon-2 svg-icon-success' : 'svg-icon-2 svg-icon-dark'
            }
          />
          Products
        </Link>
      </div>
      <div className='menu-item '>
        <Link id='linkTheme' to='/online_store' className='menu-link px-8 gap-4'>
          <KTSVG
            path='/media/icons/corksy/customize-theme.svg'
            className={
              setup && setup.customizeTheme
                ? 'svg-icon-2 svg-icon-success'
                : 'svg-icon-2 svg-icon-dark'
            }
          />
          Customize Theme
        </Link>
      </div>
      <div className='menu-item px-3 row'>
        <Link id='linkAddDomain' to='/settings/domains' className='menu-link px-8 gap-4'>
          <KTSVG
            path='/media/icons/corksy/add-domain.svg'
            className={
              setup && setup.domain ? 'svg-icon-2 svg-icon-success' : 'svg-icon-2 svg-icon-dark'
            }
          />
          Add Domain
        </Link>
      </div>
      <div className='menu-item px-3 row'>
        <Link id='linkAddDomain' to='/settings/locations' className='menu-link px-8 gap-4'>
          <KTSVG
            path='/media/icons/corksy/location.svg'
            className={
              setup && setup.orderLocation
                ? 'svg-icon-2 svg-icon-success'
                : 'svg-icon-2 svg-icon-dark'
            }
          />
          Inventory Locations
        </Link>
      </div>
      {/* <div className='menu-item px-3 row'>
        <Link id='linkAddDomain' to='/settings/emailcampaigner' className='menu-link px-8 gap-4'>
          <KTSVG
            path='/media/icons/corksy/email.svg'
            className={
              setup && setup.emailCampaigner
                ? 'svg-icon-2 svg-icon-success'
                : 'svg-icon-2 svg-icon-dark'
            }
          />
          Email Campaigner
        </Link>
      </div> */}
    </div>
  )
}

export default withReducer('misc', redux)(SetupGuide)
