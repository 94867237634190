import {FC} from 'react'
import Pagination from 'app/components/pagination'
import clsx from 'clsx'
import {RecordProps} from 'app/components/pagination/PaginationList'

export type PaginationProps = {
  totalCount: number
  onPageChange: (value: any) => any
  currentPage: number
  pageSize: number
  className?: string
  isDisableResetPaging?: boolean
  className2?: string
  records?: RecordProps
}

type Props = {
  className?: string
  title?: string | any
  search?: any
  description?: any
  subTitle?: string
  children: any
  footer?: any
  showBorder?: boolean
  toolbar?: any
  paging?: PaginationProps
  isFetching?: boolean
  isHideCardBody?: boolean
  isHideCard?: boolean
  isHideHeader?: boolean
}

const Card: FC<Props> = ({
  className,
  title,
  subTitle,
  description,
  children,
  footer,
  showBorder,
  toolbar,
  search,
  paging,
  isFetching,
  isHideCardBody,
  isHideCard,
  isHideHeader,
}) => {
  const border = showBorder ? 'border-1' : 'border-0'

  if (isHideCard) {
    return children
  }

  return (
    <div>
      <div className={`card rounded shadow-sm ${className}`}>
        {!isHideHeader && (
          <div className={`card-header ${border} pt-5 `}>
            <div className='d-flex align-items-center position-relative my-1'>
              {title && (
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label mb-1'>{title}</span>
                  <div className='text-dark mt-3 fw-bold fs-7'>{description}</div>
                  <span className='text-muted mt-1 fw-bold fs-7'>{subTitle}</span>
                </h3>
              )}

              {search && <>{search}</>}
            </div>

            {toolbar && <div className='card-toolbar'>{toolbar}</div>}
          </div>
        )}

        <div className={clsx(!isHideCardBody && 'card-body py-3')}>
          {isFetching && (
            <div className='w-100 vh-75 d-flex justify-content-center align-items-center'>
              <label>
                <span className='indicator-progress d-block'>
                  <span className='spinner-border align-middle ms-5 text-info'></span>
                </span>
                Loading...
              </label>
            </div>
          )}{' '}
          {children}
          {paging && paging.totalCount > 1 && (
            <div className={clsx('py-2', paging.className)}>
              <Pagination {...paging} className={paging.className2 ? paging.className2 : 'mx-5'} />
            </div>
          )}
        </div>
        {footer && <div className='card-footer'>{footer}</div>}
      </div>
    </div>
  )
}

export default Card
