import {all} from 'redux-saga/effects'
import {combineReducers} from 'redux'
import * as auth from 'app/features/auth'
import messages from 'app/features/message/redux'

// export const rootReducer = combineReducers({
//   auth: auth.reducer,
//   orders,
//   misc,
//   settings,
//   brands,
//   analytics,
//   onlineStore,
//   product,
//   inventory,
//   subscriptions,
//   wineClubs,
//   discounts,
//   customers,
//   messages,
// })

const createReducer = (asyncReducers?: any) => (state: any, action: never) => {
  const combinedReducer = combineReducers({
    auth: auth.reducer,
    messages,

    ...asyncReducers,
  })

  // const axn: any = action
  /*
  Reset the redux store when user logged out
   */

  // if (axn.type === '[Logout] Action') {
  //   state = undefined
  // }

  return combinedReducer(state, action)
}

export default createReducer

export type RootState = ReturnType<any>

export function* rootSaga() {
  yield all([auth.saga()])
}
