import {gql} from '@apollo/client'

export const GET_WINETASTING_BOOKINGS = gql`
  query GetCustomerEventBookings(
    $customerId: UUID!
    $offset: Int!
    $pageSize: Int!
    $orderBy: [CustomerBookingsOrderBy!]
  ) {
    customerBookings(
      orderBy: $orderBy
      offset: $offset
      first: $pageSize
      filter: {customerId: {equalTo: $customerId}}
    ) {
      nodes {
        id
        eventDate
        eventEndTime
        eventStartTime
        numberOfGuests
        customer {
          firstName
          lastName
          email
        }
      }
      totalCount
    }
  }
`
