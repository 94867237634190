import {gql} from '@apollo/client'

export const GET_MESSAGES = gql`
  query GetMessages($includes: String!, $offset: Int, $first: Int) {
    searchMessages(filter: {name: {includes: $includes}}, first: $first, offset: $offset) {
      nodes {
        createdAt
        id
        message
        name
        notificationType
        status
        subject
      }
      totalCount
    }
  }
`

export const GET_SHIPPING_COMPLIANCE_MESSAGES = gql`
  query GetShippingComplianceMessage($pageSize: Int!, $offset: Int!) {
    shippingCompliances(first: $pageSize, offset: $offset) {
      nodes {
        messages
        id
        createdat
        status
        checkoutById {
          customer {
            firstName
            lastName
            email
          }
        }
      }
      totalCount
    }
  }
`
