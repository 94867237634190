import {FC} from 'react'
import {OverlayTrigger} from 'react-bootstrap-v5'

type Props = {
  overlay: any
  show?: number
  hide?: number
  placement?: string | any
  trigger?: any
  children: any | React.ReactNode
}

const OverlayTriggerV1: FC<Props> = ({
  placement = 'top',
  hide = 45,
  show = 250,
  children,
  ...props
}) => {
  return (
    <OverlayTrigger placement={placement} delay={{show, hide}} {...props}>
      {children}
    </OverlayTrigger>
  )
}

export default OverlayTriggerV1
