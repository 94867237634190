import {FC} from 'react'
type Props = {
  address: any
  onHandleChange: any
  accountId: any
}
const AddressListItem: FC<Props> = ({address, onHandleChange, accountId}) => {
  const handleChange = () => {
    onHandleChange(address.id)
  }
  return (
    <div className='col-xxl-6 pb-6'>
      <div className='card card-dashed flex-row flex-stack flex-wrap p-6 d-flex justify-content-between min-h-100'>
        <div className='d-flex flex-column py-2 ms-7 col-7'>
          <div className='fs-6'>
            <div className='fw-bolder '>
              {address.firstName} {address.lastName}
              {address.isPrimary && (
                <span className={`badge badge badge-light-success ms-2 `}>Primary</span>
              )}
            </div>
            <div className='pt-2'>{address.addressLine1}</div>
            <div>
              {' '}
              {address.addressLine2
                ? `${address.addressLine2},${address.city}, `
                : `${address.city}, `}
              {address.zipCode}
            </div>
            <div>{address.state?.name}</div>
          </div>
        </div>
        <div className='d-flex align-items-center col-4'>
          {accountId === address.id ? (
            <button
              className='btn btn-sm btn px-10 btn-active-light-primary btn-outline'
              type='button'
              onClick={handleChange}
            >
              Selected
            </button>
          ) : (
            <button
              className='btn btn-sm btn btn-secondary px-10'
              onClick={handleChange}
              type='button'
            >
              Select
            </button>
          )}
        </div>
      </div>
    </div>
  )
}
export default AddressListItem
