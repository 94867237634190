const prodBaseURL = 'https://api.corksy.io'
const devBaseURL = 'http://localhost:8000'
const stagBaseURL = '/admin-api'
const demoBaseURL = '/admin-api'
const devOtlp = {
  trace: 'http://localhost:4318/v1/traces',
}
const prodOtlp = {
  trace: 'https://otel.corksy.io/api/v1/traces',
}

function configUrl() {
  const host = window.location.href
  const config: Props = {
    API_URL: '',
    ENVIRONMENT: '',
    OTLP: {
      trace: '',
    },
    TEMPLATE_API_KEY: '',
  }
  if (host.includes('admin.corksy.io')) {
    config.API_URL = prodBaseURL
    config.ENVIRONMENT = 'production'
    config.OTLP = prodOtlp
    config.TEMPLATE_API_KEY = '3w6fz23b1hhbpke8mrzjwgmhkp9hrzhhuuxk0ugc3uduc9oe'
  } else if (host.includes('localhost') || host.includes('127.0.0.1')) {
    config.API_URL = devBaseURL
    config.ENVIRONMENT = 'dev'
    config.OTLP = devOtlp
    config.TEMPLATE_API_KEY = 'xbjw6lkbed8vbblneu8m69ajuocq26lz19p9jhp0e5g043yr'
  } else if (host.includes('demo.corksy.io') || host.includes('staging.corksy.io')) {
    config.API_URL = demoBaseURL
    config.OTLP = prodOtlp
    config.ENVIRONMENT = host.includes('staging.corksy.io') ? 'staging' : 'demo'    
    config.TEMPLATE_API_KEY = host.includes('staging.corksy.io') ? '1fe0kn52qbv0bl0ahmg9cv3s19m2suo3e2748dj6nvipd9kf' : 'marfhewuxcz7ryq23hu7m5tb5tuv602jjf7rguk1pq7b2h5t'
  } else {
    config.API_URL = stagBaseURL
    config.ENVIRONMENT = 'staging'
    config.TEMPLATE_API_KEY = '1fe0kn52qbv0bl0ahmg9cv3s19m2suo3e2748dj6nvipd9kf'
  }

  return config
}

type Otlp = {
  trace: string
}

type Props = {
  API_URL: string
  ENVIRONMENT: string
  OTLP: Otlp
  TEMPLATE_API_KEY: string
}

const config: Props = configUrl();
(window as any).TEMPLATE_API_KEY = config.TEMPLATE_API_KEY;

export default config
