import {gql} from '@apollo/client'

export const GET_ORDERS = gql`
  query SearchOrders(
    $name: String
    $orderBy: String
    $pageSize: Int!
    $offset: Int!
    $financialStatus: FinancialStatusTypes
    $fulfillmentStatus: FulfillmentStatusTypes
    $orderNumber: BigInt
    $deliveryStatus: OrderDeliveryMethod
    $orderType: OrderTypes
    $stateId: UUID
    $zipCode: String
    $wineClubId: UUID
    $location: UUID
    $toDate: Datetime
    $totalWeight: BigFloat
    $fromDate: Datetime
    $bottles: Int
  ) {
    searchOrders(
      pCustomerName: $name
      pEmail: $name
      orderBy: $orderBy
      pOffset: $offset
      first: $pageSize
      pFinancialStatus: $financialStatus
      pFulfillmentStatus: $fulfillmentStatus
      pOrderNumber: $orderNumber
      pDeliveryStatus: $deliveryStatus
      pOrderType: $orderType
      pStateId: $stateId
      pZipCode: $zipCode
      pWineClubId: $wineClubId
      pLocationId: $location
      pToDate: $toDate
      pTotalWeight: $totalWeight
      pFromDate: $fromDate
      pBottles: $bottles
    ) {
      nodes {
        id
        customerName
        createdAt
        financialStatus
        fulfillmentStatus
        itemCount
        orderDeliveryMethod
        orderNumber
        status
        totalPrice
        orderType
        totalWeight
        packageOrderStatus
        message
        invoiceNumber
        totalCount
      }
    }
  }
`

export const GET_ORDERS_TOTAL_COUNT = gql`
  query SearchOrdersTotalCount(
    $name: String
    $financialStatus: FinancialStatusTypes
    $fulfillmentStatus: FulfillmentStatusTypes
    $orderNumber: BigInt
    $deliveryStatus: OrderDeliveryMethod
    $stateId: UUID
    $zipCode: String
    $wineClubId: UUID
    $location: UUID
    $toDate: Datetime
    $totalWeight: BigFloat
    $fromDate: Datetime
    $bottles: Int
    $orderType: OrderTypes
  ) {
    searchOrdersTotalCount(
      pCustomerName: $name
      pEmail: $name
      pFinancialStatus: $financialStatus
      pFulfillmentStatus: $fulfillmentStatus
      pOrderNumber: $orderNumber
      pDeliveryStatus: $deliveryStatus
      pStateId: $stateId
      pZipCode: $zipCode
      pWineClubId: $wineClubId
      pLocationId: $location
      pToDate: $toDate
      pTotalWeight: $totalWeight
      pFromDate: $fromDate
      pBottles: $bottles
      pOrderType: $orderType
    )
  }
`

export const GET_UNFULFILLED_ORDERS = gql`
  query GetOrders(
    $filter: FulfillOrderFilter
    $orderType: OrderTypes
    $orderNumber: BigInt
    $deliveryStatus: OrderDeliveryMethod
    $pCustomerName: String
    $orderBy: String
    $offset: Int!
    $pageSize: Int
    $wineClubPackageId: UUID
  ) {
    searchOrdersToFulfill(
      filter: $filter
      pOrderType: $orderType
      pOrderNumber: $orderNumber
      pOrderDeliveryMethod: $deliveryStatus
      pCustomerName: $pCustomerName
      orderBy: $orderBy
      offset: $offset
      first: $pageSize
      pWineClubPackageId: $wineClubPackageId
    ) {
      nodes {
        id
        createdAt
        bottles
        city
        customerName
        orderDeliveryMethod
        orderNumber
        orderType
        shippingAddress {
          addressLine1
          addressLine2
          city
          code
          firstName
          lastName
          stateId
          stateName
          zipCode
        }
        stateId
        totalPrice
        totalWeight
        zipCode
      }
      totalCount
    }
  }
`

export const GET_UNFULFILLED_ORDERS_ID = gql`
  query GetOrders(
    $filter: FulfillOrderFilter
    $orderType: OrderTypes
    $orderNumber: BigInt
    $deliveryStatus: OrderDeliveryMethod
    $pCustomerName: String
    $orderBy: String
    $offset: Int!
    $pageSize: Int!
  ) {
    searchOrdersToFulfill(
      filter: $filter
      pOrderType: $orderType
      pOrderNumber: $orderNumber
      pOrderDeliveryMethod: $deliveryStatus
      pCustomerName: $pCustomerName
      orderBy: $orderBy
      offset: $offset
      first: $pageSize
    ) {
      nodes {
        id
      }
    }
  }
`

export const FIND_ORDER = gql`
  query GetOrder($id: UUID!) {
    order(id: $id) {
      id
      name
      shippingAddress {
        state {
          code
        }
        country {
          code
        }
        zipCode
      }
      orderItems {
        nodes {
          id
          quantity
          variant {
            weight {
              value
              units
            }
          }
        }
      }
    }
  }
`
export const GET_ORDER_STATES = gql`
  query GetOrderStates($orderType: OrderTypes, $wineClubPackageId: UUID) {
    orderStates(
      pOrderType: $orderType
      pOrderDeliveryMethod: SHIP
      pWineClubPackageId: $wineClubPackageId
    ) {
      nodes {
        id
        name
      }
      totalCount
    }
  }
`

export const GET_ORDER_ZIP_CODES = gql`
  query GetOrderZipCodes($orderType: OrderTypes, $stateId: UUID!, $wineClubPackageId: UUID) {
    orderZipCodes(
      pOrderType: $orderType
      pStateId: $stateId
      pOrderDeliveryMethod: SHIP
      pWineClubPackageId: $wineClubPackageId
    ) {
      nodes
      totalCount
    }
  }
`

export const GET_ORDER_BOTTLES = gql`
  query GetOrderBottles(
    $orderType: OrderTypes
    $zipCode: String
    $stateId: UUID
    $wineClubPackageId: UUID
  ) {
    orderBottles(
      pOrderType: $orderType
      pZipCode: $zipCode
      pStateId: $stateId
      pOrderDeliveryMethod: SHIP
      pWineClubPackageId: $wineClubPackageId
    ) {
      nodes
      totalCount
    }
  }
`

export const GET_ORDER_WEIGHTS = gql`
  query GetOrderWeights(
    $orderType: OrderTypes
    $zipCode: String
    $bottles: Int!
    $stateId: UUID
    $wineClubPackageId: UUID
  ) {
    orderWeights(
      pOrderType: $orderType
      pZipCode: $zipCode
      pBottles: $bottles
      pStateId: $stateId
      pOrderDeliveryMethod: SHIP
      pWineClubPackageId: $wineClubPackageId
    ) {
      nodes
      totalCount
    }
  }
`

export const UPDATE_ORDER = gql`
  mutation UpdateOrder($id: UUID!, $patch: OrderPatch!) {
    updateOrder(input: {id: $id, patch: $patch}) {
      clientMutationId
    }
  }
`
