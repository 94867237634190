import apolloClient from 'apollo-client'
import {
  GET_ORDER_ALL_STATES,
  GET_ORDER_ALL_ZIP_CODES,
  GET_ORDER_ALL_BOTTLES,
  GET_ORDER_ALL_WEIGHTS,
  GET_WINE_CLUB,
  GET_LOCATIONS,
} from './graphql'

class OrderLookupsService {
  async getOrderStates(
    orderType: string,
    financialStatus: string,
    fulfillmentStatus: string,
    deliveryStatus: string
  ) {
    const {data} = await apolloClient.query({
      query: GET_ORDER_ALL_STATES,
      variables: {
        orderType,
        financialStatus,
        fulfillmentStatus,
        deliveryStatus,
      },
    })
    return data.orderStatesAll.nodes
  }
  async getOrderZipCodes(
    orderType: string,
    financialStatus: string,
    fulfillmentStatus: string,
    deliveryStatus: string,
    stateId: string
  ) {
    const {data} = await apolloClient.query({
      query: GET_ORDER_ALL_ZIP_CODES,
      variables: {
        orderType,
        financialStatus,
        fulfillmentStatus,
        deliveryStatus,
        stateId,
      },
    })

    const orderZipCodes = data.orderZipCodesAll.nodes.map((code: any) => {
      return {id: code}
    })
    return orderZipCodes
  }

  async getOrderBottles(
    orderType: string,
    financialStatus: string,
    fulfillmentStatus: string,
    deliveryStatus: string,
    zipCode: string,
    stateId: string
  ) {
    const {data} = await apolloClient.query({
      query: GET_ORDER_ALL_BOTTLES,
      variables: {
        orderType,
        financialStatus,
        fulfillmentStatus,
        deliveryStatus,
        zipCode,
        stateId,
      },
    })

    const orderBottles = data.orderBottlesAll.nodes.map((bottle: any) => {
      return {id: bottle}
    })
    return orderBottles
  }

  async getWineClub() {
    const {data} = await apolloClient.query({
      query: GET_WINE_CLUB,
    })
    return data.wineClubs.nodes
  }

  async getOrderWeights(
    orderType: string,
    financialStatus: string,
    fulfillmentStatus: string,
    deliveryStatus: string,
    zipCode: string,
    bottles: number,
    stateId: string
  ) {
    const {data} = await apolloClient.query({
      query: GET_ORDER_ALL_WEIGHTS,
      variables: {
        orderType,
        financialStatus,
        fulfillmentStatus,
        deliveryStatus,
        zipCode,
        bottles,
        stateId,
      },
    })

    const orderWeights = data.orderWeightsAll.nodes.map((weight: any) => {
      return {id: weight}
    })
    return orderWeights
  }

  async locationList() {
    const {data} = await apolloClient.query({
      query: GET_LOCATIONS,
    })
    return data.locations.nodes
  }
}

const ordersService = new OrderLookupsService()
export default ordersService
