import {forwardRef, useState} from 'react'
import {useParams} from 'react-router-dom'
import CustomerOverview from './components/CustomerOverview'
import CustomerAddress from './components/CustomerAddress'
import PaymentMethods from './components/CustomerPayments/list/PaymentMethods'
import AddressList from './components/CustomerAddress/list/AddressList'
import CustomerTag from './components/CustomerTag/CustomerTag'
import PaymentForm from './PaymentForm'
import EnableCustomerInformation from './EnableCustomerInformation'

type Props = {
  error: any
  isCreate: any
}

const CustomerInformation = forwardRef(({error, isCreate}: Props, ref) => {
  const routerParams: any = useParams()
  const [showDialog, setShowDialog] = useState(false)

  const handleCloseTag = () => {
    setShowDialog(false)
  }

  const handleTagDialogOpen = (value: boolean) => {
    setShowDialog(value)
  }

  if (showDialog && isCreate) {
    return <CustomerTag show={showDialog} onClose={handleCloseTag} />
  }

  return (
    <>
      <div className='row gy-5 gx-xl-8'>
        <div className='col-xl-12'>
          <CustomerOverview onTagDialogOpen={handleTagDialogOpen} />

          {routerParams.id && !isCreate ? (
            <>
              <AddressList />
              <PaymentMethods />
            </>
          ) : (
            <EnableCustomerInformation isCreate={isCreate}>
              <CustomerAddress isShowPhoneField={true} />
              <PaymentForm ref={ref} />
            </EnableCustomerInformation>
          )}
        </div>
      </div>
      {error && (
        <div className='fv-plugins-message-container invalid-feedback mb-5'>
          <i className='bi bi-exclamation-circle-fill text-danger me-2'></i>
          <span role='alert'>{error}</span>
        </div>
      )}
      {showDialog && <CustomerTag show={showDialog} onClose={handleCloseTag} />}
    </>
  )
})

export default CustomerInformation
