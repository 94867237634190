//@ts-nocheck
import {FC, useRef, useEffect, useState} from 'react'
import {shallowEqual, useSelector, connect, useDispatch, ConnectedProps} from 'react-redux'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import * as auth from './AuthRedux'
import {getUserByToken, getAccessToken, signInWithToken, adminLogin} from './AuthCRUD'
import {RootState} from '../../../../setup'
import {useParams} from 'react-router-dom'
import {getStoreSetup} from 'app/redux/StoreSetupSlice'

const mapState = (state: RootState) => ({auth: state.auth})
const connector = connect(mapState, auth.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const AuthInit: FC<PropsFromRedux> = (props) => {
  const didRequest = useRef(false)
  const {uid}: any = useParams()
  const dispatch = useDispatch()
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual)

  // We should request user by authToken before rendering the application
  useEffect(() => {
    const loginAdmin = async () => {
      try {
        const response = await adminLogin(uid)
        const {
          data: {accessToken},
        } = response
        dispatch(auth.actions.login(accessToken, false))
      } catch (error) {
        dispatch(auth.actions.logout())
        setShowSplashScreen(false)
      }
    }
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const user = await getUserByToken()
          dispatch(props.fulfillUser(user))
          dispatch(getStoreSetup())
        }
      } catch (error) {
        if (!didRequest.current) {
          dispatch(props.logout())
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    const loginWithToken = async () => {
      try {
        const response = await signInWithToken()
        const {
          data: {accessToken},
        } = response
        auth.actions.login(accessToken, true)
        requestUser()
      } catch (error) {
        dispatch(props.logout())
      }
    }
    if (uid) {
      loginAdmin()
    } else if (accessToken) {
      requestUser()
    } else if (getAccessToken()) {
      loginWithToken()
    } else {
      dispatch(props.logout())
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  if (showSplashScreen) {
    return <LayoutSplashScreen />
  }
  return <>{props.children} </>
}

export default connector(AuthInit)
