import * as Yup from 'yup'
import {useHistory, useParams} from 'react-router-dom'
import {FormikProvider, useFormik} from 'formik'
import {Button} from '_core'
import {TextField} from '_core/forms'
import authService from 'app/services/AuthService'
import {showMessage} from 'app/redux/messageSlice'
import {useDispatch} from 'react-redux'

const initialValues = {
  password: '',
  confirmPassword: '',
}

const errorMessages: any = {
  invalid: 'This link is not valid',
  expired: 'This link is expired',
}
const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Please Enter Your Password'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Password must match')
    .required('Please Enter Your Confirm Password'),
})

export function ResetPassword() {
  const dispatch = useDispatch()
  const {token}: any = useParams()

  const history = useHistory()
  const formik = useFormik({
    initialValues,
    validationSchema: resetPasswordSchema,
    onSubmit: handleSubmit,
  })

  async function handleSubmit(values: any, {setSubmitting}: any) {
    try {
      setSubmitting(true)
      const data = await authService.resetPassword({
        password: values.password,
        token,
      })

      if (data.success) {
        formik.resetForm()
        history.push('/auth/login')
      } else {
        formik.setErrors(errorMessages[data.message])
      }
    } catch (error) {
      console.error(error)
      dispatch(showMessage({text: 'An error occurred while saving the changes', variant: 'error'}))
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <>
      <FormikProvider value={formik}>
        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_password_reset_form'
          onSubmit={formik.handleSubmit}
        >
          <div className='text-center mb-10'>
            <h1 className='text-dark mb-3'>Reset Password ?</h1>
          </div>

          <div className='fv-row mb-10'>
            <label className='form-label fw-bolder text-gray-900 fs-6 required'>Password</label>
            <TextField
              id='txtPassword'
              type='password'
              placeholder='Password'
              autoComplete='off'
              name='password'
            />
          </div>
          <div className='fv-row mb-10'>
            <label className='form-label fw-bolder text-gray-900 fs-6 required'>
              Confirm password
            </label>
            <TextField
              id='txtConfirmPassword'
              type='password'
              placeholder='Confirm password'
              autoComplete='off'
              name='confirmPassword'
            />
          </div>

          <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
            <Button
              id='btnSubmit'
              className='btn btn-lg btn-primary fw-bolder me-4'
              type='submit'
              title='Submit'
              isSubmitting={formik.isSubmitting}
              isValid={formik.isValid}
            />
          </div>
        </form>
      </FormikProvider>
    </>
  )
}
