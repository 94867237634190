import {RootState} from './../../setup/redux/RootReducer'
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import StoreSetupService from 'app/services/StoreSetupService'

export const getStoreSetup = createAsyncThunk('store/setup', async (_, {dispatch}) => {
  try {
    dispatch(setFetching(true))
    const storeSetup = await StoreSetupService.getStoreSetup()
    return storeSetup
  } catch (err) {
    console.error(err)
  } finally {
    dispatch(setFetching(false))
  }
})

const storeSetupSlice = createSlice({
  name: 'storeSetup',
  initialState: {
    setup: {},
    isFetching: false,
  },
  reducers: {
    setFetching: (state, action) => {
      state.isFetching = action.payload
    },
  },
  extraReducers: {
    [getStoreSetup.fulfilled.toString()]: (state, action) => {
      return action.payload
    },
  },
})

export const {setFetching} = storeSetupSlice.actions

export default storeSetupSlice.reducer

export const selectIsFetching = ({misc}: RootState): boolean => misc.storeSetup.isFetching

export const selectStoreSetup = ({misc}: RootState): any => misc.storeSetup.setup
