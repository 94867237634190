import {gql} from '@apollo/client'

export const GET_CUSTOMER_SUBSCRIPTION = gql`
  query GetCustomerSubscriptions(
    $customerId: UUID!
    $orderBy: String
    $offset: Int!
    $pageSize: Int!
  ) {
    searchSubscriptions(
      offset: $offset
      orderBy: $orderBy
      first: $pageSize
      filter: {wineClubId: {isNull: true}, customerId: {equalTo: $customerId}}
    ) {
      nodes {
        id
        createdAt
        frequency
        bottles
        customer {
          firstName
          lastName
        }
        subscriptionOrderSchedules {
          nodes {
            lastOrderDate
            nextOrderDate
          }
        }
        subscriptionProducts {
          nodes {
            variant {
              name
              product {
                name
                imageSrc
                id
              }
            }
          }
        }
      }
      totalCount
    }
  }
`
