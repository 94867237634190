import {FC, useState} from 'react'
import {capitalize} from 'lodash'
import {KTSVG} from '_metronic/helpers/components/KTSVG'
import {Tooltip} from 'react-bootstrap-v5'
import clsx from 'clsx'
import {Button, OverlayTrigger} from '_core'
import MessagePopover from '_core/components/MessagePopover'
import {paymentLinkedMessage, paymentLinkedMessageDelete} from 'app/constant/address'

type Props = {
  address: any
  onDeletePopUp: any
  onUpdatePopUp: any
  onSelect: (id: any) => void
}
const AddressListItem: FC<Props> = ({address, onDeletePopUp, onUpdatePopUp, onSelect}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const handleDeleteAddress = () => {
    onDeletePopUp(address.id)
  }

  const handleUpdateAddress = () => {
    onUpdatePopUp(address.id)
  }

  const handleSelect = async () => {
    try {
      setIsSubmitting(true)
      await onSelect(address.id)
    } finally {
      setIsSubmitting(false)
    }
  }

  const editBtn = (
    <button
      type='button'
      className='btn btn-transparent btn-sm m-0 p-0 me-2 btn-active-text-primary text-decoration-underline bg-white'
      onClick={handleUpdateAddress}
      disabled={address.isLinkedWithPayment}
    >
      Edit
    </button>
  )

  const deleteBtn = (
    <button
      type='button'
      className='btn btn-transparent btn-sm m-0 p-0 me-2 btn-active-text-primary text-decoration-underline bg-white'
      onClick={handleDeleteAddress}
      disabled={address.isLinkedWithPayment}
    >
      Delete
    </button>
  )

  return (
    <>
      <div className='col-xxl-4 pb-6 col-lg-6 col-md-6'>
        <div className={clsx('card card-dashed flex-row flex-stack flex-wrap p-6 ')}>
          <div className='d-flex flex-column py-2 ms-7'>
            <div className=' d-flex  mb-5'>
              <span className='fw-bold text-start row'>
                <span className='text-dark fw-bolder fs-4 d-flex justify-content-between'>
                  <span className='col-8 text-wrap'>
                    {`${capitalize(address.firstName)}
                  ${capitalize(address.lastName)}`}
                    {address.isPrimary && (
                      <span className={`badge badge badge-light-success ms-2`}>Primary</span>
                    )}
                  </span>
                  <div>
                    {address.isBillingAddress && (
                      <OverlayTrigger
                        overlay={<Tooltip id='tooltip-disabled'>Billing Address</Tooltip>}
                      >
                        <span className='d-inline-block me-2'>
                          <KTSVG
                            path='/media/icons/duotune/ecommerce/ecm001.svg'
                            className='svg-icon-muted svg-icon-2x'
                          />
                        </span>
                      </OverlayTrigger>
                    )}
                    {address.isShippingAddress && (
                      <OverlayTrigger
                        overlay={<Tooltip id='tooltip-disabled'>Shipping Address</Tooltip>}
                      >
                        <span className='d-inline-block pt-1'>
                          <KTSVG
                            path='/media/icons/duotune/ecommerce/ecm006.svg'
                            className='svg-icon-muted svg-icon-2x pt-1'
                          />
                        </span>
                      </OverlayTrigger>
                    )}
                  </div>
                </span>
                <span className='text-muted fw-bold fs-7'>{address.addressLine1}</span>
                <span className='text-muted fw-bold fs-7'>
                  {address.addressLine2
                    ? `${address.addressLine2},${address.city}, `
                    : `${address.city}, `}
                  {address.zipCode}
                </span>
                <span className='text-muted fw-bold fs-7'>{address.state.name}</span>
              </span>
            </div>
            <div className='d-flex justify-content-start gap-1 align-items-center'>
              {address.isLinkedWithPayment ? (
                <MessagePopover message={paymentLinkedMessage} status={editBtn} />
              ) : (
                <>{editBtn}</>
              )}
              <div className='me-2'>|</div>
              {address.isLinkedWithPayment ? (
                <MessagePopover message={paymentLinkedMessageDelete} status={deleteBtn} />
              ) : (
                <>{deleteBtn}</>
              )}
              {!address.isPrimary && (
                <>
                  <div className='me-2'>|</div>
                  <Button
                    id='btnAddressPrimary'
                    type='button'
                    isSubmitting={isSubmitting}
                    className='btn btn-sm btn-transparent m-0 p-0 text-decoration-underline bg-white'
                    onClick={handleSelect}
                    title='Set as primary'
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default AddressListItem
