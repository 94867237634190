import {FC, useMemo} from 'react'
import Card from '_core/components/Card'
import InvoiceListItem from './InvoiceListItem'
import sumBy from 'lodash/sumBy'
import {simpleCurrency} from 'app/format/currency'
import {useSelector} from 'react-redux'
import {RootState} from 'setup'
import {selectSubscriptionInvoices} from 'app/redux/StorePlanSlice'
import {Link} from 'react-router-dom'
import PaymentMethods from '../PaymentMethods'

type Props = {}
const InvoiceList: FC<Props> = () => {
  const invoices = useSelector<RootState, Array<any>>(selectSubscriptionInvoices)

  const totalPrice = useMemo(() => {
    return sumBy(invoices, (invoice: any) => {
      if (invoice.total) {
        return parseFloat(invoice.total)
      }
      return 0
    })
  }, [invoices])

  return (
    <>
      <p className='text-center text-dark'>
        To avoid further interruption, please pay your pending invoices.
      </p>
      <Card
        title='Pending Invoices'
        className='mb-6'
        toolbar={
          <Link id='lin' to='/settings/billing/all' replace>
            View billings
          </Link>
        }
      >
        <div className='table-responsive w-100'>
          <table
            id='tableUnFulfilled'
            className='table table-row-dashed table-row-gray-300 align-middle gs-1'
          >
            <thead className='bg-transparent border-0'>
              <tr className='fw-bolder text-muted'>
                <th className='text-start'>Invoice Number</th>
                <th>Date</th>
                <th>Time Period</th>
                <th>Status</th>
                <th>SubTotal</th>
                <th>Fee</th>
                <th>Tax</th>
                <th>Total Application Fee</th>
                <th className='col-2 text-end'>Total</th>
              </tr>
            </thead>
            <tbody>
              {invoices &&
                invoices.map((invoice) => {
                  return <InvoiceListItem invoice={invoice} key={invoice.id} />
                })}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={12}>
                  <div className='float-end'>{simpleCurrency(totalPrice)}</div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </Card>
      <PaymentMethods />
    </>
  )
}

export default InvoiceList
