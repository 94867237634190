import {Redirect, Route, Switch} from 'react-router-dom'
import {shallowEqual, useSelector} from 'react-redux'
import ContactDetails from './components/ContactDetails'
import StoreDetails from './components/StoreDetails'
import {RootState} from 'setup'
import PaymentDetails from './components/PaymentDetails'
import {toAbsoluteUrl} from '_metronic/helpers'

function StorePage() {
  const isAuthorized = useSelector<RootState>(({auth}) => auth.user, shallowEqual)

  return (
    <div className='d-flex justify-content-center w-100 align-items-center vh-100 free-trail-bg'>
      <Switch>
        {isAuthorized ? (
          <Redirect from='/store' to='/' />
        ) : (
          <>
            <Route path='/store/create' exact={true} component={ContactDetails} />
            <Route path='/store/create/:token' exact={true} component={ContactDetails} />
            <Route path='/store/details'>
              <PaymentPage />
            </Route>
          </>
        )}
      </Switch>
    </div>
  )
}

export default StorePage

const PaymentPage = () => {
  return (
    <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        <span className='mb-12'>
          <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo.svg')} className='h-45px' />
        </span>
        <Switch>
          <Route path='/store/details' exact={true} component={StoreDetails} />
          <Route path='/store/details/payment' exact={true} component={PaymentDetails} />
          <Redirect to='/store/details' />
        </Switch>
      </div>
    </div>
  )
}
