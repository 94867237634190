import {FC} from 'react'
import {useSelector} from 'react-redux'
import {RootState} from 'setup'
import {map} from 'lodash'
import PaymentListItem from 'app/features/plans/components/payment/list/PaymentListItems'
import AddPaymentMethod from './AddPaymentMethod'

type Props = {
  onSubmit: (values: any) => void
  onSelectPaymentMethod: (is: string) => void

  paymentMethodId: string | any
}

const PaymentList: FC<Props> = ({onSubmit, onSelectPaymentMethod, paymentMethodId}) => {
  const storePaymentsList = useSelector<RootState>(
    ({misc}) => misc.storePlan.storePaymentsList
  ) as any[]

  return (
    <div className='row px-5 pb-7'>
      {map(storePaymentsList, (account: any) => (
        <PaymentListItem
          key={account.id}
          payment={account}
          accountId={paymentMethodId}
          onHandleChange={onSelectPaymentMethod}
        />
      ))}
      <AddPaymentMethod onSubmit={onSubmit} />
    </div>
  )
}
export default PaymentList
