import Service from '../Service'

class AuthService extends Service {
  constructor() {
    super('/users/v1')
  }
  async resetPassword(password: any) {
    return this.post(password, '/auth/resetpassword')
  }
}

const authService = new AuthService()
export default authService
