import {
  CompositePropagator,
  W3CBaggagePropagator,
  W3CTraceContextPropagator,
} from '@opentelemetry/core'
import {WebTracerProvider, BatchSpanProcessor} from '@opentelemetry/sdk-trace-web'
import {registerInstrumentations} from '@opentelemetry/instrumentation'
import {getWebAutoInstrumentations} from '@opentelemetry/auto-instrumentations-web'
import {Resource, detectResourcesSync, browserDetector} from '@opentelemetry/resources'
import {SemanticResourceAttributes} from '@opentelemetry/semantic-conventions'
import {OTLPTraceExporter} from '@opentelemetry/exporter-trace-otlp-http'
import {FetchInstrumentation} from '@opentelemetry/instrumentation-fetch'
import config from '../config'
import {SessionIdProcessor} from './SessionIdProcessor'

const FrontendTracer = async (collectorString: string) => {
  const {ZoneContextManager} = await import('@opentelemetry/context-zone')

  let resource = new Resource({
    [SemanticResourceAttributes.SERVICE_NAME]: `corksy-${config.ENVIRONMENT}-frontend`,
  })

  const detectedResources = await detectResourcesSync({detectors: [browserDetector]})
  resource = resource.merge(detectedResources)
  const provider = new WebTracerProvider({
    resource,
  })

  provider.addSpanProcessor(new SessionIdProcessor())
  provider.addSpanProcessor(
    new BatchSpanProcessor(
      new OTLPTraceExporter({
        url: config.OTLP.trace,
      })
    )
  )

  const contextManager = new ZoneContextManager()

  provider.register({
    contextManager,
    propagator: new CompositePropagator({
      propagators: [new W3CBaggagePropagator(), new W3CTraceContextPropagator()],
    }),
  })

  registerInstrumentations({
    tracerProvider: provider,
    instrumentations: [
      new FetchInstrumentation({
        ignoreUrls: [/localhost:8090\/sockjs-node/],
        propagateTraceHeaderCorsUrls: ['http://localhost:8000', 'http://localhost:8080/graphql'],
        clearTimingResources: true,
      }),
      getWebAutoInstrumentations({
        '@opentelemetry/instrumentation-fetch': {
          propagateTraceHeaderCorsUrls: /.*/,
          clearTimingResources: true,
          applyCustomAttributesOnSpan(span) {
            span.setAttribute('app.synthetic_request', 'false')
          },
        },
      }),
    ],
  })
}

export default FrontendTracer
