import {mapToShippingRate} from 'app/utilities'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'

export const mapToWineClubOrderModel = (order: any) => {
  const {orderItems, wineClubOrder, ...rest} = order
  const {customerWineClubPackage} = wineClubOrder
  const data = {
    ...rest,
    ...customerWineClubPackage,
  }
  return {data, orderItems: mapToOrderItemsModel(orderItems.nodes)}
}

const mapToOrderItemsModel = (items: []) => {
  return items.map((item: any) => {
    const {variant, ...rest} = item
    const {product, weight} = variant
    return {
      ...rest,
      ...variant,
      product,
      weight,
    }
  })
}

export const mapToUpdateWineClubOrderPackage = (
  order: any,
  deleteItems: any[],
  items: any[],
  shippingRate: any
) => {
  const orders: any = {
    id: order.id,
    shippingAddressId: order.shippingAddressId,
    shippingPrice: order.shippingPrice,
    totalTax: order.totalTax,
    paymentMethodId: order.paymentMethodId,
    totalPrice: order.totalPrice,
    totalDiscounts: order.totalDiscounts,
    subtotalPrice: order.subtotalPrice,
    shippingRateId: order.shippingRateId,
    billingAddressId: order.billingAddressId,
    wineClubDiscounts: order.wineClubDiscounts,
  }
  const deleteItemIds = map(deleteItems, 'id')
  const orderItems = mapToOrderItems(items)
  if (shippingRate) {
    const shippingMethod = mapToShippingRate(shippingRate)
    return {orders, shippingMethod, deleteItemIds, orderItems}
  }
  return {orders, deleteItemIds, orderItems}
}

const mapToOrderItems = (items: any) => {
  return items.map((item: any) => {
    return {
      id: item.id ? item.id : null,
      variantId: item.variantId,
      quantity: parseInt(item.quantity),
      productId: item.productId,
      price: item.discountPrice > 0 ? item.discountPrice : item.price,
    }
  })
}

export const mapToOrderSummary = (items: []) => {
  return items.map((item: any) => {
    const {variant, ...rest} = item
    const {product} = variant
    return {
      ...rest,
      productName: product.name,
      variantName: variant.name,
    }
  })
}

export const mapToPickupLocation = (locations: any[]) => {
  return (
    locations &&
    orderBy(locations, 'isDefault', 'desc').map((location) => {
      const {pickupLocation, ...rest} = location
      return {...rest, ...pickupLocation}
    })
  )
}
