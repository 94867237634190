import clsx from 'clsx'
import {FC, useEffect} from 'react'
import {Pagination} from 'react-bootstrap-v5'
import {useDispatch} from 'react-redux'
import {setCurrentPage, resetPaging} from 'app/redux/pagingSlice'
import {KTSVG} from '_metronic/helpers'

export type RecordProps = {
  hideText?: boolean
  recordsCount: number
}

type Props = {
  totalCount: number
  currentPage: any
  onPageChange: (currentPage: any) => void
  pageSize: number
  isDisableResetPaging?: boolean
  records?: RecordProps
  className?: string
  splitCount?: number
}

const PaginationList: FC<Props> = ({
  totalCount,
  currentPage,
  onPageChange,
  pageSize,
  isDisableResetPaging,
  records,
  className,
  splitCount = 2,
}) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (!isDisableResetPaging) {
      return () => {
        dispatch(resetPaging())
      }
    }
  }, [dispatch, isDisableResetPaging])

  const numberOfValues: any = []
  let pageCount: number = 0

  if (totalCount && pageSize) {
    pageCount = Math.ceil(totalCount / pageSize)
  }

  for (let i = 1; i <= pageCount; i++) {
    if (
      i === 1 ||
      i === pageCount ||
      (i <= currentPage + splitCount && i >= currentPage - splitCount)
    ) {
      numberOfValues.push(i)
    }
  }

  const handlePageChange = (pageIndex: any) => {
    if (onPageChange) {
      onPageChange(pageIndex)
    }
    dispatch(setCurrentPage(pageIndex))
  }

  // const handleLastPageChange = () => {
  //   if (onPageChange) {
  //     if (currentPage === numberOfValues.length) {
  //       onPageChange(currentPage)
  //       dispatch(setCurrentPage(currentPage))
  //     } else {
  //       onPageChange(pageCount)
  //       dispatch(setCurrentPage(pageCount))
  //     }
  //   }
  // }

  return (
    <RecordWithPaging
      records={records}
      pageSize={pageSize}
      totalCount={totalCount}
      currentPage={currentPage}
      className={className}
    >
      {totalCount > pageSize && (
        <Pagination
          className={clsx(
            'd-flex  align-items-center justify-content-between',
            records && 'col-auto',
            className
            //  !records.isNextLine
          )}
        >
          {/* <Pagination.First
            onClick={() => handlePageChange(1)}
            className={clsx(currentPage === 1 && 'disabled')}
          /> */}

          <Pagination.Prev
            id='page-nav'
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
          >
            <KTSVG
              path='/media/icons/duotune/arrows/arrow_left.svg'
              className={clsx(
                'svg-icon-2x',
                currentPage === 1 ? 'svg-icon-muted' : 'svg-icon-dark'
              )}
            />{' '}
            Previous
          </Pagination.Prev>
          <div className='d-flex'>
            {numberOfValues.map((value: any, index: number) => {
              return (
                <PaginationEllipsis
                  value={value}
                  index={index}
                  onPageChange={handlePageChange}
                  values={numberOfValues}
                >
                  <Pagination.Item
                    key={index}
                    onClick={() => handlePageChange(value)}
                    className={clsx(currentPage === value && 'active')}
                  >
                    {value}
                  </Pagination.Item>
                </PaginationEllipsis>
              )
            })}
          </div>
          <Pagination.Next
            id='page-nav'
            disabled={currentPage === pageCount}
            onClick={() => handlePageChange(currentPage + 1)}
          >
            Next{' '}
            <KTSVG
              path='/media/icons/duotune/arrows/arrow_right.svg'
              className={clsx(
                'svg-icon-2x',
                currentPage === pageCount ? 'svg-icon-muted' : 'svg-icon-dark'
              )}
            />{' '}
          </Pagination.Next>
          {/* <Pagination.Last
            onClick={handleLastPageChange}
            className={clsx(currentPage === pageCount && 'disabled')}
          /> */}
        </Pagination>
      )}
    </RecordWithPaging>
  )
}
export default PaginationList

type Props3 = {
  children: React.ReactNode
  records?: RecordProps
  pageSize: number
  totalCount: number
  currentPage: number
  className?: string
}

const RecordWithPaging: FC<Props3> = ({
  totalCount,
  records,
  pageSize,
  currentPage,
  children,
  className,
}) => {
  if (records && totalCount > 10 && pageSize >= 10) {
    return (
      <>
        {children}
        {records && (
          <div className='my-7'>
            <Limitation
              records={records}
              totalCount={totalCount}
              fromCount={(currentPage - 1) * pageSize}
              className={className}
            />
          </div>
        )}
      </>
    )
  }
  return <>{children}</>
}

type Props2 = {
  records: RecordProps | undefined
  totalCount: number
  className?: string
  fromCount: number
}

const Limitation: FC<Props2> = ({records, totalCount, fromCount, className}) => {
  return (
    <div className={clsx('', className)}>
      <span className='d-flex align-items-center'>
        {records && !records.hideText && (
          <span className='ps-3 text-muted'>
            Displaying {fromCount + 1} to {fromCount + records.recordsCount} of {totalCount} records
          </span>
        )}
      </span>
    </div>
  )
}

type Ellipsis = {
  children: React.ReactNode
  value: number
  index: number
  onPageChange: (value: any) => void
  values: any[]
}

const PaginationEllipsis: FC<Ellipsis> = ({children, value, index, onPageChange, values}) => {
  let isEnableEllipsis = false
  let ellipsisIndex = index
  if (values[index - 1] + 1 < value) {
    isEnableEllipsis = true
    ellipsisIndex = values[index - 1] + 1
  }
  const handleClick = () => {
    onPageChange(ellipsisIndex)
  }

  return (
    <>
      {isEnableEllipsis && (
        <Pagination.Ellipsis onClick={handleClick}>
          <KTSVG
            path='/media/icons/duotune/general/gen052.svg'
            className={clsx('svg-icon-3 svg-icon-dark')}
          />
        </Pagination.Ellipsis>
      )}
      {children}
    </>
  )
}
