import {createSlice} from '@reduxjs/toolkit'

export type LoaderState = {
  show: boolean
}

const initialState: LoaderState = {
  show: false,
}

const LoaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    showLoader: (state) => {
      state.show = true
    },
    hideLoader: (state) => {
      state.show = false
    },
    toggleLoader: (state, action) => {
      state.show = action.payload
    },
  },
})

export const {hideLoader, showLoader, toggleLoader} = LoaderSlice.actions

export default LoaderSlice.reducer
