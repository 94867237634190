import {useFormikContext} from 'formik'
import {FC, useState} from 'react'
import AutoSuggest from 'react-autosuggest'
import {useQuery} from '@apollo/client'
import googlePlaceService from 'app/services/GooglePlaceService'
import {findCountry, findState, GET_COUNTRY_STATES} from './helper'
import clsx from 'clsx'

type Props = {
  id?: string
  name: string
  title?: string
  className?: string
  fieldName?: string | any
  disabled?: boolean
}

const AddressSuggestion: FC<Props> = ({name, title, fieldName, className, ...props}) => {
  const [suggestions, setSuggestions] = useState<any>([])
  const {setFieldValue, getFieldMeta} = useFormikContext<any>()
  const {touched, error, value}: any = getFieldMeta(name)
  const {data} = useQuery(GET_COUNTRY_STATES)
  const {value: watchAddress}: any = getFieldMeta(fieldName)

  const getSuggestions = async (value: any) => {
    const inputValue = value.trim().toLowerCase()
    const inputLength = inputValue.length
    const suggestions = await googlePlaceService.suggest(value)
    return inputLength === 0 ? [] : suggestions
  }

  const handleChange = (value: any) => {
    setFieldValue(name, value)
  }

  const handleSelect = async (suggestion: any) => {
    const place = await googlePlaceService.findPlace(suggestion.value)
    if (place.status === 'OK') {
      const {result} = place
      let stateCode, countryCode
      const address = {
        ...watchAddress,
        zipCodeSuffix: '',
      }
      if (result.address_components) {
        result.address_components.forEach((addressComponent: any) => {
          const {types, long_name, short_name} = addressComponent

          if (types.includes('street_number')) {
            address.addressLine1 = long_name
          } else if (types.includes('route')) {
            address.addressLine1 = `${address.addressLine1} ${long_name}`
            address.addressLine2 = ''
          } else if (types.includes('locality')) {
            address.city = long_name
          } else if (types.includes('administrative_area_level_1')) {
            stateCode = short_name
          } else if (types.includes('country')) {
            countryCode = short_name
          } else if (types.includes('postal_code')) {
            address.zipCode = long_name.replace(/\W/g, '')
          } else if (types.includes('postal_code_suffix')) {
            address.zipCodeSuffix = long_name
          }
        })

        const country = findCountry(data, countryCode)
        if (country) {
          address.countryId = country.id
          const state = findState(data, stateCode)
          if (state) {
            address.stateId = state.id
          }
        }
        if (address) {
          Object.entries(address).forEach(([name, value]) =>
            setFieldValue(`${fieldName}.${name}`, value)
          )
        }
      }
      return address.addressLine1
    }

    return suggestion.label
  }

  const handleFetched = async (suggestions: any) => {
    setFieldValue(name, suggestions)
    const data = await getSuggestions(suggestions)
    if (data.length) {
      return Promise.all(data).then((values) => {
        return setSuggestions(values)
      })
    }
  }

  return (
    <div className='mb-5'>
      {title && <label className='form-label mb-5'>{title}</label>}
      <AutoSuggest
        suggestions={suggestions}
        onSuggestionsClearRequested={() => setSuggestions([])}
        onSuggestionsFetchRequested={({value}) => handleFetched(value)}
        onSuggestionSelected={(_, {suggestion}) => handleSelect(suggestion)}
        getSuggestionValue={(suggestion) => suggestion.label}
        renderSuggestion={(suggestion) => <span>{suggestion.label}</span>}
        inputProps={{
          placeholder: '',
          value: value || '',
          onChange: (_, {newValue}) => handleChange(newValue),
          className: clsx(
            'form-control w-100',
            className,
            {'is-invalid': touched && error},
            {
              'is-valid': touched && !error,
            }
          ),
          disabled: props.disabled,
        }}
      />
      {touched && error && (
        <div className='fv-plugins-message-container invalid-feedback'>
          <i className='bi bi-exclamation-circle-fill text-danger me-2'></i>
          <span role='alert'>{error}</span>
        </div>
      )}
    </div>
  )
}
export default AddressSuggestion
