import apolloClient from 'apollo-client'
import Service from '../Service'
import {
  GET_STORE_BASIC_INFO,
  GET_STORE,
  GET_STORE_DETAILS,
  CHECK_STORE_EMAIL_EXIST,
  GET_FEE_DETAILS,
} from './graphql'
import {removeSpecialChar} from 'app/utilities/media'

class StoreService extends Service {
  async checkEmailIsExist(email: string) {
    const result = await this.get(`/check/${email}/exists`)
    return result
  }

  async checkStoreNameAndDomainExists(storeName: string, domain: string) {
    const result = await this.get(`/check/${storeName}/domain/${domain}`)
    return result
  }

  async isEmailExist(email: string) {
    const result = await apolloClient.query({
      query: CHECK_STORE_EMAIL_EXIST,
      variables: {email},
    })
    return result.data.storeEmailExist
  }

  async create(store: any) {
    return this.post(store, '/register')
  }

  async fetch() {
    const {data} = await apolloClient.query({
      query: GET_STORE_DETAILS,
    })
    const {stores} = data
    return stores.nodes[0]
  }

  async getBasicInfo() {
    const {data} = await apolloClient.query({
      query: GET_STORE_BASIC_INFO,
    })
    const {shop} = data
    return {
      ...shop,
      country: shop.country.code,
    }
  }

  async store() {
    const {data} = await apolloClient.query({
      query: GET_STORE,
    })
    const {shop} = data
    return {
      ...shop,
      state: shop.state.code,
      country: shop.country.name,
    }
  }

  async getFeeDetails(token: string) {
    const {data} = await apolloClient.query({
      query: GET_FEE_DETAILS,
      variables: {token},
    })
    return data.accountCreationLinkRequests.nodes[0]
  }

  async uploadStoreLogo({imageBase64, filename}: any) {
    const name = removeSpecialChar(filename)
    return this.post(
      {
        imageBase64,
        filename: name,
      },
      '/image/upload'
    )
  }

  async getAccountCreationRequest(token: string) {
    try {
      return await this.get(`/account/creation/${token}`)
    } catch (err) {
      console.error(err)
    }
  }

  async config() {
    return await this.get('/payment/accounts/config')
  }
}

const storeService = new StoreService('/users/v1/public/store')
export default storeService
