import {CardNumberElement, CardCvcElement, CardExpiryElement} from '@stripe/react-stripe-js'
import clsx from 'clsx'
import {useFormikContext} from 'formik'

type Props = {}
const StripeCard: React.FC<Props> = () => {
  const {errors, setFieldValue, getFieldHelpers, touched}: any = useFormikContext()

  const setTouched = (name: string) => {
    const field = getFieldHelpers(name)
    field.setTouched(true, true)
  }

  const handleNumberChange = (event: any) => {
    setTouched('payment.number')
    if (event.complete) {
      setFieldValue('payment.number', '**** **** **** ****')
    } else {
      setFieldValue('payment.number', '')
    }
  }

  const handleExpiryChange = (event: any) => {
    setTouched('payment.expMonth')
    setTouched('payment.expYear')
    if (event.complete) {
      setFieldValue('payment.expMonth', '**')
      setFieldValue('payment.expYear', '**')
    } else {
      setFieldValue('payment.expMonth', '')
      setFieldValue('payment.expYear', '')
    }
  }

  const handleCvcChange = (event: any) => {
    setTouched('payment.cvv')
    if (event.complete) {
      setFieldValue('payment.cvv', '***')
    } else {
      setFieldValue('payment.cvv', '')
    }
  }

  const inputStyle = {
    style: {
      base: {
        fontFamily: 'system-ui',
        fontSize: '16px',
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': {
          color: '#fce883',
        },
      },
    },
  }

  return (
    <>
      <div className='mb-10'>
        <CardNumberElement
          id='cardNumber'
          className={clsx('form-control form-control-lg form-control-stripe', {
            'is-invalid': errors.cardNumber,
          })}
          onChange={handleNumberChange}
          options={inputStyle}
        />
        {touched && touched.payment?.number && <ErrorMessage error={errors?.payment?.number} />}
      </div>
      <div className='row mb-10'>
        <div className='col'>
          <CardExpiryElement
            id='cardExpiry'
            className={clsx('form-control form-control-lg form-control-stripe', {
              'is-invalid': errors.expiry,
            })}
            onChange={handleExpiryChange}
            options={inputStyle}
          />
          {touched && touched.payment?.expMonth && (
            <>
              {' '}
              {errors?.payment?.expMonth && (
                <ErrorMessage error='Please enter a card expiry date' />
              )}
            </>
          )}
        </div>
        <div className='col'>
          <CardCvcElement
            id='cardCvv'
            className={clsx('form-control form-control-lg form-control-stripe', {
              'is-invalid': errors.cvc,
            })}
            onChange={handleCvcChange}
            options={inputStyle}
          />
          {touched && touched.payment?.cvv && <ErrorMessage error={errors?.payment?.cvv} />}
        </div>
        <div className='col'></div>
      </div>
    </>
  )
}

export default StripeCard

export const ErrorMessage = ({error}: any) => {
  if (error) {
    return (
      <>
        <div className='fv-plugins-message-container invalid-feedback'>
          <i className='bi bi-exclamation-circle-fill text-danger me-2'></i>
          <span role='alert'>{error}</span>
        </div>
      </>
    )
  }
  return null
}
