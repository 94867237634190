import {FC} from 'react'
import {gql, useQuery} from '@apollo/client'
import {Select} from '_core/forms'
import {SimplePage} from '_core'

const GET_TIMEZONES = gql`
  query GetTimeZones {
    timeZones {
      nodes {
        id
        name
      }
    }
  }
`

type Props = {
  name: string
  onChange?: any
  className?: string
}

const TimeZoneLookup: FC<Props> = ({className, name, onChange}) => {
  const {data, loading, error} = useQuery(GET_TIMEZONES)

  return (
    <SimplePage loading={loading} error={error}>
      <div className={className}>
        <label className='form-label fs-6 fw-bold text-dark required'>Time Zone</label>
        <Select id='sltSelectTimeZone' name={name}>
          <option value=''>Select Time Zone</option>
          {data &&
            data.timeZones.nodes.map((tz: any) => (
              <option value={tz.id} key={tz.id}>
                {tz.name}
              </option>
            ))}
        </Select>
      </div>
    </SimplePage>
  )
}

export default TimeZoneLookup
