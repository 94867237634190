import apolloClient from 'apollo-client'
import {
  GET_PAYMENTS,
  CREATE_PAYMENT,
  CHECK_PAYMENT_METHOD,
  UPDATE_PAYMENT_METHOD,
  PAYMENT_METHOD_UPDATE,
} from './graphql'

class DraftOrderPaymentService {
  async create(model: any) {
    const {data, errors} = await apolloClient.mutate({
      mutation: CREATE_PAYMENT,
      variables: {
        model,
      },
    })

    if (errors) {
      throw errors
    }
    return data.createPaymentMethod2
  }

  async updatePaymentMethod(id: string, paymentMethod: any) {
    const {errors} = await apolloClient.mutate({
      mutation: UPDATE_PAYMENT_METHOD,
      variables: {id, patch: paymentMethod},
    })
    if (errors) {
      throw errors
    }
  }

  async updatePrimaryPaymentMethod(id: string) {
    const {errors} = await apolloClient.mutate({
      mutation: UPDATE_PAYMENT_METHOD,
      variables: {id, patch: {isPrimary: true}},
    })
    if (errors) {
      throw errors
    }
  }

  async paymentMethodUpdate(model: any) {
    const {data, errors} = await apolloClient.mutate({
      mutation: PAYMENT_METHOD_UPDATE,
      variables: {model},
    })

    if (errors) {
      throw errors
    }
    return data.paymentMethodUpdate
  }

  async getPaymentMethods(customerId: any) {
    const {data} = await apolloClient.query({
      query: GET_PAYMENTS,
      variables: {
        customerId,
      },
    })
    return data.paymentMethods.nodes
  }

  async validatePaymentMethod(customerId: string, paymentMethodId: string) {
    const {data} = await apolloClient.query({
      query: CHECK_PAYMENT_METHOD,
      variables: {
        customerId,
        paymentMethodId,
      },
    })
    return data.checkPaymentMethod
  }
}

const draftOrderPaymentService = new DraftOrderPaymentService()
export default draftOrderPaymentService
