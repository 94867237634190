import Service from '../Service'

class GooglePlaceService extends Service {
  constructor() {
    super('/users/v1/public/address')
  }

  async suggest(qry: any) {
    const response = await this.get(`/search/${qry}`)
    const data = JSON.parse(response)
    return data.predictions.map((prediction: any) => {
      return {
        value: prediction.place_id,
        label: prediction.description,
      }
    })
  }

  async findPlace(placeId: any) {
    const response = await this.get(`/place/${placeId}`)
    const data = JSON.parse(response)
    return data
  }
}

const googlePlaceService = new GooglePlaceService()
export default googlePlaceService
