import { gql } from '@apollo/client'

export const GET_ORDER_ALL_STATES = gql`
  query GetOrderAllStates(
    $deliveryStatus: OrderDeliveryMethod
    $financialStatus: FinancialStatusTypes
    $fulfillmentStatus: FulfillmentStatusTypes
    $orderType: OrderTypes
  ) {
    orderStatesAll(
      pDeliveryStatus: $deliveryStatus
      pFinancialStatus: $financialStatus
      pFulfillmentStatus: $fulfillmentStatus
      pOrderType: $orderType
    ) {
      nodes {
        id
        name
      }
      totalCount
    }
  }
`

export const GET_ORDER_ALL_ZIP_CODES = gql`
  query GetOrderAllZipCodes(
    $deliveryStatus: OrderDeliveryMethod
    $financialStatus: FinancialStatusTypes
    $fulfillmentStatus: FulfillmentStatusTypes
    $orderType: OrderTypes
    $stateId: UUID
  ) {
    orderZipCodesAll(
      pDeliveryStatus: $deliveryStatus
      pFinancialStatus: $financialStatus
      pFulfillmentStatus: $fulfillmentStatus
      pOrderType: $orderType
      pStateId: $stateId
    ) {
      nodes
      totalCount
    }
  }
`

export const GET_ORDER_ALL_BOTTLES = gql`
  query GetOrderAllBottles(
    $deliveryStatus: OrderDeliveryMethod
    $financialStatus: FinancialStatusTypes
    $fulfillmentStatus: FulfillmentStatusTypes
    $orderType: OrderTypes
    $zipCode: String
    $stateId: UUID
  ) {
    orderBottlesAll(
      pDeliveryStatus: $deliveryStatus
      pFinancialStatus: $financialStatus
      pFulfillmentStatus: $fulfillmentStatus
      pOrderType: $orderType
      pZipCode: $zipCode
      pStateId: $stateId
    ) {
      nodes
      totalCount
    }
  }
`

export const GET_WINE_CLUB = gql`
  query GetWineClub {
    wineClubs(filter: {deletedAt: {isNull: true}}) {
      nodes {
        name
        id
      }
    }
  }
`

export const GET_ORDER_ALL_WEIGHTS = gql`
  query GetOrderAllWeights(
    $bottles: Int
    $deliveryStatus: OrderDeliveryMethod
    $financialStatus: FinancialStatusTypes
    $fulfillmentStatus: FulfillmentStatusTypes
    $orderType: OrderTypes
    $zipCode: String
    $stateId: UUID
  ) {
    orderWeightsAll(
      pBottles: $bottles
      pDeliveryStatus: $deliveryStatus
      pFinancialStatus: $financialStatus
      pFulfillmentStatus: $fulfillmentStatus
      pOrderType: $orderType
      pZipCode: $zipCode
      pStateId: $stateId
    ) {
      nodes
      totalCount
    }
  }
`


export const GET_LOCATIONS = gql`
  query GetLocations {
    locations {
      nodes {
        id
        name
        isDefault
      }
    }
  }
`