/* eslint-disable react/jsx-no-target-blank */
import {useLayout} from '_metronic/layout/core'
import {useEffect, useMemo, useState} from 'react'
import {useSelector} from 'react-redux'
import {RootState} from 'setup'
import {getStoreAdminMenus} from 'app/features/auth/redux/AuthCRUD'
import MainMenu from './mainMenu'
import SalesChannels from './salesChannel'

export function AsideMenuMain() {
  const [storeAdminMenus, setStoreAdminMenus] = useState([])

  const store = useSelector<RootState>(({auth}) => auth.user?.store) as any
  const {permissions} = store

  useEffect(() => {
    const fetch = async () => {
      const menus = await getStoreAdminMenus()
      setStoreAdminMenus(menus)
    }
    fetch()
  }, [])

  const {isPosMenuEnable} = useLayout()

  const menus = useMemo(() => {
    const isPosEnabled = (permissions: any, isPosMenuEnable: boolean) => {
      return isPosMenuEnable
        ? permissions
        : permissions.filter(
            (permission: string) =>
              permission !== 'point_of_sale' && permission !== 'reports_pos.all'
          )
    }
    const filteredPermissions = isPosEnabled(permissions, isPosMenuEnable)
    filteredPermissions.push('home')
    return storeAdminMenus
      .filter((menu: any) => {
        return filteredPermissions.some((code: string) => menu.code.includes(code))
      })
      .map((menu: any) => {
        const filteredItems = menu.storeAdminMenuItemsByMenuId.nodes.filter((item: any) =>
          filteredPermissions.includes(item.code)
        )

        return {
          ...menu,
          storeAdminMenuItemsByMenuId: {
            ...menu.storeAdminMenuItemsByMenuId,
            nodes: filteredItems,
          },
        }
      })
  }, [isPosMenuEnable, permissions, storeAdminMenus])

  return (
    <>
      <MainMenu menus={menus} />
      <SalesChannels menus={menus} />
    </>
  )
}
