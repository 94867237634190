import {FC} from 'react'
import {StateLookup, CountryLookup} from 'app/components'
import {useFormikContext} from 'formik'
import Card from '_core/components/Card'
import {Checkbox, PhoneField, TextField, ZipCodeField} from '_core/forms'
import DateOfBirth from 'app/components/DateOfBirth'
import AddressSuggestion from 'app/components/AddressSearch/AddressSuggestion'

type Props = {
  children?: any
  isShowPhoneField?: boolean
}

const CustomerAddress: FC<Props> = ({children, isShowPhoneField}) => {
  const {
    values: {address},
    errors,
  } = useFormikContext<any>()
  return (
    <Card title='Address' className='card-xl-stretch mb-5 mb-xl-8'>
      {children}
      <div className='fv-row mb-10'>
        <div className='col-xl-12'>
          <label className='form-label fs-6 fw-bold text-dark required'>Address</label>
          <AddressSuggestion
            id='txtCustomerAddress1'
            name='address.addressLine1'
            fieldName='address'
            className='py-2'
          />
        </div>
      </div>
      <div className='fv-row mb-10'>
        <div className='col-xl-12'>
          <label className='form-label fs-6 fw-bold text-dark'>Apartment, suite, etc.</label>
          <TextField id='txtCustomerAddress2' name='address.addressLine2' maxLength={200} />
        </div>
      </div>
      <div className='row mb-10'>
        <div className={`col-xl-${isShowPhoneField ? '6' : '12'}`}>
          <label className='form-label fs-6 fw-bold text-dark required'>City</label>
          <TextField id='txtCustomercity' name='address.city' maxLength={200} />
        </div>
        {isShowPhoneField && (
          <div className='col-xl-6'>
            <label className='form-label fs-6 fw-bold text-dark required'>Phone</label>
            <PhoneField className='col-xl-6' id='customerPhone' name='address.phone' />
          </div>
        )}
      </div>
      <div className='fv-row mb-10'>
        <div className='row'>
          <CountryLookup
            className='col-xl-6'
            name='address.countryId'
            resetKeys={[`address.stateId`, `address.zipCode`]}
          />
          <StateLookup className='col-xl-6' name='address.stateId' countryId={address?.countryId} />
        </div>
      </div>
      <div className='fv-row mb-10'>
        <div className='row'>
          <div className='col-xl-6'>
            {/* <label className='form-label fs-6 fw-bold text-dark required'>ZIP Code</label> */}
            <ZipCodeField
              id='zipCusZipCode'
              name='address.zipCode'
              className='form-control py-3'
              countryId={address?.countryId}
            />
          </div>
          <div className='col-xl-6'>
            <label className='form-label fs-6 fw-bold text-dark required'>Date of Birth</label>
            <DateOfBirth id='customerAddressDateOfBirth' name='address.dateOfBirth' />
          </div>
        </div>
      </div>
      <div className='fv-row mb-10'>
        <label className='form-check form-check-inline form-check-solid me-5'>
          <Checkbox id='chkIsDefaultAddress' name='address.isPrimary' />
          <span className='fw-bold ps-2 fs-6'>Set as primary address</span>
        </label>
      </div>
    </Card>
  )
}
export default CustomerAddress
