import React, {useMemo} from 'react'
import {AsideMenuItemWithSub} from '../AsideMenuItemWithSub'
import {AsideMenuItem} from '../AsideMenuItem'
import orderBy from 'lodash/orderBy'
import {KTSVG} from '_metronic/helpers'

interface Props {
  menus: any[]
}

const AsideMenuMain: React.FC<Props> = ({menus}) => {
  const filteredMainMenus = useMemo(() => {
    return orderBy(menus, 'displayOrder').filter(
      (item: any) => item.displayOrder >= 1 && item.displayOrder <= 7
    )
  }, [menus])

  if (filteredMainMenus && filteredMainMenus.length === 0) {
    return null
  }

  return (
    <>
      <div className='menu-header-icon'>
        <div className='d-flex justify-content-between'>
          <div className='menu-item'>
            <div className='menu-content pt-6'>
              <span className='menu-section'>Main</span>
            </div>
          </div>
          <div className='menu-item'>
            <div className='menu-link'>
              <div
                id='kt_aside_toggle'
                className='btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle'
                data-kt-toggle='true'
                data-kt-toggle-state='active'
                data-kt-toggle-target='body'
                data-kt-toggle-name='aside-minimize'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr079.svg'
                  className='svg-icon-1 rotate-180'
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {filteredMainMenus.map((menu: any) => {
        return menu.storeAdminMenuItemsByMenuId.nodes.length > 0 ? (
          <AsideMenuItemWithSub
            id={`link${menu.name}`}
            to={menu.url}
            title={menu.name}
            icon={menu.icon}
            fontIcon='bi-person'
          >
            {menu.storeAdminMenuItemsByMenuId &&
              orderBy(menu.storeAdminMenuItemsByMenuId.nodes, 'displayOrder').map(
                (menuItem: any) => (
                  <AsideMenuItem
                    id={`link${menuItem.name}`}
                    to={menuItem.url}
                    title={menuItem.name}
                    hasBullet={true}
                  />
                )
              )}
          </AsideMenuItemWithSub>
        ) : (
          <AsideMenuItem
            id={`link${menu.name}`}
            to={menu.url}
            icon={menu.icon}
            title={menu.name}
            fontIcon='bi-app-indicator'
          />
        )
      })}
    </>
  )
}

export default React.memo(AsideMenuMain)
