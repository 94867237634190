import {forwardRef} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {Checkbox} from '_core/forms'
import {RootState} from 'setup'
import Stripe from 'app/components/PaymentForm/Stripe'
import {UserModel} from 'app/features/auth/models/UserModel'

type Props = {}

const StripeForm = forwardRef((props: Props, ref) => {
  const paymentError = useSelector<RootState>(({customers}) => customers.customer.error) as any
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  return (
    <Stripe config={user.store?.config} showCard ref={ref}>
      <div className='fv-row mb-10'>
        <label className='form-check form-check-inline form-check-solid me-5'>
          <Checkbox id='chkIsPrimaryPayment' name='payment.isPrimary' />
          <span className='fw-bold ps-2 fs-6'>Set as primary payment method</span>
        </label>
      </div>
      {paymentError && (
        <div className='fv-plugins-message-container invalid-feedback'>
          <i className='bi bi-exclamation-circle-fill text-danger me-2'></i>
          <span role='alert'>{paymentError}</span>
        </div>
      )}
    </Stripe>
  )
})

export default StripeForm
